import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

export const refTypeBien = require("./data/TypeBien.json");

export const TypeBien = ({ typeBien, showLabel = true, ...otherProps }) => {
  const options = refTypeBien.map((typeBien, index) => {
    return (
      <option key={index} value={index}>
        {typeBien}
      </option>
    );
  });
  return (
    <FormGroup>
      {showLabel === true && <Label>Type de bien</Label>}
      <Input
        type="select"
        id="type-bien"
        className="medium-input"
        value={typeBien}
        {...otherProps}
      >
        <option></option>
        {options}
      </Input>
    </FormGroup>
  );
};
