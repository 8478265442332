import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

export const refTypeTravaux = require("./data/TypeTravaux.json");

export const TypeTravaux = (props) => {
  const options = refTypeTravaux.map((typeTravaux, index) => {
    return (
      <option key={index} value={index}>
        {typeTravaux}
      </option>
    );
  });
  return (
    <FormGroup>
      <Label>Type de travaux</Label>
      <Input
        type="select"
        id="type-travaux"
        className="medium-input"
        {...props}
      >
        <option></option>
        {options}
      </Input>
    </FormGroup>
  );
};
