import React, { useState, useEffect } from "react";
import ReactStreetview from "react-streetview";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from "chart.js";
//import { Pie } from "react-chartjs-2";
import {
  PieChart,
  ResponsiveContainer,
  Pie,
  Cell,
  Legend as PieLegend,
  Tooltip as PieTooltip,
} from "recharts";

import { ToastContainer, toast } from "react-toastify";

import { Stepper, Step } from "react-form-stepper";

import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "./layout/Navbar";

import {
  BsCloudDownload,
  BsChevronRight,
  BsChevronLeft,
  BsPlusCircle,
  BsPencilSquare,
  BsTrash,
} from "react-icons/bs";

import { FaQuestionCircle } from "react-icons/fa";

import {
  getData,
  deleteData,
  generateExport,
  getExternalData,
  putData,
  toastConfig,
  prettyEuro,
  prettyPrixMetreCarre,
  postData,
} from "../utils";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  CardHeader,
  CardBody,
  Input,
  CardFooter,
  Badge,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";

import "./ProjectPage.css";
import "./ProjectStep.css";
import CurrencyInput from "./Components/CurrencyInput";
import PercentInput from "./Components/PercentInput";
import VenteLot from "./Components/VenteLot";
import Surface from "./Components/Surface";
import Travaux from "./Components/Travaux";
import { refTauxTVAValue } from "./Components/TauxTVA";
import CoutsFixes from "./Components/CoutsFixes";
import Financement from "./Components/Financement2";
import {
  calculApport,
  calculChiffreAffaire,
  calculChiffreAffaireHFA,
  calculChiffreAffaireHFAMoyen,
  calculChiffreAffaireHFAHaut,
  calculCommissionEngagement,
  calculHypotheque,
  calculInteretsEmprunt,
  calculMargeNette,
  calculMargeNetteChange,
  calculMontantAFinancer,
  calculTauxMargeNette,
  calculFraisAgenceVente,
  calculFraisAgenceVenteMoyen,
  calculFraisAgenceVenteHaut,
  calculMontantTravaux,
  calculSommeFraisVente,
  calculSommeCoutsFixes,
  calculTVAFraisVente,
  calculFraisFinancenementAvecMontantExpertise,
  calculSommeCoutsFixesProjet,
  calculTVACoutsFixes,
  calculTVATravaux,
  calculFraisAcquisition,
  calculMontantTotalDepenses,
  calculFinancementParticipatif,
  calculTVATotal,
  calculAFinancerPerso,
  calculCoutTotalFraisDepenses,
  calculMontantLoyers,
  calculChiffreAffaireMoyen,
  calculChiffreAffaireHaut,
  calculTauxMargeNetteHaut,
  calculTauxMargeNetteMoyen,
  calculSommeTravauxHT,
  calculSommeTravauxTTC,
  calculSommeTVATravauxParTVA,
} from "./Components/helpers/calculsProjet";

import {
  calculMontantFraisNotaire,
  calculEmolumentsNotaire,
} from "./Components/helpers/calculFraisNotaire";
import { TypeBien, refTypeBien } from "./Components/TypeBien";
import SmallPercentInput from "./Components/SmallPercentInput";
import BilanTravauxParLot from "./Components/BilanTravauxParLot";

export const refTypeProjet = require("./Components/data/TypeProjet.json");

ChartJS.register(ArcElement, ChartTooltip, Legend);

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          fontSize: "12px",
          backgroundColor: "#ffff",
          padding: "5px",
          border: "1px solid #cccc",
        }}
      >
        <label>{`${payload[0].name} : ${prettyEuro(payload[0].value)}`}</label>
      </div>
    );
  }
  return null;
};

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  value,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      style={{
        fontSize: "10px",
      }}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${prettyEuro(value)}`}
    </text>
  );
};

const renderColorfulLegendText = (value, entry) => {
  const { color } = entry;
  //console.log(entry);
  return (
    <span style={{ paddingLeft: "5px", fontSize: "12px", color }}>{`${value}${
      value === "Marge" ? `` : ``
    } (${prettyEuro(entry.payload.value)})`}</span>
  );
};

const streetViewApiKey =
  process.env.REACT_APP_STREET_VIEW_API_KEY ||
  `AIzaSyAjotjjj8D6j_Nidrg-w1rnepR0BXnB6EE`;

function ProjectPage() {
  const [uid, setUid] = useState("");
  const [showDetailsTotalAcquisition, setShowDetailsTotalAcquisition] =
    useState(true);
  const [
    showDetailsCoutTotalFraisDepenses,
    setShowDetailsCoutTotalFraisDepenses,
  ] = useState(true);
  const [showDetailsChiffreAffaireHFA, setShowDetailsChiffreAffaireHFA] =
    useState(true);
  const [libelle, setLibelle] = useState("");
  const [projetLots, setProjetLots] = useState([]);
  const [projetTravaux, setProjetTravaux] = useState([]);
  const [allStepsLots, setAllStepsLots] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [typeProjet, setTypeProjet] = useState("");
  const [ville, setVille] = useState("");
  const [colors, setColors] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [prixAchat, setPrixAchat] = useState(0);
  const [travauxActiveTab, setTravauxActiveTab] = useState("travaux_lot_0");
  const [typeDeBien, setTypeDeBien] = useState("");
  const [surface, setSurface] = useState(0);
  const [showTVACollecteesDetails, setShowTVACollecteesDetails] =
    useState(false);
  const [fraisAgence, setFraisAgence] = useState("");
  const [projetVille, setProjetVille] = useState("");
  const [fraisNotaire, setFraisNotaire] = useState("");
  const [pourcentageFraisNotaire, setPourcentageFraisNotaire] = useState(3);
  const [chargeVendeur, setChargeVendeur] = useState("1");
  const [sommeFraisAgenceLots, setSommeFraisAgenceLots] = useState(0);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [sommeVenteLots, setSommeVenteLots] = useState(0);
  const [sommeTravauxTTC, setSommeTravauxTTC] = useState(0);
  const [sommeCoutsGestionTTC, setSommeCoutsGestionTTC] = useState(0);
  const [sommeFraisVenteTTC, setSommeFraisVenteTTC] = useState(0);
  const [sommeTVATravaux, setSommeTVATravaux] = useState(0);
  const [sommeTVACoutsGestion, setSommeTVACoutsGestion] = useState(0);
  const [sommeTVAFraisVente, setSommeTVAFraisVente] = useState(0);
  const [sommeTVACoutsFixes, setSommeTVACoutsFixes] = useState(0);
  const [sommeCoutsFixesTTC, setSommeCoutsFixesTTC] = useState(0);
  const [montantAFinancer, setMontantAFinancer] = useState(0);
  const [modeFinancementProjet, setModeFinancementProjet] = useState(true);
  const [apport, setApport] = useState(0);
  const [financementParticipatif, setFinancementParticipatif] = useState(0);
  const [fraisDossier, setFraisDossier] = useState(0);
  const [hypotheque, setHypotheque] = useState(0);
  const [interetsEmprunt, setInteretsEmprunt] = useState(0);
  const [commissionEngagement, setCommissionEngagement] = useState(0);
  const [montantExpertise, setMontantExpertise] = useState(0);
  const [projet, setProjet] = useState({});
  const [pourcentageApport, setPourcentageApport] = useState(0);
  const [coutTotal, setCoutTotal] = useState(0);
  const [montantTVA20, setMontantTVA20] = useState(0);
  const [montantTVA10, setMontantTVA10] = useState(0);
  const [montantTVA55, setMontantTVA55] = useState(0);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const [montantDepenses, setMontantDepenses] = useState(0);
  const [margeNette, setMargeNette] = useState(0);
  const [margeNetteChange, setMargeNetteChange] = useState(0);
  const [tauxMargeNette, setTauxMargeNette] = useState(0);
  const [modalSearchAdresse, setModalSearchAdresse] = useState(false);
  const [modalVentilation, setModalVentilation] = useState(false);
  const [searchAdresse, setSearchAdresse] = useState("");
  const [proposedAdresses, setProposedAdresses] = useState([]);
  const [tauxMargeNetteChange, setTauxMargeNetteChange] = useState(0);
  const [ventilations, setVentilations] = useState([]);
  const navigate = useNavigate();

  const handleSearchAdresse = async (value) => {
    try {
      setSearchAdresse(value);
      let encodedSearch = value.replace(" ", "+");
      const proposals = await getExternalData(
        `https://api-adresse.data.gouv.fr/search/?q=${encodedSearch}`
      );
      const adresses = [];
      proposals.features.forEach((proposal) => {
        adresses.push({
          name: proposal.properties.name,
          city: proposal.properties.city,
          postcode: proposal.properties.postcode,
          score: proposal.properties.score,
          x: proposal.properties.x,
          y: proposal.properties.y,
          latitude: proposal.geometry ? proposal.geometry.coordinates[1] : null,
          longitude: proposal.geometry
            ? proposal.geometry.coordinates[0]
            : null,
        });
      });

      setProposedAdresses(adresses);
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const fillAdresseWith = (a) => {
    console.log(a);
    setVille(a.city);
    setAdresse(a.name);
    setLatitude(a.latitude);
    setLongitude(a.longitude);
    setCodePostal(a.postcode);
    setSearchAdresse("");
    toggleModalSearchAdresse();
  };

  const toggleModalSearchAdresse = () => {
    setModalSearchAdresse(!modalSearchAdresse);
  };

  const supprimerProjet = async () => {
    try {
      console.log(`supprimerProjet from Projet`);
      await deleteData(`/v1/projets/${uid}`);
      navigate(`/dashboard`);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const toggleModalVentilation = () => {
    setModalVentilation(!modalVentilation);
  };

  const saveStep0 = async () => {
    let error = false;
    try {
      if (!libelle || libelle === "") {
        toast.error("Saisissez un nom de projet", toastConfig);
        error = true;
      }
      console.log(typeProjet);
      if (typeProjet === "") {
        console.log(typeProjet);
        toast.error("Choisissez un type de projet", toastConfig);
        error = true;
      }
      if (!adresse || adresse === "") {
        console.log(adresse);
        toast.error("Saisissez une adresse", toastConfig);
        error = true;
      }

      if (error === false) {
        await putData(`/v1/projets/${uid}`, {
          typeProjet,
          libelle,
          adresse,
          latitude,
          longitude,
          codePostal,
          ville,
          activeStep: activeStep + 1,
        });

        const p = await getData(`/v1/projets/${uid}`);
        setProjet(p);

        setActiveStep(activeStep + 1);
      }
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const saveStep1 = async () => {
    let error = false;
    try {
      if (prixAchat === "") {
        toast.error("Saisissez un prix d'achat", toastConfig);
        error = true;
      }
      if (fraisAgence === "") {
        toast.error("Saisissez les frais de d'agence", toastConfig);
        error = true;
      }
      if (fraisNotaire === "") {
        toast.error("Saisissez les frais de notaire", toastConfig);
        error = true;
      }
      if (typeDeBien === "") {
        toast.error("Choisissez un type de bien", toastConfig);
        error = true;
      }
      if (surface <= 0) {
        toast.error("Saisissez la surface >= 0", toastConfig);
        error = true;
      }
      if (chargeVendeur === "") {
        console.log(chargeVendeur);
        toast.error("Saisissez la charge vendeur", toastConfig);
        error = true;
      }

      if (error === false) {
        const _projet = await putData(`/v1/projets/${uid}`, {
          prixAchat,
          typeDeBien,
          surface,
          fraisAgence,
          typeProjet,
          fraisNotaire,
          chargeVendeur,
          activeStep: activeStep + 1,
        });

        setProjet(_projet);

        setActiveStep(activeStep + 1);
      }
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const saveStep2 = async () => {
    console.log(`saveStep2`);
    let error = false;
    try {
      const projet = await getData(`/v1/projets/${uid}`);

      if (projet.ProjetLots.length === 0) {
        toast.error("Saisissez au moint une ligne de vente", toastConfig);
        error = true;
      }

      if (error === false) {
        console.log(`Calcul des ventilations`);
        let ventilationsCalculees = [];
        let _sommeVentilation = 0;
        const _caProjet = calculChiffreAffaire(projet);
        const _projetsLots = projet.ProjetLots.sort(
          (a, b) => a.itemOrder - b.itemOrder
        );
        for (let iLot = 0; iLot < _projetsLots.length; iLot++) {
          let _ventilation = 0;
          if (iLot === _projetsLots.length - 1) {
            console.log(`Dernier lot`);
            _ventilation = 100 - _sommeVentilation;
          } else {
            _ventilation = parseInt(
              (_projetsLots[iLot].prixVente * 100) / _caProjet,
              0
            );
            _sommeVentilation += _ventilation;
          }

          ventilationsCalculees.push({
            label: `Lot ${iLot + 1}`,
            ventilation: _ventilation,
            prixVente: _projetsLots[iLot].prixVente,
          });

          await putData(
            `/v1/projets/${uid}/lots/${projet.ProjetLots[iLot].uniqueId}`,
            {
              ventilation: _ventilation,
            }
          );
        }

        setVentilations(ventilationsCalculees);
        console.log(ventilationsCalculees);

        await putData(`/v1/projets/${uid}`, {
          prixAchat,
          fraisAgence,
          fraisNotaire,
          chargeVendeur,
          activeStep: activeStep + 1,
        });

        const p = await getData(`/v1/projets/${uid}`);
        setProjet(p);

        setProjetLots(projet.ProjetLots);
        setSommeFraisAgenceLots(calculFraisAgenceVente(projet));
        setSommeVenteLots(calculChiffreAffaire(projet));
        //setActiveStep(activeStep + 1);
        toggleModalVentilation();
      }
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const saveStep3 = async () => {
    let error = false;
    let itemErrors = [];

    try {
      const p = await getData(`/v1/projets/${uid}`);
      let _sommeTVA = 0;
      let _sommeTTC = 0;
      p.ProjetTravauxes.forEach((travaux) => {
        if (
          travaux.typeTravaux === null ||
          travaux.typeTravaux === "" ||
          travaux.prixHorsTaxe === null ||
          travaux.prixHorsTaxe === "" ||
          travaux.tauxTVA === null ||
          travaux.tauxTVA === ""
        ) {
          itemErrors.push(travaux.itemOrder);
          error = true;
        } else {
          _sommeTVA += travaux.prixHorsTaxe * refTauxTVAValue[travaux.tauxTVA];
          _sommeTTC +=
            travaux.prixHorsTaxe * refTauxTVAValue[travaux.tauxTVA] +
            travaux.prixHorsTaxe;
        }
      });

      _sommeTVA = calculTVATravaux(p.ProjetTravauxes);
      _sommeTTC = calculMontantTravaux(p.ProjetTravauxes);

      if (error === true) {
        toast.error(
          `Certains travaux sont incomplets (item(s) ${itemErrors.join(
            " - "
          )})`,
          toastConfig
        );
      } else {
        setSommeTVATravaux(_sommeTVA);
        setSommeTravauxTTC(_sommeTTC);
        await putData(`/v1/projets/${uid}`, {
          activeStep: activeStep + 1,
        });
        const p = await getData(`/v1/projets/${uid}`);
        setProjet(p);

        setActiveStep(activeStep + 1);
        //setActiveStep(activeStep + 2);
      }
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const saveStep4 = async () => {
    try {
      console.log(`saveStep4`);
      const p = await getData(`/v1/projets/${uid}`);
      setSommeCoutsGestionTTC(calculSommeCoutsFixesProjet(p));
      setSommeTVACoutsGestion(calculTVAFraisVente(p));
      setSommeFraisVenteTTC(calculSommeFraisVente(p));
      setSommeTVAFraisVente(calculTVAFraisVente(p));
      setSommeCoutsFixesTTC(calculSommeCoutsFixes(p));
      setSommeTVACoutsFixes(calculTVACoutsFixes(p));

      setApport(calculApport(p));
      setMontantAFinancer(calculMontantAFinancer(p));
      setFraisDossier(p.fraisDossier);
      setFinancementParticipatif(calculFinancementParticipatif(p));
      setInteretsEmprunt(calculInteretsEmprunt(p));
      setHypotheque(calculHypotheque(p));
      setCommissionEngagement(calculCommissionEngagement(p));
      setMontantExpertise(p.montantExpertise);

      let error = false;
      let errors = [];

      //#region check ProjetAssurances
      if (Array.isArray(p.ProjetAssurances) && p.ProjetAssurances.length > 0) {
        let errorStep = false;
        p.ProjetAssurances.forEach((item) => {
          console.log(`item.tauxTVA: ${item.tauxTVA} ${item.tauxTVA === null}`);
          if (
            item.ProjetAssurancesLots.length === 0 ||
            !item.libelle ||
            !item.prixHorsTaxe ||
            item.tauxTVA === null
          ) {
            console.log(`item.tauxTVA: ${item.tauxTVA}`);
            errorStep = true;
          }
        });

        if (errorStep) {
          errors.push("Assurances");
          error = true;
        }
      }
      //#endregion

      //#region check ProjetCharges
      if (Array.isArray(p.ProjetCharges) && p.ProjetCharges.length > 0) {
        let errorStep = false;
        p.ProjetCharges.forEach((item) => {
          if (
            item.ProjetChargesLots.length === 0 ||
            !item.prixHorsTaxe ||
            item.tauxTVA === null ||
            item.tauxTVA === ""
          ) {
            errorStep = true;
          }
        });

        if (errorStep) {
          errors.push("Charges copropriété");
          error = true;
        }
      }
      //#endregion

      //#region check ProjetCommissions
      if (
        Array.isArray(p.ProjetCommissions) &&
        p.ProjetCommissions.length > 0
      ) {
        let errorStep = false;
        p.ProjetCommissions.forEach((item) => {
          if (
            item.ProjetCommissionsLots.length === 0 ||
            !item.prixHorsTaxe ||
            item.tauxTVA === null ||
            item.tauxTVA === ""
          ) {
            errorStep = true;
          }
        });

        if (errorStep) {
          errors.push("Commissions");
          error = true;
        }
      }
      //#endregion

      //#region check ProjetDiagnostiques
      if (
        Array.isArray(p.ProjetDiagnostiques) &&
        p.ProjetDiagnostiques.length > 0
      ) {
        let errorStep = false;
        p.ProjetDiagnostiques.forEach((item) => {
          if (
            item.ProjetDiagnostiquesLots.length === 0 ||
            !item.libelle ||
            !item.prixHorsTaxe ||
            item.tauxTVA === null ||
            item.tauxTVA === ""
          ) {
            errorStep = true;
          }
        });

        if (errorStep) {
          errors.push("Diagnostiques");
          error = true;
        }
      }
      //#endregion

      //#region check ProjetDivers
      if (Array.isArray(p.ProjetDivers) && p.ProjetDivers.length > 0) {
        let errorStep = false;
        p.ProjetDivers.forEach((item) => {
          if (
            item.ProjetDiversLots.length === 0 ||
            !item.libelle ||
            !item.prixHorsTaxe ||
            item.tauxTVA === null ||
            item.tauxTVA === ""
          ) {
            errorStep = true;
          }
        });

        if (errorStep) {
          errors.push("Divers");
          error = true;
        }
      }
      //#endregion

      //#region check ProjetEnergies
      if (Array.isArray(p.ProjetEnergies) && p.ProjetEnergies.length > 0) {
        let errorStep = false;
        p.ProjetEnergies.forEach((item) => {
          console.log(!item.typeEnergie);
          if (
            item.ProjetEnergiesLots.length === 0 ||
            item.typeEnergie === null ||
            !item.prixHorsTaxe ||
            item.tauxTVA === null ||
            item.tauxTVA === ""
          ) {
            errorStep = true;
          }
        });

        if (errorStep) {
          errors.push("Eau / Electricité / Gaz");
          error = true;
        }
      }
      //#endregion

      //#region check ProjetEtatDates
      if (Array.isArray(p.ProjetEtatDates) && p.ProjetEtatDates.length > 0) {
        let errorStep = false;
        p.ProjetEtatDates.forEach((item) => {
          if (
            item.ProjetEtatDatesLots.length === 0 ||
            !item.prixHorsTaxe ||
            item.tauxTVA === null ||
            item.tauxTVA === ""
          ) {
            errorStep = true;
          }
        });

        if (errorStep) {
          errors.push("Etats datés");
          error = true;
        }
      }
      //#endregion

      //#region check ProjetFraisVenteDivers
      if (
        Array.isArray(p.ProjetFraisVenteDivers) &&
        p.ProjetFraisVenteDivers.length > 0
      ) {
        let errorStep = false;
        p.ProjetFraisVenteDivers.forEach((item) => {
          if (
            item.ProjetFraisVenteDiversLots.length === 0 ||
            !item.libelle ||
            !item.prixHorsTaxe ||
            item.tauxTVA === null ||
            item.tauxTVA === ""
          ) {
            errorStep = true;
          }
        });

        if (errorStep) {
          errors.push("Frais de vente divers");
          error = true;
        }
      }
      //#endregion

      //#region check ProjetPreEtatDates
      if (
        Array.isArray(p.ProjetPreEtatDates) &&
        p.ProjetPreEtatDates.length > 0
      ) {
        let errorStep = false;
        p.ProjetPreEtatDates.forEach((item) => {
          if (
            item.ProjetPreEtatDatesLots.length === 0 ||
            !item.prixHorsTaxe ||
            item.tauxTVA === null ||
            item.tauxTVA === ""
          ) {
            errorStep = true;
          }
        });

        if (errorStep) {
          errors.push("Pré états datés");
          error = true;
        }
      }
      //#endregion

      //#region check ProjetTaxeFoncieres
      if (
        Array.isArray(p.ProjetTaxeFoncieres) &&
        p.ProjetTaxeFoncieres.length > 0
      ) {
        let errorStep = false;
        p.ProjetTaxeFoncieres.forEach((item) => {
          if (
            item.ProjetTaxeFoncieresLots.length === 0 ||
            !item.prixHorsTaxe ||
            item.tauxTVA === null ||
            item.tauxTVA === ""
          ) {
            errorStep = true;
          }
        });

        if (errorStep) {
          errors.push("Taxes foncieres");
          error = true;
        }
      }
      //#endregion

      if (error) {
        toast.error(
          `Certaines sections sont incomplètes (${errors.join(", ")})`
        );

        return;
      }

      await putData(`/v1/projets/${uid}`, {
        activeStep: activeStep + 1,
      });
      const pFin = await getData(`/v1/projets/${uid}`);
      setProjet(pFin);

      setActiveStep(activeStep + 1);
    } catch (error) {
      console.log(error);
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const saveStep5 = async () => {
    try {
      await putData(`/v1/projets/${uid}`, {
        activeStep: activeStep + 1,
        fraisDossier,
        hypotheque,
      });

      setActiveStep(activeStep + 1);

      const p = await getData(`/v1/projets/${uid}`);
      setProjet(p);
      let _travaux = calculMontantTravaux(p.ProjetTravauxes);

      console.log(_travaux);
      setMontantAFinancer(calculMontantAFinancer(p));

      let _apport = calculApport(p);
      setApport(_apport);
      setFraisDossier(p.fraisDossier);
      setFinancementParticipatif(calculFinancementParticipatif(p));
      setHypotheque(calculHypotheque(p));
      setInteretsEmprunt(calculInteretsEmprunt(p));
      setPourcentageApport(p.pourcentageApport);

      const data = [
        {
          name: "Prix d'acquisition",
          value: parseInt(calculFraisAcquisition(p), 10),
        },
        {
          name: "Financement",
          value: parseInt(calculMontantAFinancer(p), 10),
        },
        {
          name: "Frais d'agence",
          value: parseInt(p.fraisAgence, 10),
        },
        {
          name: "Frais notaire",
          value: parseInt(
            calculMontantFraisNotaire(
              p.chargeVendeur === 1 ? p.prixAchat + p.fraisAgence : p.prixAchat,
              1,
              p.codePostal
            ),
            10
          ),
        },
        {
          name: "Travaux",
          value: parseInt(calculMontantTravaux(p.ProjetTravauxes), 10),
        },
        // {
        //   name: "Coût fixe",
        //   value: parseInt(calculSommeCoutsFixes(p), 10),
        // },
        {
          name: "TVA",
          value: parseInt(calculTVATotal(p), 10),
        },
        {
          name: "Marge",
          value: parseInt(calculMargeNette(p), 10),
        },
      ];

      setPieData(data.sort((a, b) => b.value - a.value));

      computeCoutTotal(p);
      computeApport(p);
      computeTVA(p);
      computeDepenses(p);
      computeOther(p);
      setProjetVille(p.ville);
      setProjet(p);
    } catch (error) {
      console.log(error);
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addLot = async () => {
    try {
      await postData(`/v1/projets/${uid}/lots`, {
        itemOrder: projetLots.length + 1,
        projetUniqueId: uid,
      });

      const projet = await getData(`/v1/projets/${uid}`);

      console.log(projet.ProjetLots.sort((a, b) => a.itemOrder - b.itemOrder));
      setProjetLots(
        projet.ProjetLots.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addTravaux = async () => {
    try {
      await postData(`/v1/projets/${uid}/travaux`, {
        itemOrder: projetTravaux.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetTravaux(
        projet.ProjetTravauxes.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeLot = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/lots/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetLots(
        projet.ProjetLots.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeTravaux = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/travaux/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetTravaux(
        projet.ProjetTravauxes.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const previousStep = () => {
    console.log(activeStep);
    //console.log(chargeVendeur);

    setActiveStep(activeStep - 1);
  };

  const goToStep = (step) => {
    //console.log(`GOTO => ${step}`);
    setActiveStep(step);
  };

  let params = useParams();

  if (uid !== params.uid) {
    setUid(params.uid);
  }

  const changeCodePostal = async (value) => {
    try {
      setCodePostal(value);
      if (value && value.length === 5) {
        const ville = await getExternalData(
          `https://apicarto.ign.fr/api/codes-postaux/communes/${value}`
        );
        Array.isArray(ville) ? setVille(ville[0].nomCommune) : setVille("");
      } else {
        setVille("");
      }
      await putData(`/v1/projets/${uid}`, {
        codePostal: value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const changeVille = (value) => {
    try {
      setVille(value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeAdresse = async (value) => {
    try {
      console.log(value);

      setAdresse(value);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePourcentageFraisNotaire = async (value) => {
    try {
      setPourcentageFraisNotaire(value);
      console.log(`chargeVendeur : ${chargeVendeur} ${chargeVendeur === 0}`);
      if (chargeVendeur === 1) {
        setFraisNotaire(prixAchat + fraisAgence * (value / 100));
      } else {
        setFraisNotaire(prixAchat * (value / 100));
      }
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleChargeVendeur = async (value) => {
    try {
      console.log(value);
      setChargeVendeur(parseInt(value, 10));
      const _frais = calculFraisNotaireAchat(
        parseInt(value, 10) === 0 ? prixAchat + fraisAgence : prixAchat
      );
      setFraisNotaire(_frais);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleFraisNotaire = async (value) => {
    try {
      console.log(`Value : ${value}`);
      const _fraisNotaire = calculMontantFraisNotaire(value, 0, 75);
      console.log(_fraisNotaire);
      console.log(value);
      setFraisNotaire(parseInt(value, 10));
      if (chargeVendeur === 0) {
        setPourcentageFraisNotaire(
          parseInt((parseInt(value, 10) / prixAchat) * 100, 10)
        );
      } else {
        setPourcentageFraisNotaire(
          parseInt(parseInt(value, 10) / (prixAchat + fraisAgence) / 100, 10)
        );
      }
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleGenerateExport = async (uid) => {
    try {
      setExportInProgress(true);
      await generateExport(uid);
      setExportInProgress(false);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handlePrixAchat = async (value) => {
    try {
      setPrixAchat(value);
      const _frais = calculFraisNotaireAchat(
        chargeVendeur === 1 ? value + fraisAgence : value
      );
      setFraisNotaire(_frais);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const computeTVA = (p) => {
    let tva20 = 0;
    let tva10 = 0;
    let tva55 = 0;
    p.ProjetTravauxes.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetTaxeFoncieres.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetAssurances.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetCharges.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetCommissions.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetDiagnostiques.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetDivers.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetFraisVenteDivers.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetEnergies.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetEtatDates.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetPreEtatDates.forEach((montant) => {
      switch (montant.tauxTVA) {
        case 0:
          break;
        case 1:
          tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 2:
          tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        case 3:
          tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
          break;
        default:
          console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
      }
    });
    p.ProjetLots.forEach((lot) => {
      lot.ProjetLotFraisVentes.forEach((montant) => {
        switch (montant.tauxTVA) {
          case 0:
            break;
          case 1:
            tva55 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
            break;
          case 2:
            tva10 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
            break;
          case 3:
            tva20 += montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
            break;
          default:
            console.log(`Taux de TVA non géré ${montant.tauxTVA}`);
        }
      });
    });
    setMontantTVA10(tva10);
    setMontantTVA20(tva20);
    setMontantTVA55(tva55);
  };

  const computeDepenses = (p) => {
    let _montantDepenses = 0;
    p.ProjetTravauxes.forEach((montant) => {
      _montantDepenses +=
        montant.prixHorsTaxe +
        montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
    });

    setMontantDepenses(_montantDepenses);
  };

  const typeTva = (value) => {
    if (value === 0) {
      return "I";
    } else if (value === 1) {
      return "M";
    } else {
      return "X";
    }
  };

  const computeOther = (p) => {
    setPourcentageApport(p.pourcentageApport);

    setFraisDossier(p.fraisDossier);
    setFinancementParticipatif(calculFinancementParticipatif(p));
  };

  const computeApport = (p) => {
    let _apport = calculApport(p);
    setApport(_apport);
  };

  const handleFraisAgence = (frais) => {
    setFraisAgence(frais);

    const _frais = calculFraisNotaireAchat(
      chargeVendeur === 1 ? prixAchat + frais : prixAchat
    );
    setFraisNotaire(_frais);
  };

  const endVentilation = () => {
    setModalVentilation(false);
    setActiveStep(activeStep + 1);
  };

  const handleFinancementParticipatif = async (montant) => {
    try {
      const p = await getData(`/v1/projets/${uid}`);
      console.log(`handleFinancementParticipatif from ProjetctPAge`);
      console.log(p);
      setFinancementParticipatif(p.modeFinancementProjet ? 0 : montant);
      await putData(`/v1/projets/${uid}`, {
        financementParticipatif: montant,
      });

      let _montantApport = calculApport(p);
      //console.log(`_montantApport : ${_montantApport}`);
      setApport(_montantApport);
      let _montantAFinancer = calculMontantAFinancer(p);
      //console.log(`_montantAFinancer : ${_montantAFinancer}`);

      setMontantAFinancer(_montantAFinancer);
      let _hypotheque = calculHypotheque(p);
      setHypotheque(_hypotheque);
      let _interetsEmprunt = calculInteretsEmprunt(p);
      setInteretsEmprunt(_interetsEmprunt);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleMontantExpertise = async (montant) => {
    try {
      console.log(`handleMontantExpertise from ProjetctPAge`);
      setMontantExpertise(montant);
      await putData(`/v1/projets/${uid}`, {
        montantExpertise: montant,
      });

      const p = await getData(`/v1/projets/${uid}`);
      let _montantApport = calculApport(p);
      //console.log(`_montantApport : ${_montantApport}`);
      setApport(_montantApport);
      let _montantAFinancer = calculMontantAFinancer(p);
      console.log(`In projetcPage _montantAFinancer : ${_montantAFinancer}`);

      setMontantAFinancer(_montantAFinancer);
      let _hypotheque = calculHypotheque(p);
      setHypotheque(_hypotheque);
      let _interetsEmprunt = calculInteretsEmprunt(p);
      setInteretsEmprunt(_interetsEmprunt);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleModeFinancementProjet = async (mode) => {
    try {
      console.log(`handleModeFinancementProjet from ProjetctPAge`);
      setModeFinancementProjet(mode);
      await putData(`/v1/projets/${uid}`, {
        modeFinancementProjet: mode,
      });
      const p = await getData(`/v1/projets/${uid}`);
      let _montantApport = calculApport(p);
      console.log(`_montantApport : ${_montantApport}`);
      setApport(_montantApport);
      let _montantAFinancer = calculMontantAFinancer(p);
      console.log(`_montantAFinancer : ${_montantAFinancer}`);

      setMontantAFinancer(_montantAFinancer);
      let _hypotheque = calculHypotheque(p);
      setHypotheque(_hypotheque);
      let _interetsEmprunt = calculInteretsEmprunt(p);
      setInteretsEmprunt(_interetsEmprunt);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleChangeTauxMargeNette = async (value) => {
    try {
      const p = await getData(`/v1/projets/${uid}`);
      let _margeNetteChange = calculMargeNetteChange(p, value);
      setMargeNetteChange(_margeNetteChange);
      setTauxMargeNetteChange(value);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const computeCoutTotal = (p) => {
    //console.log(p);

    let _prixNetVendeur = 0;
    p.ProjetLots.forEach((lot) => {
      _prixNetVendeur += lot.prixVente;
    });

    let _montantAFinancer = calculMontantAFinancer(p);
    setMontantAFinancer(_montantAFinancer);

    let _interetsEmprunt = calculInteretsEmprunt(p);
    setInteretsEmprunt(_interetsEmprunt);
    let _commissionEngagement = calculCommissionEngagement(p);
    setCommissionEngagement(_commissionEngagement);
    let _hypotheque = calculHypotheque(p);
    setHypotheque(_hypotheque);
    let _coutProjet = calculMontantTotalDepenses(p);
    setCoutTotal(_coutProjet);

    let _sommeVenteLots = 0;
    p.ProjetLots.forEach((lot) => {
      _sommeVenteLots += parseInt(lot.prixVente, 10);
    });

    let _margeNette = calculMargeNette(p);
    setMargeNette(_margeNette);
    // console.log(`Marge nette : ${_margeNette}`);
    setMargeNetteChange(_margeNette);
    let _tauxMargeNette = calculTauxMargeNette(p);
    setTauxMargeNette(_tauxMargeNette);
    setTauxMargeNetteChange(_tauxMargeNette);
  };

  const calculFraisNotaireAchat = (montant) => {
    /* console.log(
      `calculMontantFraisNotaire  : ${calculMontantFraisNotaire(
        montant,
        0,
        "75000"
      )}`
    ); */
    const emoluments = calculEmolumentsNotaire(montant);
    const frais = parseInt(
      (emoluments[0] + emoluments[1] + emoluments[2] + emoluments[3]) * 1.2 +
        1360 +
        montant * 0.001 +
        montant * 0.0071498,
      0
    );

    return frais;
  };

  useEffect(() => {
    async function getUseEffectData() {
      try {
        const profiles = await getData("/v1/me/profiles");
        if (profiles.includes("ADMIN")) {
          setIsAdmin(true);
        }

        let p = await getData(`/v1/projets/${uid}`);
        setProjet(p);
        setProjetVille(p.ville);
        setLibelle(p.libelle);
        setActiveStep(p.activeStep);
        setTypeProjet(p.typeProjet);
        await changeCodePostal(p.codePostal);
        setAdresse(p.adresse);
        setCodePostal(p.codePostal);
        setPrixAchat(p.prixAchat);
        setFraisAgence(p.fraisAgence);
        p.typeDeBien >= 0 ? setTypeDeBien(p.typeDeBien) : setTypeDeBien("");
        p.surface ? setSurface(p.surface) : setSurface(0);
        //console.log(`Charge Vendeur : ${p.chargeVendeur}`);
        setFraisNotaire(
          calculFraisNotaireAchat(
            p.chargeVendeur === 0 ? p.prixAchat + p.fraisAgence : p.prixAchat
          )
        );
        //console.log(`CHARGE VENDEUR : ${p.chargeVendeur}`);
        setChargeVendeur(p.chargeVendeur);
        setModeFinancementProjet(p.modeFinancementProjet);
        setProjetLots(p.ProjetLots.sort((a, b) => a.itemOrder - b.itemOrder));
        setProjetTravaux(
          p.ProjetTravauxes.sort((a, b) => a.itemOrder - b.itemOrder)
        );

        setAllStepsLots(p.ProjetLots);

        setColors([
          "#ff66cc",
          "#8884d8",
          "#82ca9d",
          "#FFBB28",
          "#ff3300",
          "#FF8042",
          "#AF19FF",
          "#0000ff",
        ]);

        const data = [
          {
            name: "Prix d'acquisition",
            value: parseInt(calculFraisAcquisition(p), 10),
          },
          {
            name: "Financement",
            value: parseInt(calculMontantAFinancer(p), 10),
          },
          {
            name: "Frais d'agence",
            value: parseInt(p.fraisAgence, 10),
          },
          {
            name: "Frais notaire",
            value: parseInt(
              calculFraisNotaireAchat(
                p.chargeVendeur === 0
                  ? p.prixAchat + p.fraisAgence
                  : p.prixAchat
              ),
              10
            ),
          },
          {
            name: "Travaux",
            value: parseInt(calculMontantTravaux(p.ProjetTravauxes), 10),
          },
          // {
          //   name: "Coût fixe",
          //   value: parseInt(calculSommeCoutsFixes(p), 10),
          // },
          {
            name: "TVA",
            value: parseInt(calculTVATotal(p), 10),
          },
          {
            name: "Marge",
            value: parseInt(calculMargeNette(p), 10),
          },
        ];

        setPieData(data.sort((a, b) => b.value - a.value));

        if (p.activeStep > 2) {
          setSommeFraisAgenceLots(calculFraisAgenceVente(p));
          setSommeVenteLots(calculChiffreAffaire(p));
        }
        if (p.activeStep > 3) {
          let _sommeTVA = 0;
          let _sommeTTC = 0;
          p.ProjetTravauxes.forEach((travaux) => {
            _sommeTVA =
              _sommeTVA +
              travaux.prixHorsTaxe * refTauxTVAValue[travaux.tauxTVA];
            _sommeTTC =
              _sommeTTC +
              (travaux.prixHorsTaxe * refTauxTVAValue[travaux.tauxTVA] +
                travaux.prixHorsTaxe);
          });

          setSommeTVATravaux(calculTVATravaux(p.ProjetTravauxes));
          setSommeTravauxTTC(calculMontantTravaux(p.ProjetTravauxes));
        }

        if (p.activeStep > 4) {
          let _sommeTVA = 0;
          let _sommeTTC = 0;
          let _sommeTTCFV = 0;
          let _sommeTVAFV = 0;

          p.ProjetCharges.forEach((charge) => {
            _sommeTVA += charge.prixHorsTaxe * refTauxTVAValue[charge.tauxTVA];
            _sommeTTC +=
              charge.prixHorsTaxe * refTauxTVAValue[charge.tauxTVA] +
              charge.prixHorsTaxe;
          });

          p.ProjetTaxeFoncieres.forEach((ptf) => {
            _sommeTVA += ptf.prixHorsTaxe * refTauxTVAValue[ptf.tauxTVA];
            _sommeTTC +=
              ptf.prixHorsTaxe * refTauxTVAValue[ptf.tauxTVA] +
              ptf.prixHorsTaxe;
          });

          p.ProjetAssurances.forEach((pa) => {
            _sommeTVA += pa.prixHorsTaxe * refTauxTVAValue[pa.tauxTVA];
            _sommeTTC +=
              pa.prixHorsTaxe * refTauxTVAValue[pa.tauxTVA] + pa.prixHorsTaxe;
          });

          p.ProjetEnergies.forEach((pe) => {
            _sommeTVA += pe.prixHorsTaxe * refTauxTVAValue[pe.tauxTVA];
            _sommeTTC +=
              pe.prixHorsTaxe * refTauxTVAValue[pe.tauxTVA] + pe.prixHorsTaxe;
          });

          p.ProjetDivers.forEach((pd) => {
            _sommeTVA += pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA];
            _sommeTTC +=
              pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA] + pd.prixHorsTaxe;
          });

          setSommeCoutsGestionTTC(_sommeTTC);
          setSommeTVACoutsGestion(_sommeTVA);

          p.ProjetLots.forEach((lot) => {
            lot.ProjetLotFraisVentes.forEach((fv) => {
              _sommeTVAFV += fv.prixHorsTaxe * refTauxTVAValue[fv.tauxTVA];
              _sommeTTCFV +=
                fv.prixHorsTaxe * refTauxTVAValue[fv.tauxTVA] + fv.prixHorsTaxe;
            });
          });

          setSommeFraisVenteTTC(calculSommeFraisVente(p));
          setSommeTVAFraisVente(_sommeTVAFV);

          setSommeCoutsFixesTTC(_sommeTTCFV + _sommeTTC);
          setSommeTVACoutsFixes(_sommeTVAFV + _sommeTVA);
        }

        if (p.activeStep >= 5) {
          //console.log(`HEy !!`);
          computeCoutTotal(p);
          computeTVA(p);
          computeApport(p);
          computeDepenses(p);
          computeOther(p);
          let _montantAFinancer = calculMontantAFinancer(p);
          setMontantAFinancer(_montantAFinancer);
          let _apport = calculApport(p);
          setApport(_apport);
        }
      } catch (error) {
        console.log(error);
        console.log(JSON.parse(error).error.status === 403);

        if (JSON.parse(error).error.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }

    getUseEffectData();
  }, [uid, navigate]);

  const lots = projetLots.map((projetLot, index) => {
    return (
      <VenteLot
        key={index}
        projet={projetLot.projetUniqueId}
        id={projetLot.itemOrder}
        uniqueId={projetLot.uniqueId}
        removeLot={() => removeLot(projetLot.uniqueId)}
      />
    );
  });

  const ventilationAffichees = ventilations.map((v, index) => {
    return (
      <li key={index}>
        {v.label} à {prettyEuro(v.prixVente)} représentant {v.ventilation}%
      </li>
    );
  });

  const travaux = projetTravaux.map((projetTravail, index) => {
    return (
      <Travaux
        key={index}
        projet={projetTravail.projetUniqueId}
        id={projetTravail.itemOrder}
        uniqueId={projetTravail.uniqueId}
        lots={projetLots}
        removeTravaux={() => removeTravaux(projetTravail.uniqueId)}
      />
    );
  });

  const lotsSummary = projetLots
    .sort((a, b) => a.itemOrder - b.itemOrder)
    .map((projetLot, index) => {
      return (
        <Row key={index} className="mt-10">
          <Col xs={3} md={2} className="prix-m2-summary">
            {prettyPrixMetreCarre(
              (projetLot.fraisAgence + projetLot.prixVente) / projetLot.surface
            )}
          </Col>
          <Col xs={2} md={3} className="italic projet-summary">
            <span style={{ display: "inline-flex" }} className="small">
              <span className="xs-hidden">Lot&nbsp;</span>n°
              {projetLot.itemOrder}
            </span>
          </Col>
          <Col xs={3} md={3} className="projet-summary">
            FA {prettyEuro(projetLot.fraisAgence)}
          </Col>
          <Col xs={3} md={3} className="projet-summary">
            {prettyEuro(projetLot.prixVente)}
          </Col>
          <Col xs={2} md={1} className="projet-summary">
            {typeTva(projetLot.tva)}
          </Col>
        </Row>
      );
    });

  const lotsGrilleVente = projetLots
    .sort((a, b) => a.itemOrder - b.itemOrder)
    .map((lot, index) => {
      return (
        <tr key={index} className="fs-14 mb-10">
          <td
            className="calcul-marge-projet-category"
            style={{
              verticalAlign: "top",
            }}
          >
            <span className="bold">Lot n°{lot.itemOrder}</span>{" "}
            {refTypeBien[lot.typeBien]} {lot.surface}m²
          </td>
          <td
            className="right"
            style={{ verticalAlign: "top", color: "rgb(128, 128, 128)" }}
          >
            <div>
              {lot.prixVenteHaut ? prettyEuro(lot.prixVenteHaut) : `N/A`}
            </div>
            <div>
              {lot.fraisAgenceHaut ? prettyEuro(lot.fraisAgenceHaut) : `N/A`} FA
            </div>
            <div className="bold">
              {lot.fraisAgenceHaut && lot.prixVenteHaut
                ? prettyEuro(lot.fraisAgenceHaut + lot.prixVenteHaut)
                : `N/A`}{" "}
              FAI
            </div>
            <div>
              {lot.fraisAgenceHaut && lot.prixVenteHaut && lot.surface
                ? prettyEuro(
                    (lot.fraisAgenceHaut + lot.prixVenteHaut) / lot.surface
                  )
                : `N/A`}
              {"/m²"}
            </div>
            <br />
          </td>
          <td
            className="right"
            style={{ verticalAlign: "top", color: "rgb(128, 128, 128)" }}
          >
            <div>
              {lot.prixVenteMoyen ? prettyEuro(lot.prixVenteMoyen) : `N/A`}
            </div>
            <div>
              {lot.fraisAgenceMoyen ? prettyEuro(lot.fraisAgenceMoyen) : `N/A`}{" "}
              FA
            </div>
            <div className="bold">
              {lot.fraisAgenceMoyen && lot.prixVenteMoyen
                ? prettyEuro(lot.fraisAgenceMoyen + lot.prixVenteMoyen)
                : `N/A`}{" "}
              FAI
            </div>
            <div>
              {lot.fraisAgenceMoyen && lot.prixVenteMoyen && lot.surface
                ? prettyEuro(
                    (lot.fraisAgenceMoyen + lot.prixVenteMoyen) / lot.surface
                  )
                : `N/A`}
              {"/m²"}
            </div>
          </td>
          <td
            className="right"
            style={{ verticalAlign: "top", color: "rgb(128, 128, 128)" }}
          >
            <div>{lot.prixVente ? prettyEuro(lot.prixVente) : `N/A`}</div>
            <div>
              {lot.fraisAgence ? prettyEuro(lot.fraisAgence) : `N/A`} FA
            </div>
            <div className="bold">
              {lot.fraisAgence && lot.prixVente
                ? prettyEuro(lot.fraisAgence + lot.prixVente)
                : `N/A`}{" "}
              FAI
            </div>
            <div>
              {lot.fraisAgence && lot.prixVente && lot.surface
                ? prettyEuro((lot.fraisAgence + lot.prixVente) / lot.surface)
                : `N/A`}
              {"/m²"}
            </div>
          </td>
        </tr>
      );
    });

  const lotsBilan = projetLots
    .sort((a, b) => a.itemOrder - b.itemOrder)
    .map((lot, index) => {
      return (
        <tr
          key={index}
          className={
            showDetailsChiffreAffaireHFA === true
              ? "calcul-marge-projet-row"
              : "calcul-marge-projet-row hide"
          }
        >
          <td className="calcul-marge-projet-category">
            Lot n°{lot.itemOrder} {refTypeBien[lot.typeBien]} {lot.surface}m²
          </td>
          <td className="calcul-marge-projet-edit">
            {prettyEuro(lot.prixVente)} {prettyEuro(lot.fraisAgence)} FA
          </td>
          <td className="calcul-marge-projet-amount">
            ({prettyEuro((lot.prixVente + lot.fraisAgence) / lot.surface)}/m²){" "}
            {prettyEuro(lot.prixVente + lot.fraisAgence)}
          </td>
        </tr>
      );
    });

  const typesProjet = refTypeProjet.map((tp, index) => {
    return (
      <option key={index} value={index}>
        {tp}
      </option>
    );
  });

  const proposals = proposedAdresses.map((a, index) => {
    return (
      <li key={index}>
        <span
          onClick={() => fillAdresseWith(a)}
          className="link"
        >{`${a.name} ${a.postcode} ${a.city}`}</span>
      </li>
    );
  });

  return (
    <div>
      <Tooltip id="my-tooltip" />
      <Modal isOpen={modalVentilation}>
        <ModalHeader toggle={toggleModalVentilation}>Ventilation</ModalHeader>
        <ModalBody>
          <div>
            Pour votre information, la ventilation suivante a été calculée :
            <ul>{ventilationAffichees}</ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={endVentilation}>
            Continuer
          </Button>
        </ModalFooter>
      </Modal>
      <Navbar isLoggedIn={true} isAdmin={isAdmin} />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <h2 className="pagetitle">Détails d'un projet</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <span>{libelle}</span>{" "}
                <BsTrash
                  className="pointer"
                  color="red"
                  onClick={supprimerProjet}
                />
              </li>
            </ol>
          </nav>
        </div>
      </nav>
      <section className="vh-875">
        <Container fluid>
          <Row>
            <Col xs={12} md={activeStep > 4 ? 12 : 8}>
              <Container fluid>
                {activeStep < 5 && (
                  <Row>
                    <Col xs={12}>
                      <Stepper activeStep={activeStep}>
                        <Step label="Description" onClick={() => goToStep(0)} />
                        <Step label="Achat" onClick={() => goToStep(1)}></Step>
                        <Step label="Vente" onClick={() => goToStep(2)}></Step>
                        <Step
                          label="Travaux"
                          onClick={() => goToStep(3)}
                        ></Step>
                        {/* <Step
                          label="Coûts Fixes"
                          onClick={() => goToStep(4)}
                        ></Step> */}
                        <Step
                          label="Financement"
                          onClick={() => goToStep(4)}
                        ></Step>
                        <Step
                          label="Résultat"
                          onClick={() => goToStep(5)}
                        ></Step>
                      </Stepper>
                    </Col>
                  </Row>
                )}
                {activeStep === 5 && (
                  <Row>
                    <Col xs={12}>
                      <span
                        onClick={previousStep}
                        className="pointer ml-20 last-step"
                      >
                        &lt;
                      </span>
                      <span className="pointer ml-20 last-step">6</span>
                      <span className="step-title">
                        Résultat de votre projet
                      </span>
                    </Col>
                  </Row>
                )}
                {activeStep === 0 && (
                  <Row>
                    <Col xs={12}>
                      <span className="step-title">Description</span>
                    </Col>
                    <Col xs={12}>
                      <Card className="step-body">
                        <CardBody>
                          <Form>
                            <FormGroup>
                              <Label for="nomProjet">Nom du projet</Label>
                              <Input
                                type="text"
                                name="libelle"
                                onChange={(e) => setLibelle(e.target.value)}
                                value={libelle || ""}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="nomProjet">Type de projet</Label>
                              <Input
                                type="select"
                                value={typeProjet || ""}
                                onChange={(e) => setTypeProjet(e.target.value)}
                              >
                                {typesProjet}
                              </Input>
                            </FormGroup>
                            <FormGroup>
                              <Modal isOpen={modalSearchAdresse}>
                                <ModalHeader toggle={toggleModalSearchAdresse}>
                                  Rechercher une adresse
                                </ModalHeader>
                                <ModalBody>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      value={searchAdresse || ""}
                                      onChange={(e) =>
                                        handleSearchAdresse(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                  <div>{proposals}</div>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    color="secondary"
                                    onClick={toggleModalSearchAdresse}
                                  >
                                    Annuler
                                  </Button>
                                </ModalFooter>
                              </Modal>

                              <span style={{ display: "inline-flex" }}>
                                <FormGroup>
                                  <Label>
                                    Adresse{" "}
                                    <span
                                      className="link"
                                      onClick={toggleModalSearchAdresse}
                                    >
                                      (recherche)
                                    </span>
                                  </Label>
                                  <Input
                                    type="text"
                                    style={{
                                      width: "350px",
                                    }}
                                    value={adresse}
                                    onChange={(e) =>
                                      changeAdresse(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup className="ml-10">
                                  <Label>Code postal</Label>
                                  <Input
                                    type="text"
                                    maxLength={5}
                                    className="code-postal-input"
                                    value={codePostal}
                                    onChange={(e) =>
                                      changeCodePostal(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup className="ml-10">
                                  <Label>Ville</Label>
                                  <Input
                                    type="text"
                                    style={{
                                      width: "325px",
                                    }}
                                    value={ville}
                                    onChange={(e) =>
                                      changeVille(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </span>
                            </FormGroup>
                          </Form>
                        </CardBody>
                        <CardFooter className="right">
                          <Button color="primary" onClick={saveStep0}>
                            <BsChevronRight size={20} />
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                )}
                {activeStep === 1 && (
                  <Row>
                    <Col xs={12}>
                      <span className="step-title">Achat</span>
                    </Col>
                    <Col xs={12}>
                      <Card className="step-body">
                        <CardBody>
                          <Container fluid>
                            <Row>
                              <Col xs={6} md={3}>
                                Prix d'achat
                              </Col>
                              <Col xs={6} md={3}>
                                Frais d'agence
                              </Col>
                              <Col xs={6} md={3}>
                                Type de bien
                              </Col>
                              <Col xs={6} md={3}>
                                Surface
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={3}>
                                <CurrencyInput
                                  onChange={(e) =>
                                    handlePrixAchat(
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                  value={prixAchat || ""}
                                />
                              </Col>
                              <Col xs={6} md={3}>
                                <CurrencyInput
                                  onChange={(e) =>
                                    handleFraisAgence(
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                  value={fraisAgence || ""}
                                />
                              </Col>
                              <Col xs={6} md={3}>
                                <TypeBien
                                  showLabel={false}
                                  value={typeDeBien}
                                  onChange={(e) =>
                                    setTypeDeBien(parseInt(e.target.value, 0))
                                  }
                                />
                              </Col>
                              <Col xs={3} md={3}>
                                <Surface
                                  value={surface ? surface : ""}
                                  showLabel={false}
                                  onChange={(e) =>
                                    setSurface(parseInt(e.target.value, 0))
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mt-20">
                              <Col xs={6} md={6}>
                                Prix d'achat FAI
                              </Col>
                              <Col xs={6} md={6}>
                                Charge vendeur
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} md={6}>
                                <CurrencyInput
                                  disabled
                                  value={prixAchat + fraisAgence || ""}
                                />
                              </Col>
                              <Col xs={6} md={6}>
                                <Input
                                  type="select"
                                  value={chargeVendeur}
                                  onChange={(e) =>
                                    handleChargeVendeur(e.target.value)
                                  }
                                >
                                  <option></option>
                                  <option value={0}>Oui</option>
                                  <option value={1}>Non</option>
                                </Input>
                              </Col>
                            </Row>
                            <Row className="mt-20">
                              <Col xs={6} md={5}>
                                Frais d'acquisition (notaire)
                              </Col>
                              <Col xs={2} md={3}></Col>
                              <Col xs={4} md={4}>
                                Coût total
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={5} md={5}>
                                <CurrencyInput
                                  onChange={(e) =>
                                    handleFraisNotaire(
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                  value={fraisNotaire || ""}
                                />
                              </Col>
                              <Col xs={4} md={3}>
                                <PercentInput
                                  onChange={(e) =>
                                    handlePourcentageFraisNotaire(
                                      parseInt(e.target.value, 10)
                                    )
                                  }
                                  value={pourcentageFraisNotaire}
                                />
                              </Col>
                              <Col xs={4} md={4}>
                                <CurrencyInput
                                  disabled
                                  value={
                                    prixAchat + fraisAgence + fraisNotaire || ""
                                  }
                                />
                              </Col>
                            </Row>
                          </Container>
                        </CardBody>
                        <CardFooter className="right">
                          <Button color="secondary" onClick={previousStep}>
                            <BsChevronLeft size={20} />
                          </Button>{" "}
                          <Button color="primary" onClick={saveStep1}>
                            <BsChevronRight size={20} />
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                )}
                {activeStep === 2 && (
                  <Row>
                    <Col xs={12}>
                      <span className="step-title">Vente</span>
                    </Col>
                    <Col xs={12}>
                      <Card className="step-body">
                        <CardBody>
                          {lots}
                          <div className="right mt-10 mr-25">
                            <BsPlusCircle
                              size={20}
                              className="pointer"
                              onClick={addLot}
                            />
                          </div>
                        </CardBody>
                        <CardFooter className="right">
                          <Button color="secondary" onClick={previousStep}>
                            <BsChevronLeft size={20} />
                          </Button>{" "}
                          <Button color="primary" onClick={saveStep2}>
                            <BsChevronRight size={20} />
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                )}
                {activeStep === 3 && (
                  <Row>
                    <Col xs={12}>
                      <span className="step-title">Travaux</span>
                    </Col>
                    <Col xs={12}>
                      <Card className="step-body">
                        <CardBody>
                          {travaux}
                          <div className="right mt-10 mr-25">
                            <BsPlusCircle
                              size={20}
                              className="pointer"
                              onClick={addTravaux}
                            />
                          </div>
                        </CardBody>
                        <CardFooter className="right">
                          <Button color="secondary" onClick={previousStep}>
                            <BsChevronLeft size={20} />
                          </Button>{" "}
                          <Button color="primary" onClick={saveStep3}>
                            <BsChevronRight size={20} />
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                )}
                {activeStep === 999 && (
                  <Row>
                    <Col xs={12}>
                      <span className="step-title">Coûts Fixes</span>
                    </Col>
                    <Col xs={12}>
                      <Card className="step-body">
                        <CardBody>
                          <CoutsFixes projet={uid} />
                        </CardBody>
                        <CardFooter className="right">
                          <Button color="secondary" onClick={previousStep}>
                            <BsChevronLeft size={20} />
                          </Button>{" "}
                          <Button color="primary" onClick={saveStep4}>
                            <BsChevronRight size={20} />
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                )}
                {activeStep === 4 && (
                  <Row>
                    <Col xs={12}>
                      <span className="step-title">Financement</span>
                    </Col>
                    <Col xs={12}>
                      <Card className="step-body">
                        <CardBody>
                          <Financement
                            projet={uid}
                            handleMontantAFinancer={setMontantAFinancer}
                            handleApport={setApport}
                            handleFraisDossier={setFraisDossier}
                            handleHypotheque={setHypotheque}
                            handleInteretsEmprunt={setInteretsEmprunt}
                            handleCommissionEngagement={setCommissionEngagement}
                            handleMontantExpertise={handleMontantExpertise}
                            handleModeFinancementProjet={
                              handleModeFinancementProjet
                            }
                            handleFinancementParticipatif={
                              handleFinancementParticipatif
                            }
                          />
                        </CardBody>
                        <CardFooter className="right">
                          <Button color="secondary" onClick={previousStep}>
                            <BsChevronLeft size={20} />
                          </Button>{" "}
                          <Button color="primary" onClick={saveStep5}>
                            <BsChevronRight size={20} />
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                )}
                {activeStep === 5 && (
                  <div>
                    <Row className="mt-25">
                      <Col xs={12} md={3}>
                        <Card className="card-project-kpi">
                          <CardHeader className="center card-project-header">
                            Chiffre d'affaire (HFA)
                          </CardHeader>
                          <CardBody className="kpi">
                            <div className="chiffre-affaire">
                              {prettyEuro(sommeVenteLots)}
                            </div>
                            <div className="frais-agence">
                              {prettyEuro(calculFraisAgenceVente(projet))} FA
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs={12} md={3}>
                        <Card className="card-project-kpi">
                          <CardHeader className="center card-project-header">
                            Coût total projet
                          </CardHeader>
                          <CardBody className="kpi">
                            <div className="cout-total-projet">
                              {prettyEuro(calculMontantTotalDepenses(projet))}
                            </div>
                            <div className="frais-agence">&nbsp;</div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs={12} md={3}>
                        <Card className="card-project-kpi">
                          <CardHeader className="center card-project-header">
                            Total dépenses
                          </CardHeader>
                          <CardBody className="kpi">
                            <div className="total-depenses">
                              {prettyEuro(calculCoutTotalFraisDepenses(projet))}
                            </div>
                            <div className="frais-agence">&nbsp;</div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs={12} md={3}>
                        <Card className="card-project-kpi">
                          <CardHeader className="center card-project-header">
                            Montant à financer
                          </CardHeader>
                          <CardBody className="kpi">
                            <div className="montant-a-financer">
                              {prettyEuro(montantAFinancer)}
                            </div>
                            <div className="frais-agence">&nbsp;</div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <Card className="card-project-kpi">
                          <CardHeader>
                            <span className="step-title">
                              Calcul marge du projet
                            </span>
                          </CardHeader>
                          <CardBody className="kpi">
                            <Table className="calcul-marge-projet-table">
                              <tbody>
                                <tr className="row-calcul-marge-projet-subtotal-category">
                                  <td
                                    colSpan={12}
                                    className="calcul-marge-projet-subtotal-category cell-calcul-marge-projet-subtotal-category"
                                  >
                                    {projet.libelle} -{" "}
                                    {refTypeProjet[projet.typeProjet]} -{" "}
                                    {refTypeBien[projet.typeDeBien]}
                                    {` ${projet.surface} m²`} - {projet.adresse}{" "}
                                    {projet.codePostal} {projet.ville}
                                  </td>
                                </tr>
                                <tr
                                  className={
                                    showDetailsTotalAcquisition === true
                                      ? "calcul-marge-projet-row"
                                      : "calcul-marge-projet-row hide"
                                  }
                                >
                                  <td className="calcul-marge-projet-category">
                                    Prix Acquisition
                                  </td>
                                  <td className="calcul-marge-projet-edit">
                                    <BsPencilSquare
                                      className="ml-10 pointer"
                                      onClick={() => goToStep(1)}
                                    />
                                  </td>
                                  <td className="calcul-marge-projet-amount">
                                    {prettyEuro(prixAchat)}
                                  </td>
                                </tr>
                                <tr
                                  className={
                                    showDetailsTotalAcquisition === true
                                      ? "calcul-marge-projet-row"
                                      : "calcul-marge-projet-row hide"
                                  }
                                >
                                  <td className="calcul-marge-projet-category">
                                    Frais d'agence
                                  </td>
                                  <td className="calcul-marge-projet-edit">
                                    <BsPencilSquare
                                      className="ml-10 pointer"
                                      onClick={() => goToStep(1)}
                                    />
                                  </td>
                                  <td className="calcul-marge-projet-amount">
                                    {prettyEuro(projet.fraisAgence)}
                                  </td>
                                </tr>
                                <tr
                                  className={
                                    showDetailsTotalAcquisition === true
                                      ? "calcul-marge-projet-row"
                                      : "calcul-marge-projet-row hide"
                                  }
                                >
                                  <td className="calcul-marge-projet-category">
                                    Frais notaire
                                  </td>
                                  <td className="calcul-marge-projet-edit">
                                    <BsPencilSquare
                                      className="ml-10 pointer"
                                      onClick={() => goToStep(1)}
                                    />
                                  </td>
                                  <td className="calcul-marge-projet-amount">
                                    {prettyEuro(
                                      calculFraisNotaireAchat(
                                        chargeVendeur === 0
                                          ? projet.prixAchat + fraisAgence
                                          : projet.prixAchat
                                      )
                                    )}
                                  </td>
                                </tr>
                                <tr className="row-calcul-marge-projet-subtotal-subcategory">
                                  <td
                                    colSpan={2}
                                    className="calcul-marge-projet-subtotal-category cell-calcul-marge-projet-subtotal-category"
                                  >
                                    Total acquisition{" "}
                                    <span
                                      className="hide-details"
                                      onClick={() =>
                                        setShowDetailsTotalAcquisition(
                                          !showDetailsTotalAcquisition
                                        )
                                      }
                                    >
                                      {showDetailsTotalAcquisition === true
                                        ? `Cacher les details`
                                        : `Voir les details`}
                                    </span>
                                  </td>
                                  <td className="calcul-marge-projet-amount blue cell-calcul-marge-projet-subtotal-category bold">
                                    {`${prettyEuro(
                                      parseInt(
                                        calculFraisAcquisition(projet) /
                                          projet.surface,
                                        0
                                      )
                                    )}/m²`}{" "}
                                    {prettyEuro(calculFraisAcquisition(projet))}
                                  </td>
                                </tr>
                                <tr
                                  className={
                                    showDetailsCoutTotalFraisDepenses === true
                                      ? "calcul-marge-projet-row"
                                      : "calcul-marge-projet-row hide"
                                  }
                                >
                                  <td className="calcul-marge-projet-category">
                                    Travaux
                                  </td>
                                  <td className="calcul-marge-projet-edit">
                                    <BsPencilSquare
                                      className="pointer"
                                      onClick={() => goToStep(3)}
                                    />
                                  </td>
                                  <td className="calcul-marge-projet-amount">
                                    {prettyEuro(
                                      calculMontantTravaux(
                                        projet.ProjetTravauxes
                                      )
                                    )}
                                  </td>
                                </tr>
                                {/* <tr
                                  className={
                                    showDetailsCoutTotalFraisDepenses === true
                                      ? "calcul-marge-projet-row"
                                      : "calcul-marge-projet-row hide"
                                  }
                                >
                                  <td className="calcul-marge-projet-category">
                                    Coûts fixes
                                  </td>
                                  <td className="calcul-marge-projet-edit">
                                    <BsPencilSquare
                                      className="pointer"
                                      onClick={() => goToStep(4)}
                                    />
                                  </td>
                                  <td className="calcul-marge-projet-amount">
                                    {prettyEuro(calculSommeCoutsFixes(projet))}
                                  </td>
                                </tr> */}
                                <tr
                                  className={
                                    showDetailsCoutTotalFraisDepenses === true
                                      ? "calcul-marge-projet-row"
                                      : "calcul-marge-projet-row hide"
                                  }
                                >
                                  <td className="calcul-marge-projet-category">
                                    Frais financement{" "}
                                  </td>
                                  <td className="calcul-marge-projet-edit">
                                    <BsPencilSquare
                                      className="pointer"
                                      onClick={() => goToStep(5)}
                                    />
                                  </td>
                                  <td className="calcul-marge-projet-amount">
                                    {prettyEuro(
                                      calculFraisFinancenementAvecMontantExpertise(
                                        projet
                                      )
                                    )}
                                  </td>
                                </tr>
                                <tr
                                  className={
                                    showDetailsCoutTotalFraisDepenses === true
                                      ? "calcul-marge-projet-row"
                                      : "calcul-marge-projet-row hide"
                                  }
                                >
                                  <td className="calcul-marge-projet-category">
                                    Financement participatif
                                  </td>
                                  <td className="calcul-marge-projet-edit">
                                    <BsPencilSquare
                                      className="pointer"
                                      onClick={() => goToStep(5)}
                                    />
                                  </td>
                                  <td className="calcul-marge-projet-amount">
                                    {prettyEuro(financementParticipatif)}
                                  </td>
                                </tr>
                                <tr className="row-calcul-marge-projet-subtotal-subcategory">
                                  <td
                                    colSpan={2}
                                    className="calcul-marge-projet-subtotal-category cell-calcul-marge-projet-subtotal-category"
                                  >
                                    Coût total frais/dépenses{" "}
                                    <span
                                      className="hide-details"
                                      onClick={() =>
                                        setShowDetailsCoutTotalFraisDepenses(
                                          !showDetailsCoutTotalFraisDepenses
                                        )
                                      }
                                    >
                                      {showDetailsCoutTotalFraisDepenses ===
                                      true
                                        ? `Cacher les details`
                                        : `Voir les details`}
                                    </span>
                                  </td>
                                  <td className="calcul-marge-projet-amount blue cell-calcul-marge-projet-subtotal-category bold">
                                    {prettyEuro(
                                      calculCoutTotalFraisDepenses(projet)
                                    )}
                                  </td>
                                </tr>
                                <br />
                                <tr className="row-calcul-marge-projet-subtotal-category">
                                  <td
                                    colSpan={2}
                                    className="calcul-marge-projet-subtotal-category cell-calcul-marge-projet-subtotal-category"
                                  >
                                    Coût total du projet
                                  </td>
                                  <td className="calcul-marge-projet-amount blue cell-calcul-marge-projet-subtotal-category bold">
                                    {prettyEuro(
                                      calculMontantTotalDepenses(projet)
                                    )}
                                  </td>
                                </tr>
                                {lotsBilan}
                                <tr
                                  className={
                                    showDetailsChiffreAffaireHFA === true
                                      ? "calcul-marge-projet-row"
                                      : "calcul-marge-projet-row hide"
                                  }
                                >
                                  <td
                                    colSpan={2}
                                    className="calcul-marge-projet-category"
                                  >
                                    Loyer
                                  </td>
                                  <td className="calcul-marge-projet-amount">
                                    {prettyEuro(calculMontantLoyers(projet))}
                                  </td>
                                </tr>
                                <tr className="row-calcul-marge-projet-subtotal-subcategory">
                                  <td
                                    colSpan={2}
                                    className="calcul-marge-projet-subtotal-category cell-calcul-marge-projet-subtotal-category"
                                  >
                                    Chiffre d'affaires HFA{" "}
                                    <span
                                      className="hide-details"
                                      onClick={() =>
                                        setShowDetailsChiffreAffaireHFA(
                                          !showDetailsChiffreAffaireHFA
                                        )
                                      }
                                    >
                                      {showDetailsChiffreAffaireHFA === true
                                        ? `Cacher les details`
                                        : `Voir les details`}
                                    </span>
                                  </td>
                                  <td className="calcul-marge-projet-amount blue cell-calcul-marge-projet-subtotal-category bold">
                                    {prettyEuro(
                                      calculChiffreAffaireHFA(projet)
                                    )}
                                  </td>
                                </tr>
                                <tr className="row-calcul-marge-projet-subtotal-subcategory">
                                  <td
                                    colSpan={2}
                                    className="calcul-marge-projet-subtotal-category cell-calcul-marge-projet-subtotal-category"
                                  >
                                    Frais d'agence
                                  </td>
                                  <td className="calcul-marge-projet-amount blue cell-calcul-marge-projet-subtotal-category bold">
                                    {prettyEuro(calculFraisAgenceVente(projet))}
                                  </td>
                                </tr>
                                <tr className="row-calcul-marge-projet-subtotal-subcategory">
                                  <td
                                    colSpan={2}
                                    className="calcul-marge-projet-subtotal-category cell-calcul-marge-projet-subtotal-category"
                                  >
                                    Chiffre d'affaires FAI
                                  </td>
                                  <td className="calcul-marge-projet-amount blue cell-calcul-marge-projet-subtotal-category bold">
                                    {prettyEuro(
                                      calculChiffreAffaire(projet) +
                                        calculFraisAgenceVente(projet)
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="calcul-marge-projet-category">
                                    Total TVA
                                  </td>
                                  <td className="calcul-marge-projet-edit"></td>
                                  <td className="calcul-marge-projet-amount">
                                    {prettyEuro(calculTVATotal(projet))}
                                  </td>
                                </tr>
                                <tr className="row-calcul-marge-projet-marge-nette mt-20">
                                  <td
                                    colSpan={2}
                                    className="calcul-marge-projet-subtotal-category white cell-calcul-marge-projet-subtotal-category"
                                  >
                                    Marge nette
                                  </td>
                                  <td className="white calcul-marge-projet-amount  cell-calcul-marge-projet-subtotal-category bold ">
                                    ({parseInt(calculTauxMargeNette(projet), 0)}
                                    %) {prettyEuro(margeNette)}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs={12} md={6}>
                        <Row>
                          <Col xs={12} md={6}>
                            <Card className="card-project-kpi">
                              <CardHeader className="center card-project-header">
                                Apport
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={
                                    projet.typeFinancement === false
                                      ? `L'apport est pour financer le bien + FA + frais notaire + travaux`
                                      : `L'apport est pour financer une partie du bien + les frais d'agence + frais notaire + TRX`
                                  }
                                >
                                  <FaQuestionCircle className="ml-10" />
                                </a>
                              </CardHeader>
                              <CardBody className="kpi">
                                <div className="montant-apport red">
                                  {prettyEuro(apport)}
                                </div>
                                <div className="frais-agence">
                                  <BsPencilSquare
                                    className="pointer"
                                    onClick={() => goToStep(5)}
                                  />{" "}
                                  {pourcentageApport}%
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs={12} md={6}>
                            <Card className="card-project-kpi">
                              <CardHeader className="center card-project-header">
                                Marge nette{" "}
                                <Badge
                                  color={
                                    tauxMargeNette < 0 ? "danger" : "primary"
                                  }
                                >
                                  {parseInt(tauxMargeNette, 0)}%
                                </Badge>
                              </CardHeader>
                              <CardBody className="kpi">
                                <div className="marge-nette">
                                  {prettyEuro(margeNette)}
                                </div>
                                <div className="frais-agence">&nbsp;</div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <Card className="card-project-kpi">
                              <CardHeader className="center card-project-header">
                                A financer en perso
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={
                                    projet.typeFinancement === false
                                      ? `L'apport + notaire + travaux + frais de financement + fin. part. + divers`
                                      : `Apport + Frais financement`
                                  }
                                >
                                  <FaQuestionCircle className="ml-10" />
                                </a>
                              </CardHeader>
                              <CardBody className="kpi">
                                <div className="montant-apport red">
                                  {prettyEuro(calculAFinancerPerso(projet))}
                                </div>
                                <div className="frais-agence">&nbsp;</div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs={12} md={6}>
                            <Card className="card-project-kpi">
                              <CardHeader className="center card-project-header">
                                TVA à verser
                              </CardHeader>
                              <CardBody className="kpi">
                                <div className="montant-tva">
                                  {prettyEuro(calculTVATotal(projet))}
                                </div>
                                <div className="tva-collectee-total">
                                  TVA Collectées{" "}
                                  <span
                                    className="show-details"
                                    onClick={() =>
                                      setShowTVACollecteesDetails(
                                        !showTVACollecteesDetails
                                      )
                                    }
                                  >
                                    details
                                  </span>
                                </div>
                                {showTVACollecteesDetails === true && (
                                  <div className="tva-collectee">
                                    <span className="tva-collectee-percent">
                                      20%
                                    </span>
                                    <span className="tva-collectee-value">
                                      {" "}
                                      {prettyEuro(montantTVA20)}
                                    </span>
                                  </div>
                                )}
                                {showTVACollecteesDetails === true && (
                                  <div className="tva-collectee">
                                    <span className="tva-collectee-percent">
                                      10%
                                    </span>
                                    <span className="tva-collectee-value">
                                      {" "}
                                      {prettyEuro(montantTVA10)}
                                    </span>
                                  </div>
                                )}
                                {showTVACollecteesDetails === true && (
                                  <div className="tva-collectee">
                                    <span className="tva-collectee-percent">
                                      5.5%
                                    </span>
                                    <span className="tva-collectee-value">
                                      {" "}
                                      {prettyEuro(montantTVA55)}
                                    </span>
                                  </div>
                                )}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            {projet.modeFinancementProjet === false && (
                              <Card className="card-project-kpi">
                                <CardHeader className="center card-project-header">
                                  Frais de dossier
                                  <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Payer au déblocage des fonds"
                                  >
                                    <FaQuestionCircle className="ml-10" />
                                  </a>
                                </CardHeader>
                                <CardBody className="kpi">
                                  <div className="montant-apport red">
                                    {prettyEuro(projet.fraisDossier)}
                                  </div>

                                  <div className="frais-agence">&nbsp;</div>
                                </CardBody>
                              </Card>
                            )}
                          </Col>
                          <Col xs={12} md={6}>
                            <Card className="card-project-kpi">
                              <CardHeader className="center card-project-header">
                                Divers
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Payer au déblocage des fonds"
                                >
                                  <FaQuestionCircle className="ml-10" />
                                </a>
                              </CardHeader>
                              <CardBody className="kpi">
                                <div className="montant-apport red">
                                  {prettyEuro(
                                    projet.modeFinancementProjet
                                      ? 0
                                      : projet.montantExpertise
                                  )}
                                </div>
                                <div className="frais-agence">&nbsp;</div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
                {activeStep === 5 && (
                  <Row>
                    <Col xs={12} md={6}>
                      {/* <Container fluid> */}
                      <Row>
                        <Col></Col>
                      </Row>
                      {/* <Row>
                        <Col xs={12}>
                          <span className="step-title-inside">
                            Grille prévisionnelle des ventes{" "}
                            <BsPencilSquare
                              className="pointer"
                              onClick={() => goToStep(2)}
                            />
                          </span>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col xs={12}>
                          <Card className="card-project-kpi">
                            <CardHeader>
                              <span className="step-title-inside">
                                Grille prévisionnelle des ventes{" "}
                                <BsPencilSquare
                                  className="pointer"
                                  onClick={() => goToStep(2)}
                                />
                              </span>
                            </CardHeader>
                            <CardBody>
                              <table width={`100%`}>
                                <tr>
                                  <td className="table-header">
                                    PROJET{" "}
                                    {projetVille
                                      ? projetVille.toUpperCase()
                                      : ""}
                                  </td>
                                  <td className="table-header right">HAUTE</td>
                                  <td className="table-header right">
                                    MOYENNE
                                  </td>
                                  <td className="table-header right">BASSE</td>
                                </tr>
                                {lotsGrilleVente}
                              </table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Card className="card-project-kpi">
                            <CardBody className="green">
                              <table width={`100%`} className="fs-14">
                                <tr>
                                  <td width={`45%`}>Total des lots</td>
                                  <td></td>
                                  <td></td>
                                  <td className="right">
                                    {projetLots.length} lot
                                    {projetLots.length > 1 ? "s" : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Loyer</td>
                                  <td className="right">
                                    {prettyEuro(calculMontantLoyers(projet))}
                                  </td>
                                  <td className="right">
                                    {prettyEuro(calculMontantLoyers(projet))}
                                  </td>
                                  <td className="right">
                                    {prettyEuro(calculMontantLoyers(projet))}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Chiffre d'affaire HFA</td>
                                  <td className="right">
                                    (
                                    {parseInt(
                                      calculTauxMargeNetteHaut(projet),
                                      10
                                    )}
                                    %){" "}
                                    {prettyEuro(
                                      calculChiffreAffaireHFAHaut(projet)
                                    )}
                                  </td>
                                  <td className="right">
                                    (
                                    {parseInt(
                                      calculTauxMargeNetteMoyen(projet),
                                      10
                                    )}
                                    %){" "}
                                    {prettyEuro(
                                      calculChiffreAffaireHFAMoyen(projet)
                                    )}
                                  </td>
                                  <td className="right">
                                    (
                                    {parseInt(calculTauxMargeNette(projet), 10)}
                                    %){" "}
                                    {prettyEuro(
                                      calculChiffreAffaireHFA(projet)
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Frais d'agence</td>
                                  <td className="right">
                                    {prettyEuro(
                                      calculFraisAgenceVenteHaut(projet)
                                    )}
                                  </td>
                                  <td className="right">
                                    {" "}
                                    {prettyEuro(
                                      calculFraisAgenceVenteMoyen(projet)
                                    )}
                                  </td>
                                  <td className="right">
                                    {prettyEuro(calculFraisAgenceVente(projet))}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Chiffre d'affaire FAI</td>
                                  <td className="right">
                                    {prettyEuro(
                                      calculChiffreAffaireHaut(projet) +
                                        calculFraisAgenceVenteHaut(projet)
                                    )}
                                  </td>
                                  <td className="right">
                                    {prettyEuro(
                                      calculChiffreAffaireMoyen(projet) +
                                        calculFraisAgenceVenteMoyen(projet)
                                    )}
                                  </td>
                                  <td className="right">
                                    {prettyEuro(
                                      calculChiffreAffaire(projet) +
                                        calculFraisAgenceVente(projet)
                                    )}
                                  </td>
                                </tr>
                              </table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col xs={12}>
                          <span className="step-title-inside">
                            Prix d'achat maximum
                          </span>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col xs={12}>
                          <Card className="card-project-kpi">
                            <CardHeader>
                              <span className="step-title-inside">
                                Prix d'achat maximum
                              </span>
                            </CardHeader>
                            <CardBody className="kpi">
                              <Table className="calcul-marge-projet-table">
                                <tbody>
                                  <tr className="calcul-marge-projet-row">
                                    <td className="calcul-marge-projet-category">
                                      Chiffre d'affaire (vente)
                                    </td>
                                    <td className="calcul-marge-projet-edit"></td>
                                    <td className="calcul-marge-projet-amount bold">
                                      {prettyEuro(sommeVenteLots)}
                                    </td>
                                  </tr>
                                  <tr className="calcul-marge-projet-row">
                                    <td className="calcul-marge-projet-category">
                                      Frais notaire
                                    </td>
                                    <td className="calcul-marge-projet-edit"></td>
                                    <td className="calcul-marge-projet-amount bold">
                                      {prettyEuro(
                                        calculFraisNotaireAchat(
                                          projet.chargeVendeur === 0
                                            ? projet.prixAchat +
                                                projet.fraisAgence
                                            : projet.prixAchat,
                                          1,
                                          projet.codePostal
                                        )
                                      )}
                                    </td>
                                  </tr>
                                  <tr className="calcul-marge-projet-row">
                                    <td className="calcul-marge-projet-category">
                                      Travaux
                                    </td>
                                    <td className="calcul-marge-projet-edit"></td>
                                    <td className="calcul-marge-projet-amount bold">
                                      {prettyEuro(
                                        calculMontantTravaux(
                                          projet.ProjetTravauxes
                                        )
                                      )}
                                    </td>
                                  </tr>
                                  {/* <tr className="calcul-marge-projet-row">
                                    <td className="calcul-marge-projet-category">
                                      Coûts fixes
                                    </td>
                                    <td className="calcul-marge-projet-edit"></td>
                                    <td className="calcul-marge-projet-amount bold">
                                      {prettyEuro(
                                        calculSommeCoutsFixes(projet)
                                      )}
                                    </td>
                                  </tr> */}
                                  <tr className="calcul-marge-projet-row">
                                    <td className="calcul-marge-projet-category">
                                      Frais financement
                                    </td>
                                    <td className="calcul-marge-projet-edit"></td>
                                    <td className="calcul-marge-projet-amount bold">
                                      {prettyEuro(
                                        calculFraisFinancenementAvecMontantExpertise(
                                          projet
                                        )
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="calcul-marge-projet-category">
                                      Financement participatif
                                    </td>
                                    <td className="calcul-marge-projet-edit"></td>
                                    <td className="calcul-marge-projet-amount">
                                      {prettyEuro(financementParticipatif)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="calcul-marge-projet-category">
                                      Marge
                                    </td>
                                    <td className="calcul-marge-projet-edit">
                                      <SmallPercentInput
                                        type="text"
                                        value={
                                          parseInt(
                                            tauxMargeNetteChange * 100,
                                            0
                                          ) / 100
                                        }
                                        onChange={(e) =>
                                          handleChangeTauxMargeNette(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td className="calcul-marge-projet-amount right">
                                      <div className="right">
                                        <Input
                                          type="text"
                                          className="right"
                                          style={{
                                            textAlign: "right",
                                            paddingRight: `0 !important`,
                                          }}
                                          value={prettyEuro(margeNetteChange)}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="row-calcul-marge-projet-marge-nette mt-20">
                                    <td
                                      colSpan={2}
                                      className="calcul-marge-projet-subtotal-category white cell-calcul-marge-projet-subtotal-category"
                                    >
                                      Prix d'achat max
                                    </td>
                                    <td className="white calcul-marge-projet-amount  cell-calcul-marge-projet-subtotal-category bold ">
                                      {prettyEuro(
                                        sommeVenteLots -
                                          calculFraisNotaireAchat(
                                            projet.chargeVendeur === 0
                                              ? projet.prixAchat +
                                                  projet.fraisAgence
                                              : projet.prixAchat,
                                            1,
                                            projet.codePostal
                                          ) -
                                          calculMontantTravaux(
                                            projet.ProjetTravauxes
                                          ) -
                                          calculSommeCoutsFixes(projet) -
                                          parseInt(
                                            calculFraisFinancenementAvecMontantExpertise(
                                              projet
                                            ),
                                            10
                                          ) -
                                          financementParticipatif -
                                          parseInt(margeNetteChange, 10)
                                      )}{" "}
                                      FAI
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      {/* </Container> */}
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={12}>
                          <Card className="card-project-kpi">
                            <CardHeader>
                              <span className="step-title-inside">
                                Répartition
                              </span>
                            </CardHeader>
                            <CardBody
                              className="kpi"
                              style={{ paddingLeft: 0 }}
                            >
                              <ResponsiveContainer width="100%" height={400}>
                                <PieChart>
                                  <Pie
                                    data={pieData}
                                    color="#000000"
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={120}
                                    fill="#8884d8"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                  >
                                    {pieData.map((entry, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={colors[index % colors.length]}
                                      />
                                    ))}
                                  </Pie>
                                  <PieTooltip content={<CustomTooltip />} />
                                  <PieLegend
                                    formatter={renderColorfulLegendText}
                                    align="center"
                                  />
                                </PieChart>
                              </ResponsiveContainer>
                            </CardBody>
                          </Card>
                        </Col>
                        {projet.latitude && projet.longitude && (
                          <Col xs={12}>
                            <Card className="card-project-kpi">
                              <CardHeader>
                                <span className="step-title-inside">
                                  Localisation
                                </span>
                              </CardHeader>
                              <CardBody className="kpi">
                                <div
                                  style={{
                                    paddingTop: `25px`,
                                    height: "450px",
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  <ReactStreetview
                                    apiKey={streetViewApiKey}
                                    streetViewPanoramaOptions={{
                                      position: {
                                        lng: projet.longitude,
                                        lat: projet.latitude,
                                      },
                                      pov: { heading: 100, pitch: 0 },
                                      zoom: 1,
                                    }}
                                  />
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                        {projet.modeFinancementProjet === false && (
                          <Col xs={12}>
                            <Card className="card-project-kpi">
                              <CardHeader>
                                <span className="step-title-inside">
                                  Frais de financement
                                </span>
                              </CardHeader>
                              <CardBody className="kpi">
                                <Table className="calcul-marge-projet-table">
                                  <tbody>
                                    <tr className="calcul-marge-projet-row">
                                      <td className="calcul-marge-projet-category">
                                        Montant en % et durée de l'emprunt
                                        (interêt + assu + marge banque){" "}
                                        <span className="bold">
                                          ({projet.dureeInteretsEmprunt}mois)
                                        </span>
                                      </td>
                                      <td className="calcul-marge-projet-edit"></td>
                                      <td className="calcul-marge-projet-amount bold">
                                        {projet.pourcentageInteretsEmprunt}%
                                      </td>
                                    </tr>
                                    <tr className="calcul-marge-projet-row">
                                      <td className="calcul-marge-projet-category">
                                        Intérêt total{" "}
                                        <span className="bold">
                                          (trimestriel :{" "}
                                          {prettyEuro(
                                            calculInteretsEmprunt(projet) / 4
                                          )}
                                          )
                                        </span>
                                      </td>
                                      <td className="calcul-marge-projet-edit"></td>
                                      <td className="calcul-marge-projet-amount bold">
                                        {prettyEuro(
                                          calculInteretsEmprunt(projet)
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="calcul-marge-projet-row">
                                      <td className="calcul-marge-projet-category">
                                        Commission d'engagement (
                                        {projet.pourcentageCommissionEngagement}
                                        %){" "}
                                        <span className="bold">
                                          ({projet.dureeCommissionEngagement}
                                          mois)
                                        </span>
                                      </td>
                                      <td className="calcul-marge-projet-edit"></td>
                                      <td className="calcul-marge-projet-amount bold">
                                        {prettyEuro(
                                          calculCommissionEngagement(projet)
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="calcul-marge-projet-row">
                                      <td className="calcul-marge-projet-category">
                                        Hypothèque{" "}
                                        <span className="bold">
                                          ({projet.pourcentageHypotheque}
                                          %) (12mois)
                                        </span>
                                      </td>
                                      <td className="calcul-marge-projet-edit"></td>
                                      <td className="calcul-marge-projet-amount bold">
                                        {prettyEuro(
                                          projet.hypotheque
                                            ? projet.hypotheque
                                            : 0
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="calcul-marge-projet-row">
                                      <td className="calcul-marge-projet-category">
                                        Frais dossier
                                      </td>
                                      <td className="calcul-marge-projet-edit"></td>
                                      <td className="calcul-marge-projet-amount bold">
                                        {prettyEuro(
                                          projet.fraisDossier
                                            ? projet.fraisDossier
                                            : 0
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="calcul-marge-projet-row">
                                      <td className="calcul-marge-projet-category">
                                        Montant divers
                                      </td>
                                      <td className="calcul-marge-projet-edit"></td>
                                      <td className="calcul-marge-projet-amount bold">
                                        {prettyEuro(
                                          projet.montantExpertise
                                            ? projet.montantExpertise
                                            : 0
                                        )}
                                      </td>
                                    </tr>
                                    {/* <tr className="calcul-marge-projet-row">
                                      <td className="calcul-marge-projet-category">
                                        Financement participatif
                                      </td>
                                      <td className="calcul-marge-projet-edit"></td>
                                      <td className="calcul-marge-projet-amount bold">
                                        {prettyEuro(
                                          projet.financementParticipatif
                                            ? projet.financementParticipatif
                                            : 0
                                        )}
                                      </td>
                                    </tr> */}
                                    <tr className="row-calcul-marge-projet-marge-nette mt-20">
                                      <td
                                        colSpan={2}
                                        className="calcul-marge-projet-subtotal-category white cell-calcul-marge-projet-subtotal-category"
                                      >
                                        Coût total financement
                                      </td>
                                      <td className="white calcul-marge-projet-amount  cell-calcul-marge-projet-subtotal-category bold ">
                                        {prettyEuro(
                                          calculFraisFinancenementAvecMontantExpertise(
                                            projet
                                          )
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                        <Col xs={12}>
                          <Card className="card-project-kpi">
                            <CardHeader>
                              <span className="step-title-inside">Travaux</span>
                            </CardHeader>
                            <CardBody>
                              {allStepsLots.length === 0 && (
                                <Alert color="warning">Pas de travaux</Alert>
                              )}
                              {allStepsLots.length > 0 && (
                                <Nav tabs>
                                  {allStepsLots.map((ptl, index) => {
                                    return (
                                      <NavItem key={index}>
                                        <NavLink
                                          className={
                                            travauxActiveTab ===
                                            `travaux_lot_${index}`
                                              ? "active flipping-tab"
                                              : ""
                                          }
                                          onClick={() =>
                                            setTravauxActiveTab(
                                              `travaux_lot_${index}`
                                            )
                                          }
                                        >
                                          Lot n°{index + 1}
                                        </NavLink>
                                      </NavItem>
                                    );
                                  })}
                                </Nav>
                              )}
                              <TabContent activeTab={travauxActiveTab}>
                                {allStepsLots.map((ptl, index) => {
                                  return (
                                    <TabPane
                                      key={index}
                                      tabId={`travaux_lot_${index}`}
                                      className="couts-fixes-tab"
                                    >
                                      <div className="type-bien">
                                        {refTypeBien[ptl.typeBien]}
                                      </div>
                                      <div className="step-subtitle-inside">
                                        Travaux{" "}
                                        <BsPencilSquare
                                          className="pointer"
                                          onClick={() => goToStep(3)}
                                        />
                                      </div>
                                      <BilanTravauxParLot
                                        lot={ptl}
                                        projet={projet}
                                      />
                                    </TabPane>
                                  );
                                })}
                              </TabContent>
                            </CardBody>
                          </Card>
                        </Col>
                        {allStepsLots.map((l, index) => {
                          //console.log(`Lot : ${l.uniqueId}`);

                          let montantLotHT20 = 0;
                          let montantLotTVA20 = 0;
                          let montantLotHT10 = 0;
                          let montantLotTVA10 = 0;
                          let montantLotHT5 = 0;
                          let montantLotTVA5 = 0;
                          let montantLotHT0 = 0;
                          let montantLotTVA0 = 0;

                          {
                            projet.ProjetTravauxes.forEach((pt) => {
                              //console.log(pt);
                              pt.ProjetTravauxLots.forEach((ptl) => {
                                if (ptl.projetLotUniqueId === l.uniqueId) {
                                  switch (pt.tauxTVA) {
                                    case 0:
                                      montantLotHT0 +=
                                        pt.prixHorsTaxe /
                                        pt.ProjetTravauxLots.length;
                                      montantLotTVA0 +=
                                        (pt.prixHorsTaxe *
                                          refTauxTVAValue[pt.tauxTVA]) /
                                        pt.ProjetTravauxLots.length;
                                      break;
                                    case 1:
                                      montantLotHT5 +=
                                        pt.prixHorsTaxe /
                                        pt.ProjetTravauxLots.length;
                                      montantLotTVA5 +=
                                        (pt.prixHorsTaxe *
                                          refTauxTVAValue[pt.tauxTVA]) /
                                        pt.ProjetTravauxLots.length;
                                      break;
                                    case 2:
                                      montantLotHT10 +=
                                        pt.prixHorsTaxe /
                                        pt.ProjetTravauxLots.length;
                                      montantLotTVA10 +=
                                        (pt.prixHorsTaxe *
                                          refTauxTVAValue[pt.tauxTVA]) /
                                        pt.ProjetTravauxLots.length;
                                      break;
                                    case 3:
                                      montantLotHT20 +=
                                        pt.prixHorsTaxe /
                                        pt.ProjetTravauxLots.length;
                                      montantLotTVA20 +=
                                        (pt.prixHorsTaxe *
                                          refTauxTVAValue[pt.tauxTVA]) /
                                        pt.ProjetTravauxLots.length;
                                      break;
                                  }
                                }
                              });
                            });
                          }

                          return (
                            <Col xs={12} key={index}>
                              <Card className="card-project-kpi">
                                <CardBody
                                  className="grey"
                                  style={{
                                    paddingRight: "45px",
                                  }}
                                >
                                  <table
                                    width={`100%`}
                                    style={{
                                      border: `none`,
                                    }}
                                  >
                                    <tr>
                                      <td style={{ width: "140px" }}>
                                        Total lot n°{index + 1}
                                      </td>
                                      <td>{prettyEuro(montantLotHT20)} HT</td>
                                      <td>
                                        {prettyEuro(montantLotTVA20)} TVA (20%)
                                      </td>
                                      <td>
                                        {prettyEuro(
                                          montantLotTVA20 + montantLotHT20
                                        )}{" "}
                                        TTC
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "140px" }}></td>
                                      <td>{prettyEuro(montantLotHT10)} HT</td>
                                      <td>
                                        {prettyEuro(montantLotTVA10)} TVA (10%)
                                      </td>
                                      <td>
                                        {prettyEuro(
                                          montantLotTVA10 + montantLotHT10
                                        )}{" "}
                                        TTC
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "140px" }}></td>
                                      <td>{prettyEuro(montantLotHT5)} HT</td>
                                      <td>
                                        {prettyEuro(montantLotTVA5)} TVA (5%)
                                      </td>
                                      <td>
                                        {prettyEuro(
                                          montantLotTVA5 + montantLotHT5
                                        )}{" "}
                                        TTC
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "140px" }}></td>
                                      <td>{prettyEuro(montantLotHT0)} HT</td>
                                      <td>
                                        {prettyEuro(montantLotTVA0)} TVA (0%)
                                      </td>
                                      <td>
                                        {prettyEuro(
                                          montantLotTVA0 + montantLotHT0
                                        )}{" "}
                                        TTC
                                      </td>
                                    </tr>
                                  </table>
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        })}
                        <Col xs={12}>
                          <Card className="card-project-kpi">
                            <CardBody
                              className="green"
                              style={{
                                paddingRight: "45px",
                              }}
                            >
                              <table
                                width={`100%`}
                                style={{
                                  border: `none`,
                                }}
                              >
                                <tr>
                                  <td>Total des lots HT</td>
                                  <td>TVA collectées</td>
                                  <td>Total des lots TTC</td>
                                </tr>
                                <tr>
                                  <td>
                                    {prettyEuro(calculSommeTravauxHT(projet))}
                                  </td>
                                  <td>
                                    20%&nbsp;&nbsp;
                                    {prettyEuro(
                                      calculSommeTVATravauxParTVA(projet)[0.2]
                                        ? calculSommeTVATravauxParTVA(
                                            projet
                                          )[0.2]
                                        : 0
                                    )}
                                  </td>
                                  <td>
                                    {prettyEuro(calculSommeTravauxTTC(projet))}
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>
                                    10%&nbsp;&nbsp;
                                    {prettyEuro(
                                      calculSommeTVATravauxParTVA(projet)[0.1]
                                        ? calculSommeTVATravauxParTVA(
                                            projet
                                          )[0.1]
                                        : 0
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>
                                    5%&nbsp;&nbsp;
                                    {prettyEuro(
                                      calculSommeTVATravauxParTVA(projet)[0.055]
                                        ? calculSommeTVATravauxParTVA(
                                            projet
                                          )[0.055]
                                        : 0
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>
                                    0%&nbsp;&nbsp;
                                    {prettyEuro(
                                      calculSommeTVATravauxParTVA(projet)[0]
                                        ? calculSommeTVATravauxParTVA(projet)[0]
                                        : 0
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              </table>
                            </CardBody>
                          </Card>
                        </Col>
                        {/* <Col xs={12}>
                          <Card className="card-project-kpi">
                            <CardHeader>
                              <span className="step-title-inside">
                                Coûts Fixes
                              </span>
                            </CardHeader>
                            <CardBody>
                              {allStepsLots.length === 0 && (
                                <Alert color="warning">Pas de travaux</Alert>
                              )}
                              {allStepsLots.length > 0 && (
                                <Nav tabs>
                                  {allStepsLots.map((ptl, index) => {
                                    return (
                                      <NavItem key={index}>
                                        <NavLink
                                          className={
                                            travauxActiveTab ===
                                            `travaux_lot_${index}`
                                              ? "active flipping-tab"
                                              : ""
                                          }
                                          onClick={() =>
                                            setTravauxActiveTab(
                                              `travaux_lot_${index}`
                                            )
                                          }
                                        >
                                          Lot n°{index + 1}
                                        </NavLink>
                                      </NavItem>
                                    );
                                  })}
                                </Nav>
                              )}
                              <TabContent activeTab={travauxActiveTab}>
                                {allStepsLots.map((ptl, index) => {
                                  return (
                                    <TabPane
                                      key={index}
                                      tabId={`travaux_lot_${index}`}
                                      className="couts-fixes-tab"
                                    >
                                      <div className="type-bien">
                                        {refTypeBien[ptl.typeBien]}
                                      </div>
                                      <div className="step-subtitle-inside">
                                        Coûts de gestion{" "}
                                        <BsPencilSquare
                                          className="pointer"
                                          onClick={() => goToStep(4)}
                                        />
                                      </div>
                                      <BilanCoutsGestionParLot
                                        lot={ptl}
                                        projet={projet}
                                      />
                                      <div className="step-subtitle-inside">
                                        Frais de vente{" "}
                                        <BsPencilSquare
                                          className="pointer"
                                          onClick={() => goToStep(4)}
                                        />
                                      </div>
                                      <BilanFraisVenteParLot
                                        lot={ptl}
                                        projet={projet}
                                      />
                                    </TabPane>
                                  );
                                })}
                              </TabContent>
                            </CardBody>
                          </Card>
                        </Col> */}

                        {/* {allStepsLots.map((ptl, index) => {
                          //console.log(`Total lot n°${index + 1}`);
                          //console.log(ptl);
                          let response = calculSommeCoutsFixesParLot(
                            projet,
                            ptl.uniqueId,
                            3
                          );

                          let montantFVLotHT20 = response._sommeHT;
                          let montantFVLotTVA20 = response._sommeTVA;

                          response = calculSommeCoutsFixesParLot(
                            projet,
                            ptl.uniqueId,
                            2
                          );

                          let montantFVLotHT10 = response._sommeHT;
                          let montantFVLotTVA10 = response._sommeTVA;

                          response = calculSommeCoutsFixesParLot(
                            projet,
                            ptl.uniqueId,
                            1
                          );

                          let montantFVLotHT5 = response._sommeHT;
                          let montantFVLotTVA5 = response._sommeTVA;

                          response = calculSommeCoutsFixesParLot(
                            projet,
                            ptl.uniqueId,
                            0
                          );

                          let montantFVLotHT0 = response._sommeHT;
                          let montantFVLotTVA0 = response._sommeTVA;

                          return (
                            <Col xs={12}>
                              <Card className="card-project-kpi">
                                <CardBody
                                  className="grey"
                                  style={{
                                    paddingRight: "45px",
                                  }}
                                >
                                  <table
                                    width={`100%`}
                                    style={{
                                      border: `none`,
                                    }}
                                  >
                                    <tr>
                                      <td style={{ width: "140px" }}>
                                        Total lot n°{index + 1}
                                      </td>
                                      <td>{prettyEuro(montantFVLotHT20)} HT</td>
                                      <td>
                                        {prettyEuro(montantFVLotTVA20)} TVA
                                        (20%)
                                      </td>
                                      <td>
                                        {prettyEuro(
                                          montantFVLotHT20 + montantFVLotTVA20
                                        )}{" "}
                                        TTC
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "140px" }}></td>
                                      <td>{prettyEuro(montantFVLotHT10)} HT</td>
                                      <td>
                                        {prettyEuro(montantFVLotTVA10)} TVA
                                        (10%)
                                      </td>
                                      <td>
                                        {prettyEuro(
                                          montantFVLotHT10 + montantFVLotTVA10
                                        )}{" "}
                                        TTC
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "140px" }}></td>
                                      <td>{prettyEuro(montantFVLotHT5)} HT</td>
                                      <td>
                                        {prettyEuro(montantFVLotTVA5)} TVA (5%)
                                      </td>
                                      <td>
                                        {prettyEuro(
                                          montantFVLotHT5 + montantFVLotTVA5
                                        )}{" "}
                                        TTC
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "140px" }}></td>
                                      <td>{prettyEuro(montantFVLotHT0)} HT</td>
                                      <td>
                                        {prettyEuro(montantFVLotTVA0)} TVA (0%)
                                      </td>
                                      <td>
                                        {prettyEuro(
                                          montantFVLotHT0 + montantFVLotTVA0
                                        )}{" "}
                                        TTC
                                      </td>
                                    </tr>
                                  </table>
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        })}
                        <Col xs={12}>
                          <Card className="card-project-kpi">
                            <CardBody
                              className="green"
                              style={{
                                paddingRight: "45px",
                              }}
                            >
                              <table
                                width={`100%`}
                                style={{
                                  border: `none`,
                                }}
                              >
                                <tr>
                                  <td>Total des lots HT</td>
                                  <td>TVA collectées</td>
                                  <td>Total des lots TTC</td>
                                </tr>
                                <tr>
                                  <td>
                                    {prettyEuro(
                                      calculSommeCoutsFixesHT(projet)
                                    )}
                                  </td>
                                  <td>
                                    20%&nbsp;&nbsp;
                                    {prettyEuro(
                                      calculSommeCoutsFixesParTVA(projet)[0.2]
                                    )}
                                  </td>
                                  <td>
                                    {prettyEuro(
                                      calculSommeCoutsFixesTTC(projet)
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>
                                    10%&nbsp;&nbsp;
                                    {prettyEuro(
                                      calculSommeCoutsFixesParTVA(projet)[0.1]
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>
                                    5%&nbsp;&nbsp;
                                    {prettyEuro(
                                      calculSommeCoutsFixesParTVA(projet)[0.055]
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>
                                    0%&nbsp;&nbsp;
                                    {prettyEuro(
                                      calculSommeCoutsFixesParTVA(projet)[0]
                                    )}
                                  </td>
                                  <td></td>
                                </tr>
                              </table>
                            </CardBody>
                          </Card>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                )}
              </Container>
            </Col>
            {activeStep < 5 && (
              <Col xs={12} md={4}>
                <Container fluid>
                  <Row>
                    <Col xs={12} md={6} className="mt-20">
                      <span className="bilan-projet-title">
                        Bilan du projet
                      </span>
                    </Col>
                    <Col xs={12} md={6} className="mt-20 right">
                      <Button
                        color="primary"
                        disabled={exportInProgress}
                        onClick={() => handleGenerateExport(uid)}
                      >
                        <BsCloudDownload size={20} className="" />{" "}
                        {exportInProgress
                          ? "Export en cours ..."
                          : "Export Bilan"}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Card className="full-height mt-10">
                        <CardBody>
                          {activeStep > 0 && (
                            <div className="bilan-project-step">
                              <Container fluid className="no-pl">
                                <Row>
                                  <Col xs={12}>
                                    <div className="projet-summary-vente">
                                      <table style={{ width: "100%" }}>
                                        <tbody>
                                          <tr>
                                            <td style={{ width: "20px" }}>
                                              <span className="bilan-project-step-nombre">
                                                1
                                              </span>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {libelle} à {ville}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} className="right">
                                    <span className="projet-summary-sub">
                                      {refTypeProjet[typeProjet]}
                                    </span>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          )}
                          {activeStep > 1 && (
                            <div className="bilan-project-step">
                              <Container fluid className="no-pl">
                                <Row>
                                  <Col xs={12}>
                                    <div className="projet-summary-vente">
                                      <table style={{ width: "100%" }}>
                                        <tbody>
                                          <tr>
                                            <td style={{ width: "20px" }}>
                                              <span className="bilan-project-step-nombre">
                                                2
                                              </span>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              Achat
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {prettyEuro(
                                                projet.prixAchat +
                                                  projet.fraisAgence +
                                                  calculFraisNotaireAchat(
                                                    chargeVendeur === 0
                                                      ? projet.prixAchat +
                                                          projet.fraisAgence
                                                      : projet.prixAchat,
                                                    1,
                                                    projet.codePostal
                                                  )
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          )}
                          {activeStep > 2 && (
                            <div className="bilan-project-step">
                              <Container fluid className="no-pl">
                                <Row>
                                  <Col xs={12}>
                                    <div className="projet-summary-vente">
                                      <table style={{ width: "100%" }}>
                                        <tbody>
                                          <tr>
                                            <td style={{ width: "20px" }}>
                                              <span className="bilan-project-step-nombre">
                                                3
                                              </span>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              Vente
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {prettyEuro(sommeVenteLots)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          )}
                          {activeStep > 3 && (
                            <div className="bilan-project-step">
                              <Container fluid className="no-pl">
                                <Row>
                                  <Col xs={12}>
                                    <div className="projet-summary-vente">
                                      <table style={{ width: "100%" }}>
                                        <tbody>
                                          <tr>
                                            <td style={{ width: "20px" }}>
                                              <span className="bilan-project-step-nombre">
                                                4
                                              </span>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              Travaux
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {prettyEuro(sommeTravauxTTC)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          )}
                          {activeStep >= 4 && (
                            <div className="bilan-project-step">
                              <Container fluid className="no-pl">
                                <Row>
                                  <Col xs={12}>
                                    <div className="projet-summary-vente">
                                      <table style={{ width: "100%" }}>
                                        <tbody>
                                          <tr>
                                            <td style={{ width: "20px" }}>
                                              <span className="bilan-project-step-nombre">
                                                5
                                              </span>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              Financement
                                            </td>
                                          </tr>
                                          <tr className="projet-summary-sub">
                                            <td style={{ width: "20px" }}></td>
                                            <td style={{ textAlign: "left" }}>
                                              A financer
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {modeFinancementProjet === false
                                                ? prettyEuro(montantAFinancer)
                                                : prettyEuro(0)}
                                            </td>
                                          </tr>
                                          <tr className="projet-summary-sub">
                                            <td style={{ width: "20px" }}></td>
                                            <td style={{ textAlign: "left" }}>
                                              Apport
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {prettyEuro(apport)}
                                            </td>
                                          </tr>
                                          <tr className="projet-summary-sub">
                                            <td style={{ width: "20px" }}></td>
                                            <td style={{ textAlign: "left" }}>
                                              Frais de dossier
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {!modeFinancementProjet
                                                ? prettyEuro(fraisDossier)
                                                : prettyEuro(0)}
                                            </td>
                                          </tr>

                                          <tr className="projet-summary-sub">
                                            <td style={{ width: "20px" }}></td>
                                            <td style={{ textAlign: "left" }}>
                                              Intérêts d'emprunt
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {!modeFinancementProjet
                                                ? prettyEuro(interetsEmprunt)
                                                : prettyEuro(0)}
                                            </td>
                                          </tr>
                                          <tr className="projet-summary-sub">
                                            <td style={{ width: "20px" }}></td>
                                            <td style={{ textAlign: "left" }}>
                                              Hypothèque
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {!modeFinancementProjet
                                                ? prettyEuro(hypotheque)
                                                : prettyEuro(0)}
                                            </td>
                                          </tr>
                                          <tr className="projet-summary-sub">
                                            <td style={{ width: "20px" }}></td>
                                            <td style={{ textAlign: "left" }}>
                                              Financement participatif
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {!modeFinancementProjet
                                                ? prettyEuro(
                                                    financementParticipatif
                                                  )
                                                : prettyEuro(0)}
                                            </td>
                                          </tr>
                                          <tr className="projet-summary-sub">
                                            <td style={{ width: "20px" }}></td>
                                            <td style={{ textAlign: "left" }}>
                                              Commission Engagement
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {!modeFinancementProjet
                                                ? prettyEuro(
                                                    commissionEngagement
                                                  )
                                                : prettyEuro(0)}
                                            </td>
                                          </tr>
                                          <tr className="projet-summary-sub">
                                            <td style={{ width: "20px" }}></td>
                                            <td style={{ textAlign: "left" }}>
                                              Divers
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {modeFinancementProjet === false
                                                ? prettyEuro(montantExpertise)
                                                : prettyEuro(0)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </Col>
            )}
          </Row>
        </Container>
        <ToastContainer />
      </section>
    </div>
  );
}

export default ProjectPage;
