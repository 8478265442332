import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  Label,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { FaEye } from "react-icons/fa";
import Navbar from "./layout/Navbar";
import TypesProjet from "./Components/data/TypeProjet.json";
import { getData, putData, toastConfig, prettyEuro } from "../utils";
import CleanDate from "./Components/CleanDate";
import "./AdministrationPage.css";

function UtilisateurPage() {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [uid, setUid] = useState("");
  const [utilisateur, setUtilisateur] = useState({});
  const [projets, setProjets] = useState([]);
  const [nomPrenom, setNomPrenom] = useState("");

  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const profiles = await getData("/v1/me/profiles");
        if (profiles.includes("ADMIN")) {
          setIsAdmin(true);
        }

        const u = await getData(`/v1/utilisateurs/${uid}`);
        setUtilisateur(u);
        setNomPrenom(`${u.nom} ${u.prenom}`);
        console.log(u);
        setProjets(Array.isArray(u.Projets) ? u.Projets : []);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          navigate("/");
        }
        toast.error(
          error.response?.data?.message || "Unknown error",
          toastConfig
        );
      }
    }

    if (uid !== params.uid) {
      setUid(params.uid);
    }

    fetchData();
  }, [navigate, uid, params.uid]);

  const handleChangeProfile = async (e) => {
    try {
      const u = await putData(`/v1/utilisateurs/${uid}`, {
        isAdmin: e.target.value,
      });
      setUtilisateur(u);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const projectRows = projets.map((projet, index) => (
    <tr key={index}>
      <td>{projet.libelle}</td>
      <td>{TypesProjet[projet.typeProjet]}</td>
      <td>{prettyEuro(projet.prixAchat)}</td>
      <td>
        {projet.codePostal} {projet.ville}
      </td>
      <td>
        <Link to={`/projets/${projet.uniqueId}`}>
          <FaEye />
        </Link>
      </td>
    </tr>
  ));

  return (
    <div>
      <Navbar isLoggedIn={true} isAdmin={isAdmin} />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <h2 className="pagetitle">{nomPrenom}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/administration">Administration</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {nomPrenom}
              </li>
            </ol>
          </nav>
        </div>
      </nav>
      <section className="vh-875">
        <Container fluid className="mt-20">
          <Row>
            <Col xs={12}>
              <Card>
                <Container fluid>
                  <Row>
                    <Col xs={12} md={4}>
                      <Form>
                        <FormGroup>
                          <Label className="property-label">eMail:</Label>
                          <div>{utilisateur.email}</div>
                        </FormGroup>
                        <FormGroup>
                          <Label className="property-label">
                            Date de creation:
                          </Label>
                          <div>
                            <CleanDate date={utilisateur.createdAt} />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label className="property-label">Profile:</Label>
                          <div>
                            <Input
                              type="select"
                              value={utilisateur.isAdmin}
                              onChange={(e) => handleChangeProfile(e)}
                            >
                              <option value={false}>Utilisateur</option>
                              <option value={true}>Administrateur</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col xs={12} md={8}>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Libellé</th>
                            <th>Type de projet</th>
                            <th>Prix d'achat</th>
                            <th>Localisation</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>{projectRows}</tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default UtilisateurPage;
