import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

import { Link, useNavigate } from "react-router-dom";
import Navbar from "./layout/Navbar";

import "./FraisNotaire.css";
import { FaQuestionCircle } from "react-icons/fa";

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import { toastConfig, prettyEuro, getData } from "../utils";
import CurrencyInput from "./Components/CurrencyInput";

function FraisNotairePage() {
  const [prixReventePotentiel, setPrixReventePotentiel] = useState(0);
  const [prixAdjudication, setPrixAdjudication] = useState(0);
  const [honorairesAvocat, setHonorairesAvocat] = useState(0);
  const [emolumentsCreancierPoursuivant, setEmolumentsCreancierPoursuivant] =
    useState(0);
  const [
    emolumentsCreancierAdjudicataire,
    setEmolumentsCreancierAdjudicataire,
  ] = useState(0);
  const [TVASurEmoluments, setTVASurEmoluments] = useState(0);
  const [fraisPrealable, setFraisPrealable] = useState(0);
  const [totalFrais, setTotalFrais] = useState(0);
  const [fraisPublication, setFraisPublication] = useState(0);
  const [droitsEnregistrement, setDroitsEnregistrement] = useState(0);
  const [fraisAdjudication, setFraisAdjudication] = useState(0);
  const [coutOperation, setCoutOperation] = useState(0);
  const [marge, setMarge] = useState(0);
  const [tauxMarge, setTauxMarge] = useState("");
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [montantTravaux, setMontantTravaux] = useState(0);

  const navigate = useNavigate();

  const resetCalcul = () => {
    setFraisAdjudication("");
    setCoutOperation("");
    setMarge("");
    setTauxMarge("");
  };

  const handleComputeFraisEnchere = () => {
    const val1Adjudication = 0;
    const val2Adjudication = 6500;
    const val3Adjudication = 6501;
    const val4Adjudication = 17000;
    const val5Adjudication = 17001;
    const val6Adjudication = 60000;
    const val7Adjudication = 60001;
    const pourcentage1Adjudication = 0.07256;
    const pourcentage2Adjudication = 0.02993;
    const pourcentage3Adjudication = 0.01995;
    const pourcentage4Adjudication = 0.01497;
    const taxeEmolument = 0.2;
    const taxeDepartAdjudication = 0.045;
    const taxeCommunale = 0.012;
    const fraisAssiette = 0.0237;
    const taxeFraisMarchand = 0.00715;
    const taxeFraisPublication = 0.001;
    const baseFraisPublication = 46;

    let val_1 = val2Adjudication * pourcentage1Adjudication;
    let val_2 =
      (val4Adjudication - val3Adjudication) * pourcentage2Adjudication;
    let val_3 =
      (val6Adjudication - val5Adjudication) * pourcentage3Adjudication;

    let val_4 =
      (parseInt(prixAdjudication, 10) - val7Adjudication) *
      pourcentage4Adjudication;
    val_3 = val_3 + val_4;

    if (parseInt(prixAdjudication, 10) <= 60000) {
      val_3 =
        (parseInt(prixAdjudication, 10) - val5Adjudication) *
        pourcentage3Adjudication;
    }
    console.log(`val_1 : ${val_1}`);
    console.log(`val_2 : ${val_2}`);
    console.log(`val_3 : ${val_3}`);

    const emolumentsHT = val_1 + val_2 + val_3;
    console.log(`emolumentsHT : ${emolumentsHT}`);
    const _emolumentsCreancierPoursuivant = (emolumentsHT / 4) * 3;
    console.log(
      `_emolumentsCreancierPoursuivant : ${_emolumentsCreancierPoursuivant}`
    );
    setEmolumentsCreancierPoursuivant(_emolumentsCreancierPoursuivant);
    const _emolumentsCreancierAdjudicataire = emolumentsHT / 4;
    console.log(
      `_emolumentsCreancierAdjudicataire : ${_emolumentsCreancierAdjudicataire}`
    );
    setEmolumentsCreancierAdjudicataire(_emolumentsCreancierAdjudicataire);
    const _TVASurEmoluments = emolumentsHT * taxeEmolument;
    console.log(`_TVASurEmoluments : ${_TVASurEmoluments}`);
    setTVASurEmoluments(_TVASurEmoluments);
    const _droitsEnregistrement = prixAdjudication * taxeFraisMarchand;
    console.log(`_droitsEnregistrement : ${_droitsEnregistrement}`);
    setDroitsEnregistrement(_droitsEnregistrement);
    const _fraisPublication =
      prixAdjudication * taxeFraisPublication + baseFraisPublication;
    console.log(`_fraisPublication : ${_fraisPublication}`);
    setFraisPublication(_fraisPublication);
    const _fraisAdjudication = parseInt(
      emolumentsHT +
        emolumentsHT * taxeEmolument +
        _droitsEnregistrement +
        _fraisPublication +
        fraisPrealable,
      10
    );
    console.log(`_fraisAdjudication : ${_fraisAdjudication}`);
    setFraisAdjudication(_fraisAdjudication);
    const _coutOperation =
      parseInt(honorairesAvocat, 10) +
      parseInt(montantTravaux, 10) +
      parseInt(prixAdjudication, 10) +
      _fraisAdjudication +
      parseInt(fraisPrealable, 10);
    console.log(`_coutOperation : ${_coutOperation}`);
    setCoutOperation(_coutOperation);

    const _marge = prixReventePotentiel - _coutOperation;
    console.log(`_marge : ${_marge}`);
    setMarge(_marge);

    const _tauxMarge = parseInt((_marge / prixReventePotentiel) * 100, 10);
    console.log(`_tauxMarge : ${_tauxMarge}`);
    setTauxMarge(_tauxMarge);
  };

  useEffect(() => {
    console.log("useEffect FraisNotairePage");
    async function getUseEffectData() {
      try {
        const profiles = await getData("/v1/me/profiles");
        if (profiles.includes("ADMIN")) {
          setIsAdmin(true);
        }
      } catch (error) {
        console.log(JSON.parse(error).error.status === 403);

        if (JSON.parse(error).error.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }

    getUseEffectData();
  }, [navigate]);
  return (
    <div>
      <Tooltip id="my-tooltip" />
      <Navbar isLoggedIn={true} isAdmin={isAdmin} />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <h2 className="pagetitle">Calculateur</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item">Calculateur Frais d'enchères</li>
            </ol>
          </nav>
        </div>
      </nav>
      <section className="vh-875">
        <Container fluid>
          <Row>
            <Col xs={12} md={8}>
              <Card className="step-body">
                <span className="step-title">
                  Calculez vos coûts d'acquisition aux enchères immobilières{" "}
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={`Calcul à titre indicatif et ne remplace pas l'évaluation des frais d'acquisition que votre avocat doit vous remettre`}
                  >
                    <FaQuestionCircle className="ml-10" />
                  </a>
                  <Card className="inside-body">
                    <Container fluid>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Prix de revente potentiel
                            </Label>
                            <CurrencyInput
                              onChange={(e) => {
                                resetCalcul();
                                setPrixReventePotentiel(e.target.value);
                              }}
                              value={prixReventePotentiel}
                              style={{ width: "100%" }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Montant des travaux
                            </Label>
                            <CurrencyInput
                              onChange={(e) => {
                                resetCalcul();
                                setMontantTravaux(e.target.value);
                              }}
                              value={montantTravaux}
                              style={{ width: "100%" }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Prix d'adjudication
                            </Label>
                            <CurrencyInput
                              onChange={(e) => {
                                resetCalcul();
                                setPrixAdjudication(e.target.value);
                              }}
                              value={prixAdjudication}
                              style={{ width: "100%" }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Frais préalable
                            </Label>
                            <CurrencyInput
                              onChange={(e) => {
                                resetCalcul();
                                setFraisPrealable(e.target.value);
                              }}
                              value={fraisPrealable}
                              style={{ width: "100%" }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Honoraires avocat
                            </Label>
                            <CurrencyInput
                              onChange={(e) => {
                                resetCalcul();
                                setHonorairesAvocat(e.target.value);
                              }}
                              value={honorairesAvocat}
                              style={{ width: "100%" }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Frais adjudication
                            </Label>
                            <CurrencyInput
                              disabled
                              value={fraisAdjudication}
                              style={{ width: "100%" }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-20">
                        <Col xs={12}>
                          <Button
                            style={{ width: "100%" }}
                            color="primary"
                            onClick={handleComputeFraisEnchere}
                          >
                            Calculer
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mt-20">
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Coût de l'opération
                            </Label>
                            <CurrencyInput
                              disabled
                              style={{ width: "100%" }}
                              value={coutOperation}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Marge{" "}
                              {tauxMarge !== "" ? (
                                <Badge color="primary">{tauxMarge}%</Badge>
                              ) : (
                                ""
                              )}
                            </Label>
                            <CurrencyInput
                              disabled
                              style={{ width: "100%" }}
                              value={marge}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                </span>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="step-body">
                <span className="step-title">
                  Détails de vos coûts d'acquisition
                </span>
                <div className="frais-notaire-summary">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20px" }}>
                          <span className="bilan-project-step-nombre">1</span>
                        </td>
                        <td style={{ textAlign: "left" }}>Prix adjudication</td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(prixAdjudication)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20px" }}>
                          <span className="bilan-project-step-nombre">2</span>
                        </td>
                        <td style={{ textAlign: "left" }}>Frais préalable</td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(fraisPrealable)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20px" }}>
                          <span className="bilan-project-step-nombre">3</span>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          Frais adjudication
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(fraisAdjudication)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20px" }}>
                          <span className="bilan-project-step-nombre">4</span>
                        </td>
                        <td style={{ textAlign: "left" }}>Honoraires avocat</td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(honorairesAvocat)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20px" }}>
                          <span className="bilan-project-step-nombre">5</span>
                        </td>
                        <td style={{ textAlign: "left" }}>Travaux</td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(montantTravaux)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Prix revente
                        </td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>
                          {prettyEuro(prixReventePotentiel)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Coût de l'opération
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontWeight: "bold",
                            color: "#F79292",
                          }}
                        >
                          {prettyEuro(coutOperation)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Marge{" "}
                          {tauxMarge !== "" ? (
                            <Badge color="primary">{tauxMarge}%</Badge>
                          ) : (
                            ""
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontWeight: "bold",
                            color: "#3cb599",
                          }}
                        >
                          {prettyEuro(marge)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </section>
    </div>
  );
}

export default FraisNotairePage;
