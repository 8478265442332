import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import moment from "moment";
import { BsPen, BsCloudDownload, BsTrash } from "react-icons/bs";

import "./ProjectCard.css";
import { prettyEuro } from "../../utils";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Navigate } from "react-router-dom";

import {
  getData,
  putData,
  deleteData,
  toastConfig,
  generateExport,
} from "../../utils";

import { calculMargeNette, calculCoutProjet } from "./helpers/calculsProjet";

import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr"; // the locale you want
import "react-datepicker/dist/react-datepicker.min.css";

import Toggle from "react-toggle";

registerLocale("fr", fr); // register it with the name you want

const ProjectCard = (props) => {
  const navigate = useNavigate();
  const [display, setDisplay] = useState("block");
  const [margeNette, setMargeNette] = useState(0);
  const [coutProjet, setCoutProjet] = useState(0);
  const [projet, setProjet] = useState(props.projet);
  const [typeDate, setTypeDate] = useState("");
  const [categoryDate, setCategoryDate] = useState("");
  const [modalSetDate, setModalSetDate] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [itemOrder, setItemOrder] = useState(0);
  const [toProjet, setToProjet] = React.useState(false);
  const [newProjet, setNewProjet] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState("");

  useEffect(() => {
    async function getUseEffectData() {
      //console.log(`useEffect ${props.i}`);
      setItemOrder(props.i);

      const projet = await getData(`/v1/projets/${props.projet.uniqueId}`);
      const _margeNette = calculMargeNette(projet);
      const _coutProjet = calculCoutProjet(projet);
      setMargeNette(_margeNette);
      setCoutProjet(_coutProjet);
    }
    getUseEffectData();
  }, [props.projet, props.i, itemOrder]);

  if (toProjet === true) {
    return <Navigate to={`/projets/${newProjet}`} />;
  }

  const toggleModalSetDate = (id, category, type, currentDate) => {
    console.log(id, category, type, new Date());
    setCurrentId(id);
    setCategoryDate(category);
    setSelectedDate(currentDate ? new Date(currentDate) : new Date());
    setTypeDate(type);
    setModalSetDate(!modalSetDate);
  };

  const exportProjet = async () => {
    console.log(`exportProjet`);
    try {
      await generateExport(projet.uniqueId);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleDateChange = (value) => {
    //console.log(value);
    setSelectedDate(value);
  };

  const modifierProjet = async () => {
    try {
      setNewProjet(projet.uniqueId);
      setToProjet(true);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const supprimerProjet = async () => {
    try {
      await deleteData(`/v1/projets/${projet.uniqueId}`);
      setDisplay("none");
      await props.refreshIndicateurs();
      await props.refreshGraphs();
      await props.refreshProjets();
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleLotVendu = async (id) => {
    try {
      const lot = await getData(`/v1/projets/${projet.uniqueId}/lots/${id}`);
      await putData(`/v1/projets/${projet.uniqueId}/lots/${id}`, {
        lotVendu: !lot.lotVendu,
      });
      let p = await getData(`/v1/projets/${projet.uniqueId}`);
      setProjet(p);
      await props.refreshIndicateurs();
      await props.refreshGraphs();
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleProjetAchete = async (id) => {
    try {
      const projet = await getData(`/v1/projets/${id}`);
      await putData(`/v1/projets/${id}`, {
        achatRealise: !projet.achatRealise,
      });
      let p = await getData(`/v1/projets/${id}`);
      setProjet(p);
      await props.refreshIndicateurs();
      await props.refreshGraphs();
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const setStepDate = async () => {
    try {
      //console.log("setStepDate");
      //console.log(categoryDate, typeDate);
      if (categoryDate === "projet") {
        let p = projet;
        switch (typeDate) {
          case "visite":
            p.visiteDate = selectedDate;
            //console.log(selectedDate);
            setProjet(p);
            await putData(`/v1/projets/${currentId}`, {
              visiteDate: selectedDate,
            });
            break;
          case "offre":
            p.offreDate = selectedDate;
            setProjet(p);
            await putData(`/v1/projets/${currentId}`, {
              offreDate: selectedDate,
            });
            break;
          case "compromis":
            p.compromisDate = selectedDate;
            setProjet(p);
            await putData(`/v1/projets/${currentId}`, {
              compromisDate: selectedDate,
            });
            break;
          case "acte-definitif":
            p.acteDefinitifDate = selectedDate;
            setProjet(p);
            await putData(`/v1/projets/${currentId}`, {
              acteDefinitifDate: selectedDate,
            });
            break;
          default:
            toast.message(`Type de date inconnu ${typeDate}`);
        }
      } else {
        /*let lot = await getData(
          `/v1/projets/${projet.uniqueId}/lots/${currentId}`
        );*/
        switch (typeDate) {
          case "visite":
            await putData(`/v1/projets/${projet.uniqueId}/lots/${currentId}`, {
              visiteDate: selectedDate,
            });
            break;
          case "offre":
            await putData(`/v1/projets/${projet.uniqueId}/lots/${currentId}`, {
              offreDate: selectedDate,
            });
            break;
          case "compromis":
            await putData(`/v1/projets/${projet.uniqueId}/lots/${currentId}`, {
              compromisDate: selectedDate,
            });
            break;
          case "acte-definitif":
            //p.acteDefinitifDate = selectedDate;
            //setProjet(p);
            await putData(`/v1/projets/${projet.uniqueId}/lots/${currentId}`, {
              acteDefinitifDate: selectedDate,
            });
            break;
          default:
            toast.message(`Type de date inconnu ${typeDate}`);
        }

        let p = await getData(`/v1/projets/${projet.uniqueId}`);
        setProjet(p);
      }
      setSelectedDate("");
      setModalSetDate(false);
      await props.refreshIndicateurs();
      await props.refreshGraphs();
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeStepDate = async () => {
    try {
      //console.log("removeStepDate");
      //console.log(categoryDate, typeDate);
      if (categoryDate === "projet") {
        let p = projet;
        switch (typeDate) {
          case "visite":
            p.visiteDate = null;
            setProjet(p);
            await putData(`/v1/projets/${currentId}`, {
              visiteDate: null,
            });
            break;
          case "offre":
            p.offreDate = null;
            setProjet(p);
            await putData(`/v1/projets/${currentId}`, {
              offreDate: null,
            });
            break;
          case "compromis":
            p.compromisDate = null;
            setProjet(p);
            await putData(`/v1/projets/${currentId}`, {
              compromisDate: null,
            });
            break;
          case "acte-definitif":
            p.acteDefinitifDate = null;
            setProjet(p);
            await putData(`/v1/projets/${currentId}`, {
              acteDefinitifDate: null,
            });
            break;
          default:
            toast.message(`Type de date inconnu ${typeDate}`);
        }
      } else {
        switch (typeDate) {
          case "visite":
            await putData(`/v1/projets/${projet.uniqueId}/lots/${currentId}`, {
              visiteDate: null,
            });
            break;
          case "offre":
            await putData(`/v1/projets/${projet.uniqueId}/lots/${currentId}`, {
              offreDate: null,
            });
            break;
          case "compromis":
            await putData(`/v1/projets/${projet.uniqueId}/lots/${currentId}`, {
              compromisDate: null,
            });
            break;
          case "acte-definitif":
            await putData(`/v1/projets/${projet.uniqueId}/lots/${currentId}`, {
              acteDefinitifDate: null,
            });
            break;
          default:
            toast.message(`Type de date inconnu ${typeDate}`);
        }

        let p = await getData(`/v1/projets/${projet.uniqueId}`);
        setProjet(p);
      }
      setSelectedDate("");
      setModalSetDate(false);
      await props.refreshIndicateurs();
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const lots = projet.ProjetLots.sort((a, b) => a.itemOrder - b.itemOrder).map(
    (lot, index) => {
      return (
        <Row key={index} className="pt-10">
          <Col xs={12} md={2} className="project-card-title-line">
            <div className="project-line-step-header-name">
              Lot {lot.itemOrder}
            </div>
            <div className="project-line-step-header-price">
              {prettyEuro(lot.prixVente)}
            </div>
          </Col>
          <Col xs={12} md={2} className="project-card-title-line">
            <div
              className={
                lot.visiteDate
                  ? `project-line-step step-done-visite`
                  : `project-line-step`
              }
              onClick={() =>
                toggleModalSetDate(
                  lot.uniqueId,
                  "lot",
                  "visite",
                  lot.visiteDate
                )
              }
            >
              <div className="project-line-step-name">VISITE</div>
              <div className="project-line-step-date">
                {lot.visiteDate
                  ? moment(lot.visiteDate).format("DD/MM/YYYY")
                  : "--"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={2} className="project-card-title-line">
            <div
              className={
                lot.offreDate
                  ? `project-line-step step-done-offre`
                  : `project-line-step`
              }
              onClick={() =>
                toggleModalSetDate(lot.uniqueId, "lot", "offre", lot.offreDate)
              }
            >
              <div className="project-line-step-name">OFFRE</div>
              <div className="project-line-step-date">
                {lot.offreDate
                  ? moment(lot.offreDate).format("DD/MM/YYYY")
                  : "--"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={2} className="project-card-title-line">
            <div
              className={
                lot.compromisDate
                  ? `project-line-step step-done-compromis`
                  : `project-line-step`
              }
              onClick={() =>
                toggleModalSetDate(
                  lot.uniqueId,
                  "lot",
                  "compromis",
                  lot.compromisDate
                )
              }
            >
              <div className="project-line-step-name">COMPROMIS</div>
              <div className="project-line-step-date">
                {lot.compromisDate
                  ? moment(lot.compromisDate).format("DD/MM/YYYY")
                  : "--"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={2} className="project-card-title-line">
            <div
              className={
                lot.acteDefinitifDate
                  ? `project-line-step step-done-acte-definitif`
                  : `project-line-step`
              }
              onClick={() =>
                toggleModalSetDate(
                  lot.uniqueId,
                  "lot",
                  "acte-definitif",
                  lot.acteDefinitifDate
                )
              }
            >
              <div className="project-line-step-name">ACTE DEF.</div>
              <div className="project-line-step-date">
                {lot.acteDefinitifDate
                  ? moment(lot.acteDefinitifDate).format("DD/MM/YYYY")
                  : "--"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={1} className="project-card-title-line">
            <label>
              <Toggle
                defaultChecked={lot.lotVendu}
                onChange={() => handleLotVendu(lot.uniqueId)}
              />
              <span>vendu</span>
            </label>
          </Col>
        </Row>
      );
    }
  );

  return (
    <div className="project-card" style={{ display }}>
      <Modal isOpen={modalSetDate}>
        <ModalHeader toggle={toggleModalSetDate}>
          Saisir une date de {typeDate} pour{" "}
          {categoryDate === "achat" ? "l'achat" : "le lot"}{" "}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <DatePicker
              id="example-datepicker"
              locale={fr}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              selected={selectedDate}
              onChange={(date) => handleDateChange(date)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModalSetDate}>
            Annuler
          </Button>
          <Button color="primary" onClick={setStepDate}>
            Enregistrer
          </Button>
          {selectedDate && (
            <Button color="danger" onClick={removeStepDate}>
              Enlever
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Container fluid className="pl-0">
        <Row>
          <Col xs={12}>
            <span className="project-card-number">{props.i}</span>
            <span className="project-card-title">{projet.libelle}</span>
            <BsPen
              color="#3cb599"
              size={20}
              className="mr-10 pointer"
              onClick={modifierProjet}
            />
            <BsCloudDownload
              color="#3cb599"
              size={20}
              className="mr-10 pointer"
              onClick={exportProjet}
            />
            {/*<BsTrash
              color="red"
              size={20}
              className="mr-10 pointer"
              onClick={props.removeProjet}
          />*/}
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-20 project-lines">
        <Row className="pt-20">
          <Col xs={12} md={2} className="project-card-title-line">
            <div className="project-line-step-header-name">
              {props.projet.libelle}
            </div>
            <div className="project-line-step-header-price">
              {prettyEuro(coutProjet)}
            </div>
          </Col>
          <Col xs={12} md={2} className="project-card-title-line">
            <div
              className={
                projet.visiteDate
                  ? `project-line-step step-done-visite`
                  : `project-line-step`
              }
              onClick={() =>
                toggleModalSetDate(
                  projet.uniqueId,
                  "projet",
                  "visite",
                  projet.visiteDate
                )
              }
            >
              <div className="project-line-step-name">VISITE</div>
              <div className="project-line-step-date">
                {projet.visiteDate
                  ? moment(projet.visiteDate).format("DD/MM/YYYY")
                  : "--"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={2} className="project-card-title-line">
            <div
              className={
                projet.offreDate
                  ? `project-line-step step-done-offre`
                  : `project-line-step`
              }
              onClick={() =>
                toggleModalSetDate(
                  projet.uniqueId,
                  "projet",
                  "offre",
                  projet.offreDate
                )
              }
            >
              <div className="project-line-step-name">OFFRE</div>
              <div className="project-line-step-date">
                {projet.offreDate
                  ? moment(projet.offreDate).format("DD/MM/YYYY")
                  : "--"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={2} className="project-card-title-line">
            <div
              className={
                projet.compromisDate
                  ? `project-line-step step-done-compromis`
                  : `project-line-step`
              }
              onClick={() =>
                toggleModalSetDate(
                  projet.uniqueId,
                  "projet",
                  "compromis",
                  projet.compromisDate
                )
              }
            >
              <div className="project-line-step-name">COMPROMIS</div>
              <div className="project-line-step-date">
                {projet.compromisDate
                  ? moment(projet.compromisDate).format("DD/MM/YYYY")
                  : "--"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={2} className="project-card-title-line">
            <div
              className={
                projet.acteDefinitifDate !== null
                  ? `project-line-step step-done-acte-definitif`
                  : `project-line-step`
              }
              onClick={() =>
                toggleModalSetDate(
                  projet.uniqueId,
                  "projet",
                  "acte-definitif",
                  projet.acteDefinitifDate
                )
              }
            >
              <div className="project-line-step-name">ACTE DEF.</div>
              <div className="project-line-step-date">
                {projet.acteDefinitifDate
                  ? moment(projet.acteDefinitifDate).format("DD/MM/YYYY")
                  : "--"}
              </div>
            </div>
          </Col>
          <Col xs={12} md={1} className="project-card-title-line">
            <label>
              <Toggle
                defaultChecked={projet.achatRealise}
                onChange={() => handleProjetAchete(projet.uniqueId)}
              />
              <span>acheté</span>
            </label>
          </Col>
        </Row>
        {lots}
        <Row className="pt-10">
          <Col xs={12} md={2} className="project-card-marge">
            <div className="project-line-step-header-name">
              {prettyEuro(margeNette)}
            </div>
          </Col>
        </Row>
        <Row className="pt-20">
          <Col xs={12} md={3} className="project-card-title-line">
            &nbsp;
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default ProjectCard;
