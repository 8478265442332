import { refTauxTVAValue } from "../TauxTVA";
import {
  calculMontantFraisNotaire,
  calculEmolumentsNotaire,
} from "./calculFraisNotaire";

const refTypeTravaux = require("../data/TypeTravaux.json");
const refTypeBien = require("../data/TypeBien.json");

const tauxTVAPourCalculsTVATotal = 0.2;

export const calculHypotheque = (projet) => {
  let _hypotheque = 0;
  let _coutsFixes = calculSommeCoutsFixes(projet);
  if (projet.modeFinancementProjet) {
    _hypotheque = 0;
  } else {
    if (projet.typeFinancement) {
      //console.log("calculHypotheque - Financement Projet");
      let _travaux = calculMontantTravaux(projet.ProjetTravauxes);
      //console.log(_travaux);
      _hypotheque =
        ((projet.prixAchat +
          projet.fraisAgence +
          _coutsFixes +
          projet.fraisNotaire +
          _travaux -
          (projet.prixAchat +
            projet.fraisAgence +
            _coutsFixes +
            projet.fraisNotaire +
            _travaux) *
            (projet.pourcentageApport / 100)) /
          100) *
        projet.pourcentageHypotheque;
    } else {
      //console.log("calculHypotheque - Financement Achat");
      _hypotheque =
        ((projet.prixAchat +
          projet.fraisAgence +
          _coutsFixes /*+
          projet.fraisNotaire*/ -
          (projet.prixAchat + projet.fraisAgence + _coutsFixes) /*+
            projet.fraisNotaire*/ *
            (projet.pourcentageApport / 100)) /
          100) *
        projet.pourcentageHypotheque;
    }
  }
  //console.log(`calculHypotheque - ${_hypotheque}`);
  return _hypotheque;
};

export const calculChiffreAffaire = (projet) => {
  //console.log(projet);
  let _sommeVenteLots = 0;
  projet.ProjetLots.forEach((lot) => {
    _sommeVenteLots += parseInt(lot.prixVente, 10);
    //_sommeVenteLots += parseInt(lot.fraisAgence, 10);
    //console.log(lot.loyer === null);
    //console.log(lot.loyer === undefined);
    if (lot.loyer !== undefined && lot.loyer !== null) {
      _sommeVenteLots += parseInt(lot.loyer, 10);
    }
  });

  return _sommeVenteLots;
};

export const calculChiffreAffaireHaut = (projet) => {
  let _sommeVenteLots = 0;
  projet.ProjetLots.forEach((lot) => {
    _sommeVenteLots += parseInt(lot.prixVenteHaut, 10);
    if (lot.loyer !== undefined && lot.loyer !== null) {
      _sommeVenteLots += parseInt(lot.loyer, 10);
    }
  });

  return _sommeVenteLots;
};

export const calculChiffreAffaireMoyen = (projet) => {
  let _sommeVenteLots = 0;
  projet.ProjetLots.forEach((lot) => {
    _sommeVenteLots += parseInt(lot.prixVenteMoyen, 10);
    if (lot.loyer !== undefined && lot.loyer !== null) {
      _sommeVenteLots += parseInt(lot.loyer, 10);
    }
  });

  return _sommeVenteLots;
};

export const calculChiffreAffaireHFA = (projet) => {
  return calculChiffreAffaire(projet);
};

export const calculChiffreAffaireHFAHaut = (projet) => {
  return calculChiffreAffaireHaut(projet);
};

export const calculChiffreAffaireHFAMoyen = (projet) => {
  return calculChiffreAffaireMoyen(projet);
};

export const calculFraisAgenceVente = (projet) => {
  let _sommeFraisAgenceLots = 0;
  projet.ProjetLots.forEach((lot) => {
    if (lot.fraisAgence) {
      _sommeFraisAgenceLots += parseInt(lot.fraisAgence, 10);
    }
  });

  return _sommeFraisAgenceLots;
};

export const calculFraisAgenceVenteHaut = (projet) => {
  let _sommeFraisAgenceLots = 0;
  projet.ProjetLots.forEach((lot) => {
    if (lot.fraisAgence) {
      _sommeFraisAgenceLots += parseInt(lot.fraisAgenceHaut, 10);
    }
  });

  return _sommeFraisAgenceLots;
};

export const calculFraisAgenceVenteMoyen = (projet) => {
  let _sommeFraisAgenceLots = 0;
  projet.ProjetLots.forEach((lot) => {
    if (lot.fraisAgence) {
      _sommeFraisAgenceLots += parseInt(lot.fraisAgenceMoyen, 10);
    }
  });

  return _sommeFraisAgenceLots;
};

export const calculCommissionEngagement = (projet) => {
  let _commissionEngagement = 0;
  let _coutsFixes = calculSommeCoutsFixes(projet);
  if (projet.typeFinancement) {
    //console.log("Financement Projet");
    let _travaux = calculMontantTravaux(projet.ProjetTravauxes);
    _commissionEngagement =
      ((projet.prixAchat +
        projet.fraisAgence +
        _coutsFixes +
        projet.fraisNotaire +
        _travaux -
        (projet.prixAchat +
          projet.fraisAgence +
          _coutsFixes +
          projet.fraisNotaire +
          _travaux) *
          (projet.pourcentageApport / 100)) /
        100) *
      projet.pourcentageCommissionEngagement *
      (projet.dureeCommissionEngagement / 12);
  } else {
    //console.log("Financement Achat");
    _commissionEngagement =
      ((projet.prixAchat +
        projet.fraisAgence +
        _coutsFixes /*+
        projet.fraisNotaire*/ -
        (projet.prixAchat + projet.fraisAgence + _coutsFixes) /*+
          projet.fraisNotaire*/ *
          (projet.pourcentageApport / 100)) /
        100) *
      projet.pourcentageCommissionEngagement *
      (projet.dureeCommissionEngagement / 12);
  }
  //console.log(`_commissionEngagement : ${_commissionEngagement}`);
  return _commissionEngagement;
};

export const calculInteretsEmprunt = (projet) => {
  let _interetsEmprunt = 0;
  let _coutsFixes = calculSommeCoutsFixes(projet);
  //console.log(`----CF ${_coutsFixes}`);
  if (projet.modeFinancementProjet) {
    //console.log("Fonds propres");
    _interetsEmprunt = 0;
  } else {
    //console.log("Financenement");
    if (projet.typeFinancement) {
      //console.log("Financement Projet");
      let _travaux = calculMontantTravaux(projet.ProjetTravauxes);

      _interetsEmprunt =
        ((projet.prixAchat +
          projet.fraisAgence +
          projet.fraisNotaire +
          _coutsFixes +
          _travaux -
          (projet.prixAchat +
            projet.fraisAgence +
            projet.fraisNotaire +
            _coutsFixes +
            _travaux) *
            (projet.pourcentageApport / 100)) /
          100) *
        projet.pourcentageInteretsEmprunt *
        (projet.dureeInteretsEmprunt / 12);
    } else {
      console.log("Financement Achat");
      _interetsEmprunt =
        ((projet.prixAchat +
          projet.fraisAgence +
          _coutsFixes /* +
          projet.fraisNotaire*/ -
          (projet.prixAchat + projet.fraisAgence + _coutsFixes) /* +
            projet.fraisNotaire*/ *
            (projet.pourcentageApport / 100)) /
          100) *
        projet.pourcentageInteretsEmprunt *
        (projet.dureeInteretsEmprunt / 12);
    }
  }

  //console.log(`_interetsEmprunt : ${_interetsEmprunt}`);
  return _interetsEmprunt;
};

export const calculFinancementParticipatif = (projet) => {
  let _financementParticipatif = 0;
  if (projet.modeFinancementProjet) {
    return 0;
  } else {
    if (projet.financementParticipatif) {
      _financementParticipatif = projet.financementParticipatif;
    }

    return _financementParticipatif;
  }
};

export const calculMontantAFinancer = (projet) => {
  let _montantAFinancer = 0;
  const _coutsFixes = calculSommeCoutsFixes(projet);
  if (projet.modeFinancementProjet) {
    //Fonds propres
    //console.log("calculMontantAFinancer - Fonds propres");
    _montantAFinancer = 0;
  } else {
    //console.log("calculMontantAFinancer - Financement");
    let _financementParticipatif = calculFinancementParticipatif(projet);
    //Financement
    if (projet.typeFinancement) {
      // console.log("calculMontantAFinancer - Financement Projet");
      let _travaux = calculMontantTravaux(projet.ProjetTravauxes);
      // console.log(`**********************`);
      // console.log(`projet.prixAchat : ${projet.prixAchat}`);
      // console.log(`projet.fraisAgence : ${projet.fraisAgence}`);
      // console.log(`projet.fraisNotaire : ${projet.fraisNotaire}`);
      // console.log(`financementParticipatif : ${_financementParticipatif}`);
      // console.log(`expertise : ${projet.montantExpertise}`);
      // console.log(`couts fixes : ${_coutsFixes}`);
      // console.log(`travaux : ${_travaux}`);

      _montantAFinancer =
        projet.prixAchat +
        _coutsFixes +
        projet.fraisAgence +
        // projet.fraisNotaire +
        _travaux +
        projet.montantExpertise +
        _financementParticipatif -
        (projet.prixAchat +
          projet.fraisAgence +
          projet.fraisNotaire +
          _travaux) *
          (projet.pourcentageApport / 100);
      // console.log(`montantAFinancer : ${_montantAFinancer}`);
      // console.log(`**********************`);
    } else {
      // console.log("calculMontantAFinancer - Financement Achat du bien");
      _montantAFinancer =
        /*projet.prixAchat +
        projet.fraisAgence +
        _financementParticipatif -
        (projet.prixAchat + projet.fraisAgence) *
          (projet.pourcentageApport / 100);*/
        calculCoutProjet(projet) - calculApport(projet);
    }
  }

  return _montantAFinancer;
};

export const calculTotalDepenses = (projet) => {
  let _totalDepenses = 0;

  _totalDepenses += calculMontantTravaux(projet.ProjetTravauxes);
  _totalDepenses += calculSommeCoutsFixes(projet);
  _totalDepenses += calculFraisFinancenement(projet);
  // console.log(`!!!!!!!!!!!!!!!!!!!!!!!!`);
  // console.log(
  //   `calculMontantTravaux : ${calculMontantTravaux(projet.ProjetTravauxes)}`
  // );
  // console.log(`calculSommeCoutsFixes : ${calculSommeCoutsFixes(projet)}`);
  // console.log(`calculFraisFinancenement : ${calculFraisFinancenement(projet)}`);
  // console.log(`!!!!!!!!!!!!!!!!!!!!!!!!`);
  /*console.log(
    `${projet.uniqueId} : Travaux ${calculMontantTravaux(
      projet.ProjetTravauxes
    )}`
  );*/
  //console.log(`${projet.uniqueId} : CF ${calculSommeCoutsFixes(projet)}`);
  //console.log(`${projet.uniqueId} : FF ${calculFraisFinancenement(projet)}`);

  return _totalDepenses;
};

export const calculMontantTVAAVerser = (projet) => {
  let _totalMontantTVAAVerser = 0;

  return _totalMontantTVAAVerser;
};

export const calculFraisFinancenement = (projet) => {
  let _fraisFinancement = 0;

  if (projet.modeFinancementProjet) {
    return 0;
  }

  // console.log(`IE : ${calculInteretsEmprunt(projet)}`);
  // console.log(`CE : ${calculCommissionEngagement(projet)}`);
  // console.log(`FD : ${projet.fraisDossier}`);
  // console.log(`HY : ${calculHypotheque(projet)}`);

  _fraisFinancement += calculInteretsEmprunt(projet);
  _fraisFinancement += calculCommissionEngagement(projet);
  _fraisFinancement += projet.fraisDossier;
  _fraisFinancement += calculHypotheque(projet);

  // console.log(`FF : ${_fraisFinancement}`);

  return _fraisFinancement;
};

export const calculFraisFinancenementAvecMontantExpertise = (projet) => {
  let _fraisFinancement = 0;

  if (projet.modeFinancementProjet) {
    return 0;
  }

  // console.log(`IE : ${calculInteretsEmprunt(projet)}`);
  // console.log(`CE : ${calculCommissionEngagement(projet)}`);
  // console.log(`FD : ${projet.fraisDossier}`);
  // console.log(`HY : ${calculHypotheque(projet)}`);

  _fraisFinancement += calculInteretsEmprunt(projet);
  _fraisFinancement += calculCommissionEngagement(projet);
  _fraisFinancement += projet.fraisDossier;
  _fraisFinancement += projet.montantExpertise;
  _fraisFinancement += calculHypotheque(projet);

  // console.log(`FF : ${_fraisFinancement}`);

  return _fraisFinancement;
};

export const calculApport = (projet) => {
  let _apport = 0;
  let _travaux = calculMontantTravaux(projet.ProjetTravauxes);
  let _coutsFixes = calculSommeCoutsFixes(projet);
  //console.log(`Travaux : ${_travaux}`);
  if (projet.modeFinancementProjet === true) {
    //Fonds Propres
    //console.log("Fonds propres");
    _apport = calculCoutProjet(projet);
  } else {
    //Financement
    //console.log("Financement");

    if (projet.typeFinancement) {
      /* console.log("Financement Projet");
      console.log(`prixAchat : ${projet.prixAchat}`);
      console.log(`fraisAgence : ${projet.fraisAgence}`);
      console.log(`fraisNotaire : ${projet.fraisNotaire}`);
      console.log(`coutsFixes : ${_coutsFixes}`);
      console.log(`travaux : ${_travaux}`); */
      _apport =
        (projet.prixAchat +
          projet.fraisAgence +
          _coutsFixes +
          calculFraisNotaireAchat(
            projet.chargeVendeur === 0
              ? projet.prixAchat + projet.fraisAgence
              : projet.prixAchat,
            1,
            projet.codePostal
          ) +
          _travaux) *
        (projet.pourcentageApport / 100);
    } else {
      //console.log("Financement Achat du bien");
      _apport = calculCoutProjet(projet) * (projet.pourcentageApport / 100);
    }
  }

  // console.log(`Apport : ${_apport}`);

  return _apport;
};

export const calculFraisNotaireAchat = (montant) => {
  /* console.log(
    `calculMontantFraisNotaire  : ${calculMontantFraisNotaire(
      montant,
      0,
      "75000"
    )}`
  ); */
  const emoluments = calculEmolumentsNotaire(montant);
  const frais = parseInt(
    (emoluments[0] + emoluments[1] + emoluments[2] + emoluments[3]) * 1.2 +
      1360 +
      montant * 0.001 +
      montant * 0.0071498,
    0
  );

  return frais;
};

export const calculFraisAcquisition = (projet) => {
  let _fraisAcquisition = projet.prixAchat;
  //console.log(projet.prixAchat, projet.fraisNotaire, projet.fraisAgence);
  _fraisAcquisition += calculFraisNotaireAchat(
    projet.chargeVendeur === 0
      ? projet.prixAchat + projet.fraisAgence
      : projet.prixAchat,
    1,
    projet.codePostal
  );
  _fraisAcquisition += projet.fraisAgence;

  return _fraisAcquisition;
};

export const calculCoutProjet = (projet) => {
  let _coutProjet = 0;
  //console.log(projet.typeFinancement);
  if (projet.typeFinancement) {
    //console.log("calculCoutProjet - Financement Projet");
    // console.log(`++++++++++++++++++`);
    // console.log(`calculTotalDepenses : ${calculTotalDepenses(projet)}`);
    // console.log(`projet.prixAchat : ${projet.prixAchat}`);
    // console.log(`projet.fraisAgence : ${projet.fraisAgence}`);
    // console.log(`projet.fraisNotaire : ${projet.fraisNotaire}`);
    // console.log(
    //   `calculMontantTravaux: ${calculMontantTravaux(projet.ProjetTravauxes)}`
    // );
    // console.log(`calculSommeCoutsFixes : ${calculSommeCoutsFixes(projet)}`);

    let _financementParticipatif = calculFinancementParticipatif(projet);
    // console.log(`_financementParticipatif : ${_financementParticipatif}`);
    // console.log(`projet.montantExpertise : ${projet.montantExpertise}`);

    _coutProjet =
      projet.prixAchat +
      (projet.modeFinancementProjet ? 0 : _financementParticipatif) +
      (projet.modeFinancementProjet ? 0 : projet.montantExpertise) +
      projet.fraisAgence +
      calculFraisNotaireAchat(
        projet.chargeVendeur === 0
          ? projet.prixAchat + projet.fraisAgence
          : projet.prixAchat,
        1,
        projet.codePostal
      ) +
      calculTotalDepenses(projet);
    // console.log(`_coutProjet : ${_coutProjet}`);
    // console.log(`++++++++++++++++++`);
  } else {
    //console.log("calculCoutProjet - Financement Achat du bien");
    //console.log("ici");
    _coutProjet = projet.prixAchat + projet.fraisAgence; /*+
       calculFraisNotaireAchat(
        projet.chargeVendeur === 0
          ? projet.prixAchat + projet.fraisAgence
          : projet.prixAchat,
        1,
        projet.codePostal
      ); */
  }
  /*console.log(
    `Cout PRojet : ${_coutProjet} = ${projet.prixAchat} + ${
      projet.fraisAgence
    } + ${projet.fraisNotaire} + ${calculMontantTravaux(
      projet.ProjetTravauxes
    )} + ${calculSommeCoutsFixes(projet)}`
  );*/
  return _coutProjet;
};

export const calculMontantTravaux = (lignesTravaux) => {
  //console.log(`calculMontantTravaux debut`);
  //console.log(lignesTravaux);
  let _travaux = 0;
  Array.isArray(lignesTravaux) &&
    lignesTravaux.forEach((travail) => {
      /*  console.log(
        `${travail.prixHorsTaxe} - ${refTauxTVAValue[travail.tauxTVA]}`
      );
    */
      _travaux +=
        travail.prixHorsTaxe +
        parseInt(travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA], 10);
    });
  //console.log(_travaux);
  //console.log(`calculMontantTravaux fin`);
  return _travaux;
};

export const calculTVATravaux = (lignesTravaux) => {
  let _TVATravaux = 0;
  lignesTravaux.forEach((travail) => {
    _TVATravaux += parseInt(
      travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA],
      10
    );
  });

  return _TVATravaux;
};

export const calculMontantTVALot = (lot) => {
  let _TVALot = 0;

  _TVALot += parseInt(
    lot.prixVente * refTauxTVAValue[lot.tva ? lot.tva : 0],
    10
  );

  return _TVALot;
};

export const calculMontantDepenses = (projet) => {
  let _montantDepenses = 0;
  Array.isArray(projet.ProjetTravauxes) &&
    projet.ProjetTravauxes.forEach((montant) => {
      _montantDepenses +=
        montant.prixHorsTaxe +
        montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
    });

  return _montantDepenses;
};

export const calculMontantTotalDepenses = (projet) => {
  let _montantTotalDepenses = 0;
  _montantTotalDepenses += calculFraisAcquisition(projet);
  _montantTotalDepenses += calculMontantTravaux(projet.ProjetTravauxes);
  _montantTotalDepenses += calculSommeCoutsFixes(projet);
  _montantTotalDepenses += calculFraisFinancenementAvecMontantExpertise(projet);
  _montantTotalDepenses += calculFinancementParticipatif(projet);

  return _montantTotalDepenses;
};

export const calculMargeNette = (projet) => {
  let _sommeVenteLots = calculChiffreAffaire(projet);
  let _sommeLoyers = calculMontantLoyers(projet);
  let _fraisTotal = calculCoutProjet(projet);

  if (projet.typeFinancement) {
    return (
      _sommeVenteLots + _sommeLoyers - _fraisTotal - calculTVATotal(projet) /* +
          _fraisNotaire +
          _montantDepenses +
          projet.fraisAgence +
          projet.prixAchat)*/
    );
  } else {
    return (
      _sommeVenteLots +
      _sommeLoyers -
      _fraisTotal -
      parseInt(calculTotalDepenses(projet), 0) -
      parseInt(calculTVATotal(projet), 0) /* +
          _fraisNotaire +
          _montantDepenses +
          projet.fraisAgence +
          projet.prixAchat)*/
    );
  }
};

export const calculMargeNetteMoyen = (projet) => {
  let _sommeVenteLots = calculChiffreAffaireMoyen(projet);
  let _sommeLoyers = calculMontantLoyers(projet);
  let _fraisTotal = calculCoutProjet(projet);

  if (projet.typeFinancement) {
    return (
      _sommeVenteLots + _sommeLoyers - _fraisTotal - calculTVATotal(projet) /* +
          _fraisNotaire +
          _montantDepenses +
          projet.fraisAgence +
          projet.prixAchat)*/
    );
  } else {
    return (
      _sommeVenteLots +
      _sommeLoyers -
      _fraisTotal -
      calculTotalDepenses(projet) -
      calculTVATotal(projet) /* +
          _fraisNotaire +
          _montantDepenses +
          projet.fraisAgence +
          projet.prixAchat)*/
    );
  }
};

export const calculMargeNetteHaut = (projet) => {
  let _sommeVenteLots = calculChiffreAffaireHaut(projet);
  let _sommeLoyers = calculMontantLoyers(projet);
  let _fraisTotal = calculCoutProjet(projet);

  if (projet.typeFinancement) {
    return (
      _sommeVenteLots + _sommeLoyers - _fraisTotal - calculTVATotal(projet) /* +
          _fraisNotaire +
          _montantDepenses +
          projet.fraisAgence +
          projet.prixAchat)*/
    );
  } else {
    return (
      _sommeVenteLots +
      _sommeLoyers -
      _fraisTotal -
      calculTotalDepenses(projet) -
      calculTVATotal(projet) /* +
          _fraisNotaire +
          _montantDepenses +
          projet.fraisAgence +
          projet.prixAchat)*/
    );
  }
};

const sansVentilation = (a, b) => {
  if (a.sort().join() === b.sort().join()) {
    return true;
  }

  let r = true;

  b.forEach((lot) => {
    if (!a.includes(lot)) {
      r = false;
    }
  });

  return r;
};

const avecVentilation = (a, b) => {
  // a LotsTVA, b Lots Travail
  let iConcern = 0;
  b.forEach((lot) => {
    if (a.includes(lot)) {
      iConcern++;
    }
  });

  /* console.log(`iConcern : ${iConcern}`);
  //console.log(`a.length : ${a.length}`);
*/
  if (iConcern > 0) {
    return true;
  }

  return false;

  /* if (a.sort().join() != b.sort().join()) {
    return true;
  }*/
};

export const calculTVATotal = (p) => {
  //console.log(p);
  let _TVAAVerser = 0;
  //console.log(`>>>>>>>>>>>>>>>>calculTVATotalNew START`);
  let fraisAcquisition = calculFraisAcquisition(p);
  //console.log(`Frais d'acquisition : ${fraisAcquisition}`);
  const ventilations = {};
  const ventilationsAchat = {};
  const ventilationsPourcentage = {};

  const repartitionTravaux = {};
  const travauxLots = {};
  const montantTravaux = {};

  const labelLots = {};
  const allLots = {};

  const lotsNoTVA = [];

  p.ProjetLots.forEach((lot) => {
    labelLots[lot.uniqueId] = `Lot ${refTypeBien[lot.typeBien]} montant ${
      lot.prixVente
    }`;
    allLots[lot.uniqueId] = lot;
    ventilationsPourcentage[lot.uniqueId] = lot.ventilation / 100;
    if (lot.tva !== 2) {
      let v = lot.ventilation;
      ventilationsAchat[lot.uniqueId] = (v / 100) * fraisAcquisition;
      ventilations[lot.uniqueId] = {};
      let montant =
        (lot.prixVente / (1 + tauxTVAPourCalculsTVATotal)) *
        tauxTVAPourCalculsTVATotal;

      ventilations[lot.uniqueId]["TVA_INTEGRALE"] =
        lot.prixVente - lot.prixVente / 1.2;
      ventilations[lot.uniqueId]["TVA_MARGE"] =
        ((lot.prixVente - ventilationsAchat[lot.uniqueId]) / 1.2) * 0.2;

      /* if (currentTVA !== 9 && currentTVA !== lot.tva) {
        sameTVA = false;
      }*/

      switch (lot.tva) {
        case 0:
          _TVAAVerser += ventilations[lot.uniqueId]["TVA_INTEGRALE"];
          break;
        case 1:
          _TVAAVerser += ventilations[lot.uniqueId]["TVA_MARGE"];
          break;
        default:
          _TVAAVerser += 0;
      }
    }
    if (lot.tva === 2) {
      lotsNoTVA.push(lot.uniqueId);
      //console.log(`on supprime la repartition travaux de ${lot.uniqueId}`);
      delete repartitionTravaux[lot.uniqueId];
    }

    //currentTVA = lot.tva;
  });

  Array.isArray(p.ProjetTravauxes) &&
    p.ProjetTravauxes.forEach((travail) => {
      travauxLots[travail.uniqueId] = [];
      travail.ProjetTravauxLots.forEach((lot) => {
        if (
          !lotsNoTVA.includes(lot.projetLotUniqueId) ||
          travail.ProjetTravauxLots.length > 1
        ) {
          if (labelLots[lot.projetLotUniqueId]) {
            if (!repartitionTravaux[lot.projetLotUniqueId]) {
              repartitionTravaux[lot.projetLotUniqueId] = [];
            }

            travauxLots[travail.uniqueId].push(lot.projetLotUniqueId);

            montantTravaux[travail.uniqueId] = parseInt(
              travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA],
              10
            );

            repartitionTravaux[lot.projetLotUniqueId].push(travail.uniqueId);
          }
        }
      });
    });

  //console.log(`travauxLots`);
  //console.table(travauxLots);
  //console.log(`Lots`);
  //console.table(labelLots);
  //console.log(`Lots exonérés de TVA`);
  //console.table(lotsNoTVA);
  //console.log(`Ventilations`);
  //console.table(ventilations);
  //console.log(`Ventilations en pourcentage`);
  //console.table(ventilationsPourcentage);
  //console.log(`Ventilations en montant de l'achat`);
  //console.table(ventilationsAchat);
  //console.log(`Repartition travaux`);
  //console.table(repartitionTravaux);

  const travauxIterator = Object.keys(travauxLots);
  //console.log(travauxIterator);
  //console.log(lotsNoTVA);

  /*console.log(
    `>>>>>>>>>>>>>>>>calculTVATotalNew ${_TVAAVerser} Avant suppression travaux`
  );*/

  let _TVATravaux = 0;
  //console.log(travauxIterator);
  travauxIterator.forEach((t) => {
    //console.log(travauxLots[t]);
    let sameTVA = true;
    let exonere = 0;
    let currentTVA = 9;
    travauxLots[t].forEach((lot) => {
      if (allLots[lot].tva !== 2) {
        /*console.log(
          `Lot ${allLots[lot].uniqueId} : ${currentTVA} !== 9 && ${currentTVA} !== ${allLots[lot].tva}`
        );*/
        if (currentTVA !== 9 && currentTVA !== allLots[lot].tva) {
          sameTVA = false;
        }
      } else {
        exonere++;
      }
      currentTVA = allLots[lot].tva;
    });
    //console.log(`Exonere : ${exonere}`);
    if (exonere > 0 && travauxLots[t].length !== exonere) {
      sameTVA = false;
    }
    /* sameTVA === true
      ? console.log(`${t} : Tous les lots ont la meme gestion de TVA`)
      : console.log(
          `${t} : La gestion de la TVA n'est pas la meme pour tous les lots`
        ); */
    if (
      sameTVA === true &&
      exonere !== travauxLots[t].length &&
      travauxLots[t].length > 0
    ) {
      //console.log(`On enleve ${montantTravaux[t]}`);
      _TVATravaux += montantTravaux[t];
    } else {
      //console.log(`On ventile`);
      travauxLots[t].forEach((lot) => {
        if (labelLots[lot]) {
          //console.log(lot);
          if (!lotsNoTVA.includes(lot)) {
            _TVATravaux += montantTravaux[t] * ventilationsPourcentage[lot];
            //console.log(lot);
            //console.log(ventilationsPourcentage[lot]);
            //console.log(montantTravaux[t]);
            /*console.log(
              `Ventilation : ${
                montantTravaux[t] * ventilationsPourcentage[lot]
              }`
            );*/
          }
        }
      });
    }

    //console.log(`${t} : _TVATravaux ${_TVATravaux}`);
  });

  //console.log(`_TVATravaux : ${_TVATravaux}`);

  /*console.log(repartitionTravaux);
  //console.log(ventilations);
  //console.log(ventilationsPourcentage);
  //console.log(travauxIterator);
  //console.log(montantTravaux);*/
  /*console.log(
    `>>>>>>>>>>>>>>>>calculTVATotalNew ${_TVAAVerser - _TVATravaux} END`
  );*/

  return _TVAAVerser - _TVATravaux;
};

export const calculTVATotalLot = (p, l) => {
  //console.log(p);
  let _TVAAVerser = 0;
  console.log(`>>>>>>>>>>>>>>>>calculTVATotalLot START`);
  let fraisAcquisition = calculFraisAcquisition(p);
  console.log(`Frais d'acquisition : ${fraisAcquisition}`);
  const ventilations = {};
  const ventilationsAchat = {};
  const ventilationsPourcentage = {};

  const repartitionTravaux = {};
  const travauxLots = {};
  const montantTravaux = {};

  const labelLots = {};
  const allLots = {};

  const lotsNoTVA = [];

  p.ProjetLots.forEach((lot) => {
    if (lot.uniqueId === l) {
      labelLots[lot.uniqueId] = `Lot ${refTypeBien[lot.typeBien]} montant ${
        lot.prixVente
      }`;
      allLots[lot.uniqueId] = lot;
      ventilationsPourcentage[lot.uniqueId] = lot.ventilation / 100;
      if (lot.tva !== 2) {
        let v = lot.ventilation;
        ventilationsAchat[lot.uniqueId] = (v / 100) * fraisAcquisition;
        ventilations[lot.uniqueId] = {};
        let montant =
          (lot.prixVente / (1 + tauxTVAPourCalculsTVATotal)) *
          tauxTVAPourCalculsTVATotal;

        ventilations[lot.uniqueId]["TVA_INTEGRALE"] =
          lot.prixVente - lot.prixVente / 1.2;
        ventilations[lot.uniqueId]["TVA_MARGE"] =
          ((lot.prixVente - ventilationsAchat[lot.uniqueId]) / 1.2) * 0.2;

        /* if (currentTVA !== 9 && currentTVA !== lot.tva) {
          sameTVA = false;
        }*/

        switch (lot.tva) {
          case 0:
            _TVAAVerser += ventilations[lot.uniqueId]["TVA_INTEGRALE"];
            break;
          case 1:
            _TVAAVerser += ventilations[lot.uniqueId]["TVA_MARGE"];
            break;
          default:
            _TVAAVerser += 0;
        }
      }
      if (lot.tva === 2) {
        lotsNoTVA.push(lot.uniqueId);
        //console.log(`on supprime la repartition travaux de ${lot.uniqueId}`);
        delete repartitionTravaux[lot.uniqueId];
      }
    }

    //currentTVA = lot.tva;
  });

  Array.isArray(p.ProjetTravauxes) &&
    p.ProjetTravauxes.forEach((travail) => {
      travauxLots[travail.uniqueId] = [];
      travail.ProjetTravauxLots.forEach((lot) => {
        if (lot.uniqueId === l) {
          if (
            !lotsNoTVA.includes(lot.projetLotUniqueId) ||
            travail.ProjetTravauxLots.length > 1
          ) {
            if (labelLots[lot.projetLotUniqueId]) {
              if (!repartitionTravaux[lot.projetLotUniqueId]) {
                repartitionTravaux[lot.projetLotUniqueId] = [];
              }

              travauxLots[travail.uniqueId].push(lot.projetLotUniqueId);

              montantTravaux[travail.uniqueId] = parseInt(
                travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA],
                10
              );

              repartitionTravaux[lot.projetLotUniqueId].push(travail.uniqueId);
            }
          }
        }
      });
    });

  //console.log(`travauxLots`);
  //console.table(travauxLots);
  console.log(`Lots`);
  console.table(labelLots);
  console.log(`Lots exonérés de TVA`);
  console.table(lotsNoTVA);
  //console.log(`Ventilations`);
  //console.table(ventilations);
  //console.log(`Ventilations en pourcentage`);
  //console.table(ventilationsPourcentage);
  //console.log(`Ventilations en montant de l'achat`);
  //console.table(ventilationsAchat);
  //console.log(`Repartition travaux`);
  //console.table(repartitionTravaux);

  const travauxIterator = Object.keys(travauxLots);
  //console.log(travauxIterator);
  //console.log(lotsNoTVA);

  /*console.log(
    `>>>>>>>>>>>>>>>>calculTVATotalNew ${_TVAAVerser} Avant suppression travaux`
  );*/

  let _TVATravaux = 0;
  //console.log(travauxIterator);
  travauxIterator.forEach((t) => {
    //console.log(travauxLots[t]);
    let sameTVA = true;
    let exonere = 0;
    let currentTVA = 9;
    travauxLots[t].forEach((lot) => {
      if (allLots[lot].tva !== 2) {
        /*console.log(
          `Lot ${allLots[lot].uniqueId} : ${currentTVA} !== 9 && ${currentTVA} !== ${allLots[lot].tva}`
        );*/
        if (currentTVA !== 9 && currentTVA !== allLots[lot].tva) {
          sameTVA = false;
        }
      } else {
        exonere++;
      }
      currentTVA = allLots[lot].tva;
    });
    //console.log(`Exonere : ${exonere}`);
    if (exonere > 0 && travauxLots[t].length !== exonere) {
      sameTVA = false;
    }
    /* sameTVA === true
      ? console.log(`${t} : Tous les lots ont la meme gestion de TVA`)
      : console.log(
          `${t} : La gestion de la TVA n'est pas la meme pour tous les lots`
        ); */
    if (
      sameTVA === true &&
      exonere !== travauxLots[t].length &&
      travauxLots[t].length > 0
    ) {
      //console.log(`On enleve ${montantTravaux[t]}`);
      _TVATravaux += montantTravaux[t];
    } else {
      //console.log(`On ventile`);
      travauxLots[t].forEach((lot) => {
        if (labelLots[lot]) {
          //console.log(lot);
          if (!lotsNoTVA.includes(lot)) {
            _TVATravaux += montantTravaux[t] * ventilationsPourcentage[lot];
            //console.log(lot);
            //console.log(ventilationsPourcentage[lot]);
            //console.log(montantTravaux[t]);
            /*console.log(
              `Ventilation : ${
                montantTravaux[t] * ventilationsPourcentage[lot]
              }`
            );*/
          }
        }
      });
    }

    //console.log(`${t} : _TVATravaux ${_TVATravaux}`);
  });

  //console.log(`_TVATravaux : ${_TVATravaux}`);

  /*console.log(repartitionTravaux);
  //console.log(ventilations);
  //console.log(ventilationsPourcentage);
  //console.log(travauxIterator);
  //console.log(montantTravaux);*/
  /*console.log(
    `>>>>>>>>>>>>>>>>calculTVATotalNew ${_TVAAVerser - _TVATravaux} END`
  );*/

  return _TVAAVerser - _TVATravaux;
};

export const calculTVATotalOld2 = (p) => {
  //calculTVATotalNew(p);
  let _TVAAVerser = 0;
  //console.log(`calculTVATotalNew START`);
  const lotTVAIntegrale = [];
  const lotTVAMarge = [];
  const lotNoTVA = [];

  const ventilations = {};
  const ventilationsAchat = {};
  const baseCalculTVALot = {};

  const fraisAcquisition = calculFraisAcquisition(p);

  p.ProjetLots.forEach((lot) => {
    ventilations[lot.uniqueId] = lot.ventilation;
    ventilationsAchat[lot.uniqueId] =
      (lot.ventilation / 100) * fraisAcquisition;
    switch (lot.tva) {
      case 0:
        lotTVAIntegrale.push(lot.uniqueId);
        baseCalculTVALot[lot.uniqueId] =
          (lot.prixVente / (1 + tauxTVAPourCalculsTVATotal)) *
          tauxTVAPourCalculsTVATotal;
        break;
      case 1:
        lotTVAMarge.push(lot.uniqueId);
        baseCalculTVALot[lot.uniqueId] =
          ((lot.prixVente - ventilationsAchat[lot.uniqueId]) /
            (1 + tauxTVAPourCalculsTVATotal)) *
          tauxTVAPourCalculsTVATotal;
        break;
      default:
        baseCalculTVALot[lot.uniqueId] = 0;
        lotNoTVA.push(lot.uniqueId);
      //console.log(`Lot ${lot.uniqueId} exonéré de TVA`);
    }
  });

  //console.log(ventilationsAchat);
  //console.log(baseCalculTVALot);

  let _TVATravaux = 0;

  Array.isArray(p.ProjetTravauxes) &&
    p.ProjetTravauxes.forEach((travail) => {
      const lotsTravail = [];
      travail.ProjetTravauxLots.forEach((lot) => {
        lotsTravail.push(lot.projetLotUniqueId);
      });

      if (
        sansVentilation(lotTVAMarge, lotsTravail) ||
        sansVentilation(lotTVAIntegrale, lotsTravail)
      ) {
        //console.log(`tous les lots sont sur le meme type de TVA`);
        lotsTravail.forEach((lot) => {
          _TVATravaux +=
            //baseCalculTVALot[lot] -
            parseInt(
              travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA],
              10
            );
          //console.log(`_TVAAVerser(${lot}) : ${_TVAAVerser}`);
        });
      }
      if (sansVentilation(lotNoTVA, lotsTravail)) {
        //console.log(`tous les lots sont en No TVA`);
      }

      if (
        lotsTravail.length > 1 &&
        (avecVentilation(lotTVAMarge, lotsTravail) ||
          avecVentilation(lotTVAIntegrale, lotsTravail))
      ) {
        //console.log(`Ya mix de type de TVA`);
        lotsTravail.forEach((lot) => {
          /* console.log(
            `++ _TVAAVerser(${lot}) : ${
              parseInt(
                travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA],
                10
              ) *
              (ventilations[lot] / 100)
            }`
          );*/
          if (baseCalculTVALot[lot] > 0) {
            /*console.log(`baseCalculTVALot[lot] : ${baseCalculTVALot[lot]}`);
            //console.log(`ventilations[lot] : ${ventilations[lot]}`);
            //console.log(`_TVAAVerser(${lot}) : ${_TVAAVerser}`);
            //console.log(
              `++ _TVAAVerser(${lot}) : ${
                parseInt(
                  travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA],
                  10
                ) *
                (ventilations[lot] / 100)
              }`
            );*/
            _TVATravaux +=
              parseInt(
                travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA],
                10
              ) *
              (ventilations[lot] / 100);
          }
        });
      }
    });

  _TVAAVerser = 0;
  p.ProjetLots.forEach((lot) => {
    _TVAAVerser += baseCalculTVALot[lot.uniqueId];
  });
  /* console.log(baseCalculTVALot);
  //console.log(ventilations);
  //console.log(`_TVAAVerser : ${_TVAAVerser}`);
  //console.log(`_TVATravaux : ${_TVATravaux}`);*/

  _TVAAVerser -= _TVATravaux;

  // console.log(`_TVAAVerser : ${_TVAAVerser}`);

  //console.log(`calculTVATotalNew END`);

  return _TVAAVerser;
};

export const calculTVATotalOld = (p) => {
  //calculTVATotalNew(p);
  let _caPourTVA = 0;

  let _TVATotal = 0;

  let _coutsAcquisition = calculFraisAcquisition(p);
  //console.log(`_coutsAcquisition : ${_coutsAcquisition}`);

  const lotTVAIntegrale = [];
  const lotTVAMarge = [];
  const lotNoTVA = [];

  const ventilations = {};

  p.ProjetLots.forEach((lot) => {
    ventilations[lot.uniqueId] = lot.ventilation;
    switch (lot.tva) {
      case 0:
        //console.log(`Lot ${lot.uniqueId} TVA Intégrale`);
        _caPourTVA += lot.prixVente;
        lotTVAIntegrale.push(lot.uniqueId);
        break;
      case 1:
        lotTVAMarge.push(lot.uniqueId);
        _caPourTVA +=
          ((lot.prixVente - (_coutsAcquisition * lot.ventilation) / 100) /
            1.2) *
          0.2;
        break;
      default:
        lotNoTVA.push(lot.uniqueId);
      //console.log(`Lot ${lot.uniqueId} exonéré de TVA`);
    }
  });

  let _tvaADeduire = 0;

  //console.log(lotTVAIntegrale);

  Array.isArray(p.ProjetTravauxes) &&
    p.ProjetTravauxes.forEach((travail) => {
      //console.log(`************${refTypeTravaux[travail.typeTravaux]}`);
      const lotsTravail = [];
      travail.ProjetTravauxLots.forEach((lot) => {
        /* if (lotTVAIntegrale.includes(lot.projetLotUniqueId)) {
        _tvaADeduire += travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA];
        //console.log(`_tvaADeduire ${lot.projetLotUniqueId} : ${_tvaADeduire}`);
      }
      if (lotTVAMarge.includes(lot.uniqueId)) {
        //console.log(`TODO _tvaADeduire TVA sur Marge`);
      }*/
        lotsTravail.push(lot.projetLotUniqueId);
      });

      /* console.log(
        `${lotTVAIntegrale.sort().join()} VS ${lotsTravail.sort().join()}`
      ); */

      if (sansVentilation(lotTVAMarge, lotsTravail)) {
        // tous les lots sont en TVA marge
        /*console.log(
          `${
            refTypeTravaux[travail.typeTravaux]
          } - tous les lots sont en TVA Marge`
        );*/
        _tvaADeduire += travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA];
        //console.log(`_tvaADeduire : ${_tvaADeduire}`);
        _TVATotal += _caPourTVA - _tvaADeduire;
      }
      if (sansVentilation(lotNoTVA, lotsTravail)) {
        // tous les lots sont en No TVA
        /* console.log(
        `${
          refTypeTravaux[travail.typeTravaux]
        } - tous les lots sont en exoneration de TVA`
      ); */
        //_tvaADeduire += travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA];
      }
      if (sansVentilation(lotTVAIntegrale, lotsTravail)) {
        //console.log(`tous les lots sont en TVA intégrale`);
        /*console.log(
          `${
            refTypeTravaux[travail.typeTravaux]
          } - tous les lots sont en TVA Integrales`
        );*/
        _tvaADeduire += travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA];
        _TVATotal += _caPourTVA - _caPourTVA / 1.2 - _tvaADeduire;
        //console.log(`        _tvaADeduire : ${_tvaADeduire}`);
        //console.log(`        _TVATotal : ${_TVATotal}`);
      }

      if (lotsTravail.length > 1 && avecVentilation(lotTVAMarge, lotsTravail)) {
        /*console.log(`Ya pas tous les lots en TVA Marge`);
        //console.log(ventilations);
        //console.log(lotsTravail);
        //console.log(lotTVAMarge);*/

        let proRata = 0;
        lotTVAMarge.forEach((lotUniqueId) => {
          proRata += ventilations[lotUniqueId];
          /*console.log(
            (travail.prixHorsTaxe *
              refTauxTVAValue[travail.tauxTVA] *
              ventilations[lotUniqueId]) /
              100
          );*/
          _tvaADeduire +=
            (travail.prixHorsTaxe *
              refTauxTVAValue[travail.tauxTVA] *
              ventilations[lotUniqueId]) /
            100;
        });

        //console.log(`====_tvaADeduire : ${_tvaADeduire}`);
        _TVATotal = _caPourTVA - _tvaADeduire;
      }
      if (
        lotsTravail.length > 1 &&
        avecVentilation(lotTVAIntegrale, lotsTravail)
      ) {
        //console.log(`Ya pas tous les lots en TVA intégrale`);
        //console.log(ventilations);
        //console.log(lotsTravail);
        //console.log(lotTVAIntegrale);
        let proRata = 0;
        lotTVAIntegrale.forEach((lotUniqueId) => {
          proRata += ventilations[lotUniqueId];
          /*console.log(
            (travail.prixHorsTaxe *
              refTauxTVAValue[travail.tauxTVA] *
              ventilations[lotUniqueId]) /
              100
          );*/
          _tvaADeduire +=
            (travail.prixHorsTaxe *
              refTauxTVAValue[travail.tauxTVA] *
              ventilations[lotUniqueId]) /
            100;
        });

        // console.log(`proRata : ${proRata}`);
        // console.log(travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA]);
        /*_tvaADeduire +=
          travail.prixHorsTaxe *
          refTauxTVAValue[travail.tauxTVA] *
          (proRata / 100);*/

        _TVATotal = _caPourTVA - _caPourTVA / 1.2 - _tvaADeduire;
      }
    });

  //console.log(`_tvaADeduire : ${_tvaADeduire}`);

  //console.log(`_caPourTVA : ${_caPourTVA}`);
  //console.log(`_TVATotal : ${_TVATotal}`);

  return _TVATotal;
};

export const calculAFinancerPerso = (p) => {
  const _apport = calculApport(p);
  const _fraisFinancement = calculFraisFinancenement(p);
  const _travaux = calculMontantTravaux(p.ProjetTravauxes);
  console.log(p);
  // console.log(`Apport : ${_apport}`);
  // console.log(`FF : ${_fraisFinancement}`);
  // console.log(`TRX : ${_travaux}`);
  if (p.typeFinancement === false)
    return (
      _apport +
      _fraisFinancement +
      p.montantExpertise +
      p.financementParticipatif +
      _travaux +
      p.fraisNotaire
      //0
    );

  return (
    _apport + _fraisFinancement + p.montantExpertise + p.financementParticipatif
  );
};

export const calculMargeNetteChange = (p, newTaux) => {
  let currentTaux = calculTauxMargeNette(p);
  let currentMarge = calculMargeNette(p);

  return (newTaux * currentMarge) / currentTaux;
};

export const calculTauxMargeNetteChange = (p, newMarge) => {
  let currentTaux = calculTauxMargeNette(p);
  let currentMarge = calculMargeNette(p);

  return (newMarge * currentTaux) / currentMarge;
};

export const calculTauxMargeNette = (projet) => {
  let _tauxMargeNette = 0;
  //let _sommeVenteLots = calculChiffreAffaire(projet);
  //let _montantDepenses = calculMontantDepenses(projet);
  //console.log(`*******************************`);
  //console.log(_montantDepenses);
  //console.log(`*******************************`);
  _tauxMargeNette = (calculMargeNette(projet) / calculCoutProjet(projet)) * 100;

  return _tauxMargeNette;
};

export const calculTauxMargeNetteMoyen = (projet) => {
  let _tauxMargeNette = 0;
  //let _sommeVenteLots = calculChiffreAffaire(projet);
  //let _montantDepenses = calculMontantDepenses(projet);
  //console.log(`*******************************`);
  //console.log(_montantDepenses);
  //console.log(`*******************************`);
  _tauxMargeNette =
    (calculMargeNetteMoyen(projet) / calculCoutProjet(projet)) * 100;

  return _tauxMargeNette;
};

export const calculTauxMargeNetteHaut = (projet) => {
  let _tauxMargeNette = 0;
  //let _sommeVenteLots = calculChiffreAffaire(projet);
  //let _montantDepenses = calculMontantDepenses(projet);
  //console.log(`*******************************`);
  //console.log(_montantDepenses);
  //console.log(`*******************************`);
  _tauxMargeNette =
    (calculMargeNetteHaut(projet) / calculCoutProjet(projet)) * 100;

  return _tauxMargeNette;
};

export const calculSommeCoutsFixesProjet = (projet) => {
  let _sommeTTC = 0;
  //console.log(projet);
  Array.isArray(projet.ProjetCharges) &&
    projet.ProjetCharges.forEach((charge) => {
      if (charge.prixHorsTaxe && !isNaN(charge.tauxTVA))
        _sommeTTC +=
          charge.prixHorsTaxe * refTauxTVAValue[charge.tauxTVA] +
          charge.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTC}`);

  Array.isArray(projet.ProjetTaxeFoncieres) &&
    projet.ProjetTaxeFoncieres.forEach((ptf) => {
      if (ptf.prixHorsTaxe && !isNaN(ptf.tauxTVA)) {
        _sommeTTC +=
          ptf.prixHorsTaxe * refTauxTVAValue[ptf.tauxTVA] + ptf.prixHorsTaxe;
      }
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTC}`);

  Array.isArray(projet.ProjetAssurances) &&
    projet.ProjetAssurances.forEach((pa) => {
      if (pa.prixHorsTaxe && !isNaN(pa.tauxTVA))
        _sommeTTC +=
          pa.prixHorsTaxe * refTauxTVAValue[pa.tauxTVA] + pa.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTC}`);

  Array.isArray(projet.ProjetEnergies) &&
    projet.ProjetEnergies.forEach((pe) => {
      if (pe.prixHorsTaxe && !isNaN(pe.tauxTVA))
        _sommeTTC +=
          pe.prixHorsTaxe * refTauxTVAValue[pe.tauxTVA] + pe.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTC}`);

  Array.isArray(projet.ProjetDivers) &&
    projet.ProjetDivers.forEach((pd) => {
      if (pd.prixHorsTaxe && !isNaN(pd.tauxTVA))
        _sommeTTC +=
          pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA] + pd.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTC}`);

  return _sommeTTC;
};

export const calculSommeCoutsGestionProjetParLot = (projet, lot, tva) => {
  let _sommeHT = 0;
  let _sommeTVA = 0;

  //console.log(projet);
  Array.isArray(projet.ProjetCharges) &&
    projet.ProjetCharges.forEach((charge) => {
      let count = charge.ProjetChargesLots.length;
      charge.ProjetChargesLots.forEach((pcl) => {
        if (pcl.projetLotUniqueId === lot && charge.tauxTVA === tva) {
          if (charge.prixHorsTaxe && !isNaN(charge.tauxTVA)) {
            _sommeTVA +=
              (charge.prixHorsTaxe * refTauxTVAValue[charge.tauxTVA]) / count;
            _sommeHT += charge.prixHorsTaxe / count;
          }
        }
      });
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTC}`);

  Array.isArray(projet.ProjetTaxeFoncieres) &&
    projet.ProjetTaxeFoncieres.forEach((ptf) => {
      let count = ptf.ProjetTaxeFoncieresLots.length;
      ptf.ProjetTaxeFoncieresLots.forEach((ptfl) => {
        if (ptfl.projetLotUniqueId === lot && ptf.tauxTVA === tva) {
          if (ptf.prixHorsTaxe && !isNaN(ptf.tauxTVA)) {
            _sommeTVA +=
              (ptf.prixHorsTaxe * refTauxTVAValue[ptf.tauxTVA]) / count;
            _sommeHT += ptf.prixHorsTaxe / count;
          }
        }
      });
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTC}`);

  Array.isArray(projet.ProjetAssurances) &&
    projet.ProjetAssurances.forEach((pa) => {
      let count = pa.ProjetAssurancesLots.length;
      pa.ProjetAssurancesLots.forEach((pal) => {
        if (pal.projetLotUniqueId === lot && pa.tauxTVA === tva) {
          if (pa.prixHorsTaxe && !isNaN(pa.tauxTVA)) {
            _sommeTVA +=
              (pa.prixHorsTaxe * refTauxTVAValue[pa.tauxTVA]) / count;
            _sommeHT += pa.prixHorsTaxe / count;
          }
        }
      });
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTC}`);

  Array.isArray(projet.ProjetEnergies) &&
    projet.ProjetEnergies.forEach((pe) => {
      let count = pe.ProjetEnergiesLots.length;
      pe.ProjetEnergiesLots.forEach((pel) => {
        if (pel.projetLotUniqueId === lot && pe.tauxTVA === tva) {
          if (pe.prixHorsTaxe && !isNaN(pe.tauxTVA)) {
            _sommeTVA +=
              (pe.prixHorsTaxe * refTauxTVAValue[pe.tauxTVA]) / count;
            _sommeHT += pe.prixHorsTaxe / count;
          }
        }
      });
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTC}`);

  Array.isArray(projet.ProjetDivers) &&
    projet.ProjetDivers.forEach((pd) => {
      let count = pd.ProjetDiversLots.length;
      pd.ProjetDiversLots.forEach((pdl) => {
        if (pdl.projetLotUniqueId === lot && pd.tauxTVA === tva) {
          if (pd.prixHorsTaxe && !isNaN(pd.tauxTVA)) {
            _sommeTVA +=
              (pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA]) / count;
            _sommeHT += pd.prixHorsTaxe / count;
          }
        }
      });
    });

  /* console.log(
    `calculSommeCoutsGestionProjetParLot : ${lot} : ${_sommeTVA} / ${_sommeHT}`
  ); */

  return { _sommeHT, _sommeTVA };
};

export const calculTVACoutsFixesProjet = (projet) => {
  let _sommeTVA = 0;

  projet.ProjetCharges.forEach((charge) => {
    _sommeTVA += charge.prixHorsTaxe * refTauxTVAValue[charge.tauxTVA];
  });

  projet.ProjetTaxeFoncieres.forEach((ptf) => {
    _sommeTVA += ptf.prixHorsTaxe * refTauxTVAValue[ptf.tauxTVA];
  });

  projet.ProjetDiagnostiques.forEach((pd) => {
    _sommeTVA += pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA];
  });

  projet.ProjetAssurances.forEach((pa) => {
    _sommeTVA += pa.prixHorsTaxe * refTauxTVAValue[pa.tauxTVA];
  });

  projet.ProjetEnergies.forEach((pe) => {
    _sommeTVA += pe.prixHorsTaxe * refTauxTVAValue[pe.tauxTVA];
  });

  projet.ProjetDivers.forEach((pd) => {
    _sommeTVA += pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA];
  });

  return _sommeTVA;
};

export const calculSommeFraisVente = (projet) => {
  let _sommeTTCFV = 0;

  Array.isArray(projet.ProjetDiagnostiques) &&
    projet.ProjetDiagnostiques.forEach((pd) => {
      _sommeTTCFV +=
        pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA] + pd.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetPreEtatDates) &&
    projet.ProjetPreEtatDates.forEach((pped) => {
      _sommeTTCFV +=
        pped.prixHorsTaxe * refTauxTVAValue[pped.tauxTVA] + pped.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetEtatDates) &&
    projet.ProjetEtatDates.forEach((ped) => {
      _sommeTTCFV +=
        ped.prixHorsTaxe * refTauxTVAValue[ped.tauxTVA] + ped.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetCommissions) &&
    projet.ProjetCommissions.forEach((pc) => {
      _sommeTTCFV +=
        pc.prixHorsTaxe * refTauxTVAValue[pc.tauxTVA] + pc.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetFraisVenteDivers) &&
    projet.ProjetFraisVenteDivers.forEach((pfvd) => {
      _sommeTTCFV +=
        pfvd.prixHorsTaxe * refTauxTVAValue[pfvd.tauxTVA] + pfvd.prixHorsTaxe;
    });

  return _sommeTTCFV;
};

export const calculSommeFraisVenteParLot = (projet, lot, tva) => {
  let _sommeHT = 0;
  let _sommeTVA = 0;

  Array.isArray(projet.ProjetDiagnostiques) &&
    projet.ProjetDiagnostiques.forEach((pd) => {
      let count = pd.ProjetDiagnostiquesLots.length;
      pd.ProjetDiagnostiquesLots.forEach((pdl) => {
        if (pdl.projetLotUniqueId === lot && pd.tauxTVA === tva) {
          _sommeHT += pd.prixHorsTaxe / count;
          _sommeTVA += (pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA]) / count;
        }
      });
    });

  Array.isArray(projet.ProjetPreEtatDates) &&
    projet.ProjetPreEtatDates.forEach((pped) => {
      let count = Array.isArray(pped.ProjetPreEtatDatesLots)
        ? pped.ProjetPreEtatDatesLots.length
        : 0;
      pped.ProjetPreEtatDatesLots.forEach((ppedl) => {
        if (ppedl.projetLotUniqueId === lot && pped.tauxTVA === tva) {
          _sommeHT += pped.prixHorsTaxe / count;
          _sommeTVA +=
            (pped.prixHorsTaxe * refTauxTVAValue[pped.tauxTVA]) / count;
        }
      });
    });

  Array.isArray(projet.ProjetEtatDates) &&
    projet.ProjetEtatDates.forEach((ped) => {
      let count = Array.isArray(ped.ProjetEtatDatesLots)
        ? ped.ProjetEtatDatesLots.length
        : 0;
      ped.ProjetEtatDatesLots.forEach((pedl) => {
        if (pedl.projetLotUniqueId === lot && ped.tauxTVA === tva) {
          console.log(ped.prixHorsTaxe);
          console.log(count);
          _sommeHT += ped.prixHorsTaxe / count;
          _sommeTVA +=
            (ped.prixHorsTaxe * refTauxTVAValue[ped.tauxTVA]) / count;
        }
      });
    });

  //console.log(_sommeHT);

  Array.isArray(projet.ProjetCommissions) &&
    projet.ProjetCommissions.forEach((pc) => {
      let count = pc.ProjetCommissionsLots.length;
      pc.ProjetCommissionsLots.forEach((pcl) => {
        if (pcl.projetLotUniqueId === lot && pc.tauxTVA === tva) {
          _sommeHT += pc.prixHorsTaxe / count;
          _sommeTVA += (pc.prixHorsTaxe * refTauxTVAValue[pc.tauxTVA]) / count;
        }
      });
    });

  Array.isArray(projet.ProjetFraisVenteDivers) &&
    projet.ProjetFraisVenteDivers.forEach((pfvd) => {
      let count = pfvd.ProjetFraisVenteDiversLots.length;
      pfvd.ProjetFraisVenteDiversLots.forEach((pfvdl) => {
        if (pfvdl.projetLotUniq === lot && pfvd.tauxTVA === tva) {
          _sommeHT += pfvd.prixHorsTaxe / count;
          _sommeTVA +=
            (pfvd.prixHorsTaxe * refTauxTVAValue[pfvd.tauxTVA]) / count;
        }
      });
    });

  /* console.log(
    ` calculSommeFraisVenteParLot ${lot} : ${_sommeTVA} / ${_sommeHT}`
  ); */

  return { _sommeHT, _sommeTVA };
};

export const calculSommeTravauxHT = (projet) => {
  let _somme = 0;

  projet.ProjetTravauxes.map((pt) => {
    _somme += pt.prixHorsTaxe;
  });

  return _somme;
};

export const calculSommeTravauxTTC = (projet) => {
  let _somme = 0;

  projet.ProjetTravauxes.map((pt) => {
    //console.log(pt);
    _somme += pt.prixHorsTaxe + pt.prixHorsTaxe * refTauxTVAValue[pt.tauxTVA];
    //console.log(`${pt.prixHorsTaxe} * ${refTauxTVAValue[pt.tauxTVA]}`);
  });

  return _somme;
};

export const calculSommeTVATravauxParTVA = (projet) => {
  const _sommeTVATravauxParTVA = {};

  refTauxTVAValue.forEach((tx) => {
    _sommeTVATravauxParTVA[tx] = 0;
  });

  projet.ProjetTravauxes.map((pt) => {
    if (!_sommeTVATravauxParTVA[refTauxTVAValue[pt.tauxTVA]]) {
      _sommeTVATravauxParTVA[refTauxTVAValue[pt.tauxTVA]] = 0;
    }

    _sommeTVATravauxParTVA[refTauxTVAValue[pt.tauxTVA]] +=
      pt.prixHorsTaxe * refTauxTVAValue[pt.tauxTVA];
  });

  return _sommeTVATravauxParTVA;
};

export const calculSommeCoutsFixesParTVA = (projet) => {
  let _somme = {};

  refTauxTVAValue.forEach((tx) => {
    _somme[tx] = 0;
  });

  Array.isArray(projet.ProjetDiagnostiques) &&
    projet.ProjetDiagnostiques.forEach((pd) => {
      _somme[refTauxTVAValue[pd.tauxTVA]] +=
        pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA];
    });

  Array.isArray(projet.ProjetPreEtatDates) &&
    projet.ProjetPreEtatDates.forEach((pped) => {
      _somme[refTauxTVAValue[pped.tauxTVA]] +=
        pped.prixHorsTaxe * refTauxTVAValue[pped.tauxTVA];
    });

  Array.isArray(projet.ProjetEtatDates) &&
    projet.ProjetEtatDates.forEach((ped) => {
      _somme[refTauxTVAValue[ped.tauxTVA]] +=
        ped.prixHorsTaxe * refTauxTVAValue[ped.tauxTVA];
    });

  Array.isArray(projet.ProjetCommissions) &&
    projet.ProjetCommissions.forEach((pc) => {
      _somme[refTauxTVAValue[pc.tauxTVA]] +=
        pc.prixHorsTaxe * refTauxTVAValue[pc.tauxTVA];
    });

  Array.isArray(projet.ProjetFraisVenteDivers) &&
    projet.ProjetFraisVenteDivers.forEach((pfvd) => {
      _somme[refTauxTVAValue[pfvd.tauxTVA]] +=
        pfvd.prixHorsTaxe * refTauxTVAValue[pfvd.tauxTVA];
    });

  //console.log(projet);
  Array.isArray(projet.ProjetCharges) &&
    projet.ProjetCharges.forEach((charge) => {
      if (charge.prixHorsTaxe && !isNaN(charge.tauxTVA))
        _somme[refTauxTVAValue[charge.tauxTVA]] +=
          charge.prixHorsTaxe * refTauxTVAValue[charge.tauxTVA];
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTCCF}`);

  Array.isArray(projet.ProjetTaxeFoncieres) &&
    projet.ProjetTaxeFoncieres.forEach((ptf) => {
      if (ptf.prixHorsTaxe && !isNaN(ptf.tauxTVA)) {
        _somme[refTauxTVAValue[ptf.tauxTVA]] +=
          ptf.prixHorsTaxe * refTauxTVAValue[ptf.tauxTVA];
      }
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTCCF}`);

  Array.isArray(projet.ProjetAssurances) &&
    projet.ProjetAssurances.forEach((pa) => {
      if (pa.prixHorsTaxe && !isNaN(pa.tauxTVA))
        _somme[refTauxTVAValue[pa.tauxTVA]] +=
          pa.prixHorsTaxe * refTauxTVAValue[pa.tauxTVA];
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTCCF}`);

  Array.isArray(projet.ProjetEnergies) &&
    projet.ProjetEnergies.forEach((pe) => {
      if (pe.prixHorsTaxe && !isNaN(pe.tauxTVA))
        _somme[refTauxTVAValue[pe.tauxTVA]] +=
          pe.prixHorsTaxe * refTauxTVAValue[pe.tauxTVA];
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTCCF}`);

  Array.isArray(projet.ProjetDivers) &&
    projet.ProjetDivers.forEach((pd) => {
      if (pd.prixHorsTaxe && !isNaN(pd.tauxTVA))
        _somme[refTauxTVAValue[pd.tauxTVA]] +=
          pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA];
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTCCF}`);

  return _somme;
};

export const calculSommeCoutsFixesTTC = (projet) => {
  let _sommeTTCFV = 0;

  Array.isArray(projet.ProjetDiagnostiques) &&
    projet.ProjetDiagnostiques.forEach((pd) => {
      _sommeTTCFV +=
        pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA] + pd.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetPreEtatDates) &&
    projet.ProjetPreEtatDates.forEach((pped) => {
      _sommeTTCFV +=
        pped.prixHorsTaxe * refTauxTVAValue[pped.tauxTVA] + pped.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetEtatDates) &&
    projet.ProjetEtatDates.forEach((ped) => {
      _sommeTTCFV +=
        ped.prixHorsTaxe * refTauxTVAValue[ped.tauxTVA] + ped.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetCommissions) &&
    projet.ProjetCommissions.forEach((pc) => {
      _sommeTTCFV +=
        pc.prixHorsTaxe * refTauxTVAValue[pc.tauxTVA] + pc.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetFraisVenteDivers) &&
    projet.ProjetFraisVenteDivers.forEach((pfvd) => {
      _sommeTTCFV +=
        pfvd.prixHorsTaxe * refTauxTVAValue[pfvd.tauxTVA] + pfvd.prixHorsTaxe;
    });

  //return _sommeTTCFV;

  let _sommeTTCCF = 0;
  //console.log(projet);
  Array.isArray(projet.ProjetCharges) &&
    projet.ProjetCharges.forEach((charge) => {
      if (charge.prixHorsTaxe && !isNaN(charge.tauxTVA))
        _sommeTTCCF +=
          charge.prixHorsTaxe * refTauxTVAValue[charge.tauxTVA] +
          charge.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTCCF}`);

  Array.isArray(projet.ProjetTaxeFoncieres) &&
    projet.ProjetTaxeFoncieres.forEach((ptf) => {
      if (ptf.prixHorsTaxe && !isNaN(ptf.tauxTVA)) {
        _sommeTTCCF +=
          ptf.prixHorsTaxe * refTauxTVAValue[ptf.tauxTVA] + ptf.prixHorsTaxe;
      }
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTCCF}`);

  Array.isArray(projet.ProjetAssurances) &&
    projet.ProjetAssurances.forEach((pa) => {
      if (pa.prixHorsTaxe && !isNaN(pa.tauxTVA))
        _sommeTTCCF +=
          pa.prixHorsTaxe * refTauxTVAValue[pa.tauxTVA] + pa.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTCCF}`);

  Array.isArray(projet.ProjetEnergies) &&
    projet.ProjetEnergies.forEach((pe) => {
      if (pe.prixHorsTaxe && !isNaN(pe.tauxTVA))
        _sommeTTCCF +=
          pe.prixHorsTaxe * refTauxTVAValue[pe.tauxTVA] + pe.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTCCF}`);

  Array.isArray(projet.ProjetDivers) &&
    projet.ProjetDivers.forEach((pd) => {
      if (pd.prixHorsTaxe && !isNaN(pd.tauxTVA))
        _sommeTTCCF +=
          pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA] + pd.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeTTCCF}`);

  return _sommeTTCCF + _sommeTTCFV;
};

export const calculSommeCoutsFixesHT = (projet) => {
  let _sommeHTFV = 0;

  Array.isArray(projet.ProjetDiagnostiques) &&
    projet.ProjetDiagnostiques.forEach((pd) => {
      _sommeHTFV += pd.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetPreEtatDates) &&
    projet.ProjetPreEtatDates.forEach((pped) => {
      _sommeHTFV += pped.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetEtatDates) &&
    projet.ProjetEtatDates.forEach((ped) => {
      _sommeHTFV += ped.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetCommissions) &&
    projet.ProjetCommissions.forEach((pc) => {
      _sommeHTFV += pc.prixHorsTaxe;
    });

  Array.isArray(projet.ProjetFraisVenteDivers) &&
    projet.ProjetFraisVenteDivers.forEach((pfvd) => {
      _sommeHTFV += pfvd.prixHorsTaxe;
    });

  let _sommeHTCF = 0;
  //console.log(projet);
  Array.isArray(projet.ProjetCharges) &&
    projet.ProjetCharges.forEach((charge) => {
      if (charge.prixHorsTaxe && !isNaN(charge.tauxTVA))
        _sommeHTCF += charge.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeHTCF}`);

  Array.isArray(projet.ProjetTaxeFoncieres) &&
    projet.ProjetTaxeFoncieres.forEach((ptf) => {
      if (ptf.prixHorsTaxe && !isNaN(ptf.tauxTVA)) {
        _sommeHTCF += ptf.prixHorsTaxe;
      }
    });

  //console.log(`${projet.uniqueId} : ${_sommeHTCF}`);

  Array.isArray(projet.ProjetAssurances) &&
    projet.ProjetAssurances.forEach((pa) => {
      if (pa.prixHorsTaxe && !isNaN(pa.tauxTVA)) _sommeHTCF += pa.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeHTCF}`);

  Array.isArray(projet.ProjetEnergies) &&
    projet.ProjetEnergies.forEach((pe) => {
      if (pe.prixHorsTaxe && !isNaN(pe.tauxTVA)) _sommeHTCF += pe.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeHTCF}`);

  Array.isArray(projet.ProjetDivers) &&
    projet.ProjetDivers.forEach((pd) => {
      if (pd.prixHorsTaxe && !isNaN(pd.tauxTVA)) _sommeHTCF += pd.prixHorsTaxe;
    });

  //console.log(`${projet.uniqueId} : ${_sommeHTCF}`);

  return _sommeHTCF + _sommeHTFV;
};

export const calculSommeFraisVenteAll = (projet) => {
  let _sommeHTFV = 0;
  let _sommeTVAFV = 0;

  Array.isArray(projet.ProjetDiagnostiques) &&
    projet.ProjetDiagnostiques.forEach((pd) => {
      let count = pd.ProjetDiagnostiquesLots.length;
      pd.ProjetDiagnostiquesLots.forEach((pdl) => {
        _sommeHTFV += pd.prixHorsTaxe / count;
        _sommeTVAFV += (pd.prixHorsTaxe * refTauxTVAValue[pd.tauxTVA]) / count;
      });
    });

  Array.isArray(projet.ProjetPreEtatDates) &&
    projet.ProjetPreEtatDates.forEach((pped) => {
      let count = pped.ProjetPreEtatDatesLots.length;
      pped.ProjetPreEtatDatesLots.forEach((ppedl) => {
        _sommeHTFV += pped.prixHorsTaxe / count;
        _sommeTVAFV +=
          (pped.prixHorsTaxe * refTauxTVAValue[pped.tauxTVA]) / count;
      });
    });

  Array.isArray(projet.ProjetEtatDates) &&
    projet.ProjetEtatDates.forEach((ped) => {
      let count = ped.ProjetPreEtatDatesLots.length;
      ped.ProjetEtatDatesLots.forEach((pedl) => {
        _sommeHTFV += ped.prixHorsTaxe / count;
        _sommeTVAFV +=
          (ped.prixHorsTaxe * refTauxTVAValue[ped.tauxTVA]) / count;
      });
    });

  Array.isArray(projet.ProjetCommissions) &&
    projet.ProjetCommissions.forEach((pc) => {
      let count = pc.ProjetCommissionsLots.length;
      pc.ProjetCommissionsLots.forEach((pcl) => {
        _sommeHTFV += pc.prixHorsTaxe / count;
        _sommeTVAFV += (pc.prixHorsTaxe * refTauxTVAValue[pc.tauxTVA]) / count;
      });
    });

  Array.isArray(projet.ProjetFraisVenteDivers) &&
    projet.ProjetFraisVenteDivers.forEach((pfvd) => {
      let count = pfvd.ProjetFraisVenteDiversLots.length;
      pfvd.ProjetFraisVenteDiversLots.forEach((pfvdl) => {
        _sommeHTFV += pfvd.prixHorsTaxe / count;
        _sommeTVAFV +=
          (pfvd.prixHorsTaxe * refTauxTVAValue[pfvd.tauxTVA]) / count;
      });
    });

  //console.log(_sommeHTFV);
  console.log(`${_sommeTVAFV}`);
  console.log(`${_sommeHTFV}`);

  return { _sommeHTFV, _sommeTVAFV };
};

export const calculTVAFraisVente = (projet) => {
  let _sommeTVAFV = 0;
  projet.ProjetLots.forEach((lot) => {
    lot.ProjetLotFraisVentes.forEach((fv) => {
      _sommeTVAFV += fv.prixHorsTaxe * refTauxTVAValue[fv.tauxTVA];
    });
  });

  return _sommeTVAFV;
};

export const calculMontantLoyers = (projet) => {
  let _sommeLoyers = 0;
  projet.ProjetLots.forEach((lot) => {
    _sommeLoyers += lot.loyer;
  });

  return _sommeLoyers;
};

export const calculCoutTotalFraisDepenses = (projet) => {
  let _coutTotalFraisDepenses = 0;
  const _sommeTravaux = calculMontantTravaux(projet.ProjetTravauxes);
  const _sommeCF = calculSommeCoutsFixes(projet);
  const _sommeFF = calculFraisFinancenementAvecMontantExpertise(projet);
  const _sommeFP = calculFinancementParticipatif(projet);

  if (!isNaN(_sommeTravaux)) {
    //console.log(`_sommeTravaux : ${_sommeTravaux}`);
    _coutTotalFraisDepenses += _sommeTravaux;
  }
  if (!isNaN(_sommeCF)) {
    //console.log(`_sommeCF : ${_sommeCF}`);
    _coutTotalFraisDepenses += _sommeCF;
  }
  if (!isNaN(_sommeFF)) {
    //console.log(`_sommeFF : ${_sommeFF}`);
    _coutTotalFraisDepenses += _sommeFF;
  }
  if (!isNaN(_sommeFP)) {
    //console.log(`_sommeFP : ${_sommeFP}`);
    _coutTotalFraisDepenses += _sommeFP;
  }

  return _coutTotalFraisDepenses;
};

export const calculSommeCoutsFixes = (projet) => {
  return calculSommeFraisVente(projet) + calculSommeCoutsFixesProjet(projet);
};

export const calculSommeCoutsFixesParLot = (projet, lot, tx) => {
  //console.log(`TAUX : ${tx}`);
  let FVParLot = calculSommeFraisVenteParLot(projet, lot, tx);
  //console.log(FVParLot);
  let CGParLot = calculSommeCoutsGestionProjetParLot(projet, lot, tx);
  //console.log(CGParLot);

  return {
    _sommeHT: CGParLot._sommeHT + FVParLot._sommeHT,
    _sommeTVA: FVParLot._sommeTVA + CGParLot._sommeTVA,
  };
};

export const calculTVACoutsFixes = (projet) => {
  return calculTVAFraisVente(projet) + calculTVACoutsFixesProjet(projet);
};
