import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Badge,
} from "reactstrap";
import { useNavigate, Navigate } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { ToastContainer, toast } from "react-toastify";
import { BsPlus } from "react-icons/bs";

import Navbar from "./layout/Navbar";

import {
  getData,
  postData,
  toastConfig,
  prettyEuro,
  deleteData,
} from "../utils";

import "./DashboardPage.css";
import ProjectCard from "./Components/ProjectCard";
import { calculMargeNette } from "./Components/helpers/calculsProjet";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const navigate = useNavigate();
  const [projets, setProjets] = React.useState([]);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [sommeCa, setSommeCa] = React.useState(0);
  const [sommeMarge, setSommeMarge] = React.useState(0);
  const [caValues, setCaValues] = React.useState([]);
  const [margeValues, setMargeValues] = React.useState([]);
  const [caLabels, setCaLabels] = React.useState([]);
  const [margeLabels, setMargeLabels] = React.useState([]);
  const [totalLotsVendus, setTotalLotsVendus] = React.useState(0);
  const [totalLotsAchetes, setTotalLotsAchetes] = React.useState(0);
  const [achatsEnCours, setAchatsEnCours] = React.useState(0);
  const [ventesEnCours, setVentesEnCours] = React.useState(0);
  const [prenom, setPrenom] = React.useState("");
  const [finDeCycle, setFinDeCycle] = React.useState("");
  const [toProjet, setToProjet] = React.useState(false);
  const [newProjet, setNewProjet] = React.useState("");
  const [modalUtilisableFinDeCycle, setModalUtilisableFinDeCycle] =
    React.useState(false);
  const [noSubscription, setNoSubscription] = React.useState(false);
  const [prix, setPrix] = useState([]);

  const refreshGraphs = async () => {
    try {
      const dashboard = await getData(`/v1/me/dashboard`);
      let caLabelsList = [];
      let caValuesList = [];

      let sommeTotal = 0;
      dashboard.ca.forEach((ca) => {
        caLabelsList.push(ca.period);
        caValuesList.push(ca.sum);
        sommeTotal += ca.sum;
      });

      setSommeCa(sommeTotal);

      setCaLabels(caLabelsList);
      setCaValues(caValuesList);

      let margeLabelsList = [];
      let margeValuesList = [];

      let sommeTotalMarge = 0;
      for (let iProjet = 0; iProjet < dashboard.marge.length; iProjet++) {
        const pVendu = dashboard.marge[iProjet];
        const p = await getData(`/v1/projets/${pVendu.unique_id}`);
        const margeNette = calculMargeNette(p);
        //console.log(pVendu.unique_id);
        console.log(
          `[${pVendu.period}]${pVendu.unique_id} =======> ${margeNette}`
        );
        if (margeLabelsList.includes(pVendu.period)) {
          margeValuesList[margeValuesList.length - 1] += margeNette;
        } else {
          margeLabelsList.push(pVendu.period);
          margeValuesList.push(margeNette);
        }

        sommeTotalMarge += isNaN(margeNette) ? 0 : margeNette;
      }

      setSommeMarge(sommeTotalMarge);

      setMargeLabels(margeLabelsList);
      setMargeValues(margeValuesList);
    } catch (error) {
      console.log(error);
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  useEffect(() => {
    async function getUseEffectData() {
      try {
        const profiles = await getData("/v1/me/profiles");
        if (profiles.includes("ADMIN")) {
          setIsAdmin(true);
        }
        const ps = await getData(`/v1/me/projets`);
        setProjets(
          ps.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        );

        await refreshGraphs();

        await refreshIndicateurs();

        const abonnement = await getData(`/v1/me/abonnement`);
        if (abonnement.code === 402 && !profiles.includes("ADMIN")) {
          setNoSubscription(true);
          const pricesList = await getData(`/v1/me/prix`);
          setPrix(pricesList.data);
        }
        if (abonnement.code === 199 && !profiles.includes("ADMIN")) {
          console.log(abonnement);
          setFinDeCycle(abonnement.finDeCycle);
          setModalUtilisableFinDeCycle(true);
        }
      } catch (error) {
        console.log(error);
        console.log(JSON.parse(error).error?.status === 403);

        if (JSON.parse(error).error?.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error?.data?.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }

    getUseEffectData();
  }, [navigate]);

  if (toProjet === true) {
    return <Navigate to={`/projets/${newProjet}`} />;
  }

  const createNewProjet = async () => {
    try {
      const projet = await postData(`/v1/projets`, {
        libelle: `Nouveau Projet ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
        modeFinancementProjet: true,
        fraisDossier: 3000,
      });
      setNewProjet(projet.uniqueId);
      setToProjet(true);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  if (prenom !== localStorage.getItem("prenom")) {
    setPrenom(localStorage.getItem("prenom"));
  }

  const refreshIndicateurs = async () => {
    try {
      const ps = await getData(`/v1/me/projets`);

      let _totalLotsVendus = 0;
      let _totalLotsAchetes = 0;
      let _ventesEnCours = 0;
      ps.forEach((p) => {
        if (p.achatRealise === true) {
          _totalLotsAchetes += 1;
        }
        p.ProjetLots.forEach((lot) => {
          if (lot.lotVendu === true) {
            _totalLotsVendus += 1;
          } else {
            if (lot.compromisDate && !lot.acteDefinitifDate) {
              _ventesEnCours += 1;
            }
          }
        });
      });
      setTotalLotsVendus(_totalLotsVendus);
      setTotalLotsAchetes(_totalLotsAchetes);
      setVentesEnCours(_ventesEnCours);

      let _achatsEnCours = 0;
      ps.forEach((p) => {
        if (p.achatRealise === false && p.compromisDate) {
          _achatsEnCours += 1;
        }
      });
      setAchatsEnCours(_achatsEnCours);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const refreshProjets = async () => {
    try {
      console.log("refreshProjets");
      const ps = await getData(`/v1/me/projets`);
      console.log(ps);
      setProjets(
        ps.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const supprimerProjet = async (uniqueId) => {
    try {
      console.log(`supprimerProjet from DAshboard`);
      await deleteData(`/v1/projets/${uniqueId}`);
      await refreshProjets();
      await refreshGraphs();
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const projectCards = projets.map((projet, index) => {
    return (
      <ProjectCard
        projet={projet}
        label={projet.libelle}
        key={index}
        i={index + 1}
        refreshGraphs={refreshGraphs}
        refreshIndicateurs={refreshIndicateurs}
        refreshProjets={refreshProjets}
        removeProjet={() => supprimerProjet(projet.uniqueId)}
      />
    );
  });

  return (
    <div>
      <Navbar isLoggedIn={true} isAdmin={isAdmin} />
      <Modal isOpen={modalUtilisableFinDeCycle}>
        <ModalHeader toggle={() => setModalUtilisableFinDeCycle(false)}>
          Votre abonnement est terminé
        </ModalHeader>
        <ModalBody>
          <div>
            Votre abonnement est terminé, celui-ci reste toutefois utilisable
            jusqu'au {moment(finDeCycle).format("DD/MM/YYYY")}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => setModalUtilisableFinDeCycle(false)}
          >
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
      <section className="vh-875">
        {noSubscription === false && (
          <Container fluid>
            <Row>
              <Col xs={12} className="welcome pl-30">
                Bienvenue {prenom}
              </Col>
            </Row>
            <Row>
              <Col xs={8}>
                <Container fluid>
                  <Row>
                    <Col xs={12} md={6}>
                      <Card className="card-dashboard-graph">
                        <CardBody>
                          <Bar
                            options={{
                              responsive: true,
                            }}
                            data={{
                              labels: caLabels,
                              datasets: [
                                {
                                  label: `Total des lots vendus ${prettyEuro(
                                    sommeCa
                                  )}`,
                                  data: caValues,
                                  backgroundColor: "rgba(255, 99, 132, 1)",
                                },
                              ],
                            }}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={12} md={6}>
                      <Card className="card-dashboard-graph">
                        <CardBody>
                          <Bar
                            options={{
                              responsive: true,
                            }}
                            data={{
                              labels: margeLabels,
                              datasets: [
                                {
                                  label: `Marge avant IS ${prettyEuro(
                                    sommeMarge
                                  )}`,
                                  data: margeValues,
                                  backgroundColor: "rgba(173, 216, 230, 1)",
                                },
                              ],
                            }}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="welcome">
                      Liste des projets
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Card>
                        <CardBody>{projectCards}</CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs={4}>
                <Container fluid>
                  <Row>
                    <Col xs={12}>
                      <Container fluid className="follow-scroll">
                        <Row>
                          <Col xs={12} className="border-grey">
                            <Card className="pb-50">
                              <div className="lots-encours">
                                {achatsEnCours}
                              </div>
                              <div className="lots-vendus-legende">
                                Achat(s) en cours
                              </div>
                            </Card>
                          </Col>
                        </Row>
                        <Row className="mt-10">
                          <Col xs={12}>
                            <Card className="pb-50">
                              <div className="lots-encours">
                                {ventesEnCours}
                              </div>
                              <div className="lots-vendus-legende">
                                Vente(s) en cours
                              </div>
                            </Card>
                          </Col>
                        </Row>
                        <Row className="mt-10">
                          <Col xs={12}>
                            <Button
                              color="primary"
                              style={{
                                width: "100%",
                              }}
                              onClick={createNewProjet}
                            >
                              Ajouter un projet <BsPlus size={30} />
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <div className="lots-vendus">{totalLotsVendus}</div>
                            <div className="lots-vendus-legende">
                              Total lots vendus
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <div className="lots-vendus">
                              {totalLotsAchetes}
                            </div>
                            <div className="lots-vendus-legende">
                              Total lots achetés
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        )}
        {noSubscription === true && (
          <Container>
            <div className="mt-40">
              <h2 className="pagetitle">
                Nous n'avons pas trouvé d'abonnement actif pour votre compte :'(
              </h2>
              <p>
                Merci de d'effectuer votre règlement ou de contacter notre
                support client à{" "}
                <a href="mailto:contact@viedemarchanddebiens.fr">
                  contact@viedemarchanddebiens.fr
                </a>
              </p>
              <div>
                <Row>
                  {prix
                    .filter((p) => p.active === true)
                    .map((p, index) => {
                      return (
                        <Col
                          xs={12}
                          md={{
                            size: 12 / prix.length - 2,
                          }}
                        >
                          <Card>
                            <CardHeader>{p.product.name}</CardHeader>

                            <CardBody>{p.product.description}</CardBody>
                            <CardBody>
                              Prix de l'
                              {p.type === "one_time"
                                ? `inscription`
                                : `abonnement`}
                              <Badge className="ml-10 mr-10" color="info">
                                {prettyEuro(p.unit_amount / 100)}
                              </Badge>
                              {p.type === "one_time" ? `` : `mensuellement`}
                            </CardBody>
                            <CardBody>
                              <form
                                action={`${process.env.REACT_APP_BASEURL}/v1/me/abonnement`}
                                method="POST"
                              >
                                {/* Add a hidden field with the lookup_key of your Price */}
                                <input
                                  type="hidden"
                                  name="lookup_key"
                                  value={p.lookup_key}
                                />
                                <input
                                  type="hidden"
                                  name="token"
                                  value={localStorage.getItem("token")}
                                />
                                <Button
                                  color="primary"
                                  id="checkout-and-portal-button"
                                  type="submit"
                                >
                                  {p.type === "one_time"
                                    ? `Je m'inscris !`
                                    : `Je m'abonne`}
                                </Button>
                              </form>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </div>
          </Container>
        )}
        <ToastContainer />
      </section>
    </div>
  );
}

export default Dashboard;
