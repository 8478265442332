import React from "react";
import { Input } from "reactstrap";

import "./MetreCarre.css";

function MetreCarre(props) {
  return (
    <div className="input-icon input-icon-right">
      <Input
        maxLength={3}
        type="number"
        {...props}
        style={{ width: "200px" }}
      />
      <i>m²</i>
    </div>
  );
}

export default MetreCarre;
