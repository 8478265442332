import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

export const refTVA = ["TVA Intégrale", "TVA Marge", "Exonéré de TVA"];

export const TVA = (props) => {
  const options = refTVA.map((tva, index) => {
    return (
      <option key={index} value={index}>
        {tva}
      </option>
    );
  });
  return (
    <FormGroup>
      <Label>TVA</Label>
      <Input type="select" className="medium-input" {...props}>
        <option></option>
        {options}
      </Input>
    </FormGroup>
  );
};
