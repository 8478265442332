import React from "react";
import { FormGroup, Label } from "reactstrap";
import PrixMetreCarre from "./PrixMetreCarre";

const PrixVenteMetreCarre = ({ label, ...otherProps }) => {
  return (
    <FormGroup>
      {!label && <Label>Prix € / m²</Label>}
      {label && <Label>{label}</Label>}
      <PrixMetreCarre className="medium-input" {...otherProps} />
    </FormGroup>
  );
};

export default PrixVenteMetreCarre;
