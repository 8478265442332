import React from "react";

import { refTypeBien } from "./TypeBien";
import { refTypeTravaux } from "./TypeTravaux";
import { refTauxTVA, refTauxTVAValue } from "./TauxTVA";

import "./BilanTravauxParLot.css";
import { prettyEuro } from "../../utils";

const BilanTravauxParLot = ({ projet, lot }) => {
  //console.log(lot);
  //console.log(projet);
  return (
    <div>
      <table
        width={`100%`}
        style={{
          border: `none`,
        }}
      >
        {projet.ProjetTravauxes.map((pt) => {
          let count = pt.ProjetTravauxLots.length;
          //console.log(`ProjetTravauxLots : ${count}`);
          return pt.ProjetTravauxLots.map((ptl, index) => {
            if (ptl.projetLotUniqueId === lot.uniqueId) {
              return (
                <tr key={index}>
                  <td>{refTypeTravaux[pt.typeTravaux]}</td>
                  <td>{prettyEuro(pt.prixHorsTaxe / count)} HT</td>
                  <td>
                    {prettyEuro(
                      (pt.prixHorsTaxe * refTauxTVAValue[pt.tauxTVA]) / count
                    )}{" "}
                    TVA ({refTauxTVA[pt.tauxTVA]})
                  </td>
                  <td>
                    {prettyEuro(
                      (pt.prixHorsTaxe * refTauxTVAValue[pt.tauxTVA] +
                        pt.prixHorsTaxe) /
                        count
                    )}{" "}
                    TTC
                  </td>
                </tr>
              );
            }
          });
        })}
      </table>
    </div>
  );
};

export default BilanTravauxParLot;
