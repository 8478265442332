import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

export const refTauxTVA = ["0%", "5,5%", "10%", "20%"];
export const refTauxTVAValue = [0, 0.055, 0.1, 0.2];

export const TauxTVA = (props) => {
  const options = refTauxTVA.map((tva, index) => {
    return (
      <option key={index} value={index}>
        {tva}
      </option>
    );
  });
  return (
    <FormGroup className={props.tauxtvaclassname}>
      <Label>Taux TVA</Label>
      <Input type="select" className="medium-input" {...props}>
        <option></option>
        {options}
      </Input>
    </FormGroup>
  );
};
