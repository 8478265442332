import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

import { Link, useNavigate, useSearchParams, Navigate } from "react-router-dom";
import Navbar from "./layout/Navbar";

import { Container, Row, Col, Button } from "reactstrap";
import { putData, toastConfig } from "../utils";

function WelcomePage() {
  const [isPremium, setIsPremium] = React.useState(false);
  const [toDashboard, setToDashboard] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    async function getUseEffectData() {
      try {
        console.log(searchParams.get("mode"));
        const me = await putData(`/v1/me`, { mode: searchParams.get("mode") });
        setIsPremium(me.isPremium);
      } catch (error) {
        console.log(JSON.parse(error).error.status === 403);

        if (JSON.parse(error).error.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }

    getUseEffectData();
  }, [navigate]);

  if (toDashboard === true) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div>
      <Tooltip id="my-tooltip" />
      <Navbar isLoggedIn={true} isAdmin={false} />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <h2 className="pagetitle">Bienvenue</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item">Bienvenue</li>
            </ol>
          </nav>
        </div>
      </nav>
      <section className="vh-875">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="rounded px-3 px-sm-4 py-3 py-sm-5">
                <h1 className="display-3">Félicitations !</h1>
                <p className="lead">
                  Vous avez désormais accès à l'application
                </p>
                <hr className="my-2" />
                {isPremium && (
                  <p>
                    En tant que membre premium, vous bénéficiez d'un accès
                    illimité à l'application
                  </p>
                )}
                <p className="lead">
                  <Button color="primary" onClick={() => setToDashboard(true)}>
                    Commencer !
                  </Button>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </section>
    </div>
  );
}

export default WelcomePage;
