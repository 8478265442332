import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Container,
  Badge,
} from "reactstrap";

import { BsPlusCircle } from "react-icons/bs";

import { useNavigate } from "react-router-dom";

import { getData, postData, deleteData, toastConfig } from "../../utils";

import "./CoutsFixes.css";
import ChargesCopropriete from "./ChargesCopropriete";
import TaxeFonciere from "./TaxeFonciere";
import Assurance from "./Assurance";
import Energie from "./Energie";
import Divers from "./Divers";
import Diagnostique from "./Diagnostique";
import FraisVenteDivers from "./FraisVenteDivers";
import Commission from "./Commission";
import PreEtatDate from "./PreEtatDate";
import EtatDate from "./EtatDate";

function CoutsFixes(props) {
  const [activeTab, setActiveTab] = useState("chargescopro");
  const [activeTabFV, setActiveTabFV] = useState("diag");
  const [uid, setUid] = useState("");
  const [projetLots, setProjetLots] = useState([]);
  const [projetCharges, setProjetCharges] = useState([]);
  const [projetTaxeFoncieres, setProjetTaxeFoncieres] = useState([]);
  const [projetDiagnostiques, setProjetDiagnostiques] = useState([]);
  const [projetFraisVenteDivers, setProjetFraisVenteDivers] = useState([]);
  const [projetCommissions, setProjetCommissions] = useState([]);
  const [projetPreEtatDates, setProjetPreEtatDates] = useState([]);
  const [projetEtatDates, setProjetEtatDates] = useState([]);
  const [projetAssurances, setProjetAssurances] = useState([]);
  const [projetEnergies, setProjetEnergies] = useState([]);
  const [projetDivers, setProjetDivers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setUid(props.projet);
    async function getUseEffectData() {
      try {
        const projet = await getData(`/v1/projets/${props.projet}`);
        setProjetLots(projet.ProjetLots);

        setProjetCharges(projet.ProjetCharges);
        setProjetTaxeFoncieres(projet.ProjetTaxeFoncieres);
        setProjetDiagnostiques(projet.ProjetDiagnostiques || []);
        setProjetFraisVenteDivers(projet.ProjetFraisVenteDivers || []);
        setProjetCommissions(projet.ProjetCommissions || []);
        setProjetPreEtatDates(projet.ProjetPreEtatDates || []);
        setProjetEtatDates(projet.ProjetEtatDates || []);
        setProjetAssurances(projet.ProjetAssurances || []);
        setProjetEnergies(projet.ProjetEnergies || []);
        setProjetDivers(projet.ProjetDivers || []);
      } catch (error) {
        console.log(JSON.parse(error).error.status === 403);

        if (JSON.parse(error).error.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }
    getUseEffectData();
  }, [uid, navigate, props.projet]);

  const addChargesCopropriete = async () => {
    try {
      await postData(`/v1/projets/${uid}/charges`, {
        itemOrder: projetCharges.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetCharges(
        projet.ProjetCharges.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addTaxeFonciere = async () => {
    try {
      await postData(`/v1/projets/${uid}/taxefoncieres`, {
        itemOrder: projetTaxeFoncieres.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetTaxeFoncieres(
        projet.ProjetTaxeFoncieres.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addDiagnostique = async () => {
    try {
      await postData(`/v1/projets/${uid}/diagnostiques`, {
        itemOrder: projetDiagnostiques.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetDiagnostiques(
        projet.ProjetDiagnostiques.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addFraisVenteDivers = async () => {
    try {
      await postData(`/v1/projets/${uid}/fraisventedivers`, {
        itemOrder: projetDiagnostiques.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetFraisVenteDivers(
        projet.ProjetFraisVenteDivers.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addCommission = async () => {
    try {
      await postData(`/v1/projets/${uid}/commissions`, {
        itemOrder: projetCommissions.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetCommissions(
        projet.ProjetCommissions.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addPreEtatDate = async () => {
    try {
      await postData(`/v1/projets/${uid}/preetatdates`, {
        itemOrder: projetPreEtatDates.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetPreEtatDates(
        projet.ProjetPreEtatDates.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      console.log(error);
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addEtatDate = async () => {
    try {
      await postData(`/v1/projets/${uid}/etatdates`, {
        itemOrder: projetEtatDates.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetEtatDates(
        projet.ProjetEtatDates.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      console.log(error);
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addAssurance = async () => {
    try {
      await postData(`/v1/projets/${uid}/assurances`, {
        itemOrder: projetAssurances.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetAssurances(
        projet.ProjetAssurances.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addDivers = async () => {
    try {
      await postData(`/v1/projets/${uid}/divers`, {
        itemOrder: projetDivers.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetDivers(
        projet.ProjetDivers.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const addEnergie = async () => {
    try {
      await postData(`/v1/projets/${uid}/energies`, {
        itemOrder: projetEnergies.length + 1,
        projetUniqueId: uid,
      });
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetEnergies(
        projet.ProjetEnergies.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeCharge = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/charges/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetCharges(
        projet.ProjetCharges.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeTaxeFonciere = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/taxefoncieres/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetTaxeFoncieres(
        projet.ProjetTaxeFoncieres.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeDiagnostique = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/diagnostiques/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetDiagnostiques(
        projet.ProjetDiagnostiques.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeFraisVenteDivers = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/fraisventedivers/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetFraisVenteDivers(
        projet.ProjetFraisVenteDivers.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeCommission = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/commissions/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetCommissions(
        projet.ProjetCommissions.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removePreEtatDate = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/preetatdates/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetPreEtatDates(
        projet.ProjetPreEtatDates.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeEtatDate = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/etatdates/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetEtatDates(
        projet.ProjetEtatDates.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeAssurance = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/assurances/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetAssurances(
        projet.ProjetAssurances.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeDivers = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/divers/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetDivers(
        projet.ProjetDivers.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const removeEnergie = async (id) => {
    try {
      await deleteData(`/v1/projets/${uid}/energies/${id}`);
      const projet = await getData(`/v1/projets/${uid}`);
      setProjetEnergies(
        projet.ProjetEnergies.sort((a, b) => a.itemOrder - b.itemOrder)
      );
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const chargescopro = projetCharges.map((cp, index) => {
    return (
      <ChargesCopropriete
        key={index}
        projet={props.projet}
        uniqueId={cp.uniqueId}
        removeCharge={() => removeCharge(cp.uniqueId)}
      />
    );
  });

  const taxefoncieres = projetTaxeFoncieres.map((tf, index) => {
    return (
      <TaxeFonciere
        key={index}
        projet={props.projet}
        uniqueId={tf.uniqueId}
        removeTaxeFonciere={() => removeTaxeFonciere(tf.uniqueId)}
      />
    );
  });

  const assurances = projetAssurances.map((a, index) => {
    return (
      <Assurance
        key={index}
        projet={props.projet}
        uniqueId={a.uniqueId}
        removeAssurance={() => removeAssurance(a.uniqueId)}
      />
    );
  });

  const energies = projetEnergies.map((e, index) => {
    return (
      <Energie
        key={index}
        projet={props.projet}
        uniqueId={e.uniqueId}
        removeEnergie={() => removeEnergie(e.uniqueId)}
      />
    );
  });

  const divers = projetDivers.map((d, index) => {
    return (
      <Divers
        key={index}
        projet={props.projet}
        uniqueId={d.uniqueId}
        removeDivers={() => removeDivers(d.uniqueId)}
      />
    );
  });

  const diagnostics = projetDiagnostiques.map((d, index) => {
    return (
      <Diagnostique
        key={index}
        projet={props.projet}
        lots={projetLots}
        uniqueId={d.uniqueId}
        removeDiagnostique={() => removeDiagnostique(d.uniqueId)}
      />
    );
  });

  const fraisVenteDivers = projetFraisVenteDivers.map((fvd, index) => {
    return (
      <FraisVenteDivers
        key={index}
        projet={props.projet}
        lots={projetLots}
        uniqueId={fvd.uniqueId}
        removeFraisVenteDivers={() => removeFraisVenteDivers(fvd.uniqueId)}
      />
    );
  });

  const commissions = projetCommissions.map((c, index) => {
    return (
      <Commission
        key={index}
        projet={props.projet}
        lots={projetLots}
        uniqueId={c.uniqueId}
        removeCommission={() => removeCommission(c.uniqueId)}
      />
    );
  });

  const preEtatDates = projetPreEtatDates.map((ped, index) => {
    return (
      <PreEtatDate
        key={index}
        projet={props.projet}
        lots={projetLots}
        uniqueId={ped.uniqueId}
        removePreEtatDate={() => removePreEtatDate(ped.uniqueId)}
      />
    );
  });

  const etatDates = projetEtatDates.map((ed, index) => {
    return (
      <EtatDate
        key={index}
        projet={props.projet}
        lots={projetLots}
        uniqueId={ed.uniqueId}
        removeEtatDate={() => removeEtatDate(ed.uniqueId)}
      />
    );
  });

  return (
    <div className="mb-25">
      <Container fluid>
        <Row>
          <Col xs={10}>
            <span className="couts-fixes-nombre">1</span>
            <span className="couts-fixes-libelle">Coûts de gestion</span>
          </Col>
        </Row>
        <Row className="pt-10">
          <Col xs={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "chargescopro" ? "active flipping-tab" : ""
                  }
                  onClick={() => setActiveTab("chargescopro")}
                >
                  Charges Copropriété{" "}
                  <Badge
                    className="step-counter"
                    color={projetCharges.length > 0 ? `danger` : `primary`}
                  >
                    {projetCharges.length}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "taxefonciere" ? "active flipping-tab" : ""
                  }
                  onClick={() => setActiveTab("taxefonciere")}
                >
                  Taxe Foncière{" "}
                  <Badge
                    className="step-counter"
                    color={
                      projetTaxeFoncieres.length > 0 ? `danger` : `primary`
                    }
                  >
                    {projetTaxeFoncieres.length}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "assurance" ? "active flipping-tab" : ""
                  }
                  onClick={() => setActiveTab("assurance")}
                >
                  Assurance{" "}
                  <Badge
                    className="step-counter"
                    color={projetAssurances.length > 0 ? `danger` : `primary`}
                  >
                    {projetAssurances.length}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "eau-elec-gaz" ? "active flipping-tab" : ""
                  }
                  onClick={() => setActiveTab("eau-elec-gaz")}
                >
                  Eau / Electricité / Gaz{" "}
                  <Badge
                    className="step-counter"
                    color={projetEnergies.length > 0 ? `danger` : `primary`}
                  >
                    {projetEnergies.length}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTab === "divers" ? "active flipping-tab" : ""
                  }
                  onClick={() => setActiveTab("divers")}
                >
                  Divers{" "}
                  <Badge
                    className="step-counter"
                    color={projetDivers.length > 0 ? `danger` : `primary`}
                  >
                    {projetDivers.length}
                  </Badge>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="chargescopro" className="couts-fixes-tab">
                <Row>
                  <Col xs="12">{chargescopro}</Col>
                </Row>
                <Row>
                  <Col xs="11"></Col>
                  <Col xs="1">
                    <div className="right mt-10 mr-25">
                      <BsPlusCircle
                        size={20}
                        className="pointer right"
                        onClick={addChargesCopropriete}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="taxefonciere" className="couts-fixes-tab">
                <Row>
                  <Col xs="12">{taxefoncieres}</Col>
                </Row>
                <Row>
                  <Col xs="11"></Col>
                  <Col xs="1">
                    <div className="right mt-10 mr-25">
                      <BsPlusCircle
                        size={20}
                        className="pointer right"
                        onClick={addTaxeFonciere}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="assurance" className="couts-fixes-tab">
                <Row>
                  <Col xs="12">{assurances}</Col>
                </Row>
                <Row>
                  <Col xs="11"></Col>
                  <Col xs="1">
                    <div className="right mt-10 mr-25">
                      <BsPlusCircle
                        size={20}
                        className="pointer right"
                        onClick={addAssurance}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="eau-elec-gaz" className="couts-fixes-tab">
                <Row>
                  <Col xs="12">{energies}</Col>
                </Row>
                <Row>
                  <Col xs="11"></Col>
                  <Col xs="1">
                    <div className="right mt-10 mr-25">
                      <BsPlusCircle
                        size={20}
                        className="pointer right"
                        onClick={addEnergie}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="divers" className="couts-fixes-tab">
                <Row>
                  <Col xs="12">{divers}</Col>
                </Row>
                <Row>
                  <Col xs="11"></Col>
                  <Col xs="1">
                    <div className="right mt-10 mr-25">
                      <BsPlusCircle
                        size={20}
                        className="pointer right"
                        onClick={addDivers}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
        <Row className="mt-25">
          <Col xs={10}>
            <span className="couts-fixes-nombre">2</span>
            <span className="couts-fixes-libelle">Frais de vente</span>
          </Col>
        </Row>
        <Row className="pt-10">
          <Col xs={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={
                    activeTabFV === "diag" ? "active flipping-tab" : ""
                  }
                  onClick={() => setActiveTabFV("diag")}
                >
                  Diagnostiques{" "}
                  <Badge
                    className="step-counter"
                    color={
                      projetDiagnostiques.length > 0 ? `danger` : `primary`
                    }
                  >
                    {projetDiagnostiques.length}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTabFV === "preetatdate" ? "active flipping-tab" : ""
                  }
                  onClick={() => setActiveTabFV("preetatdate")}
                >
                  Pré-état daté{" "}
                  <Badge
                    className="step-counter"
                    color={projetPreEtatDates.length > 0 ? `danger` : `primary`}
                  >
                    {projetPreEtatDates.length}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTabFV === "etatdate" ? "active flipping-tab" : ""
                  }
                  onClick={() => setActiveTabFV("etatdate")}
                >
                  Etat daté{" "}
                  <Badge
                    className="step-counter"
                    color={projetEtatDates.length > 0 ? `danger` : `primary`}
                  >
                    {projetEtatDates.length}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTabFV === "commissions" ? "active flipping-tab" : ""
                  }
                  onClick={() => setActiveTabFV("commissions")}
                >
                  Commissions{" "}
                  <Badge
                    className="step-counter"
                    color={projetCommissions.length > 0 ? `danger` : `primary`}
                  >
                    {projetCommissions.length}
                  </Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    activeTabFV === "diversFV" ? "active flipping-tab" : ""
                  }
                  onClick={() => setActiveTabFV("diversFV")}
                >
                  Divers{" "}
                  <Badge
                    className="step-counter"
                    color={
                      projetFraisVenteDivers.length > 0 ? `danger` : `primary`
                    }
                  >
                    {projetFraisVenteDivers.length}
                  </Badge>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTabFV}>
              <TabPane tabId="diag" className="couts-fixes-tab">
                <Row>
                  <Col xs="12">{diagnostics}</Col>
                </Row>
                {projetLots.length > 0 && (
                  <Row>
                    <Col xs="11"></Col>
                    <Col xs="1">
                      <div className="right mt-10 mr-25">
                        <BsPlusCircle
                          size={20}
                          className="pointer right"
                          onClick={addDiagnostique}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </TabPane>
              <TabPane tabId="preetatdate" className="couts-fixes-tab">
                <Row>
                  <Col xs="12">{preEtatDates}</Col>
                </Row>
                {projetLots.length > 0 && (
                  <Row>
                    <Col xs="11"></Col>
                    <Col xs="1">
                      <div className="right mt-10 mr-25">
                        <BsPlusCircle
                          size={20}
                          className="pointer right"
                          onClick={addPreEtatDate}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </TabPane>
              <TabPane tabId="etatdate" className="couts-fixes-tab">
                <Row>
                  <Col xs="12">{etatDates}</Col>
                </Row>
                {projetLots.length > 0 && (
                  <Row>
                    <Col xs="11"></Col>
                    <Col xs="1">
                      <div className="right mt-10 mr-25">
                        <BsPlusCircle
                          size={20}
                          className="pointer right"
                          onClick={addEtatDate}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </TabPane>
              <TabPane tabId="commissions" className="couts-fixes-tab">
                <Row>
                  <Col xs="12">{commissions}</Col>
                </Row>
                {projetLots.length > 0 && (
                  <Row>
                    <Col xs="11"></Col>
                    <Col xs="1">
                      <div className="right mt-10 mr-25">
                        <BsPlusCircle
                          size={20}
                          className="pointer right"
                          onClick={addCommission}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </TabPane>
              <TabPane tabId="diversFV" className="couts-fixes-tab">
                <Row>
                  <Col xs="12">{fraisVenteDivers}</Col>
                </Row>
                {projetLots.length > 0 && (
                  <Row>
                    <Col xs="11"></Col>
                    <Col xs="1">
                      <div className="right mt-10 mr-25">
                        <BsPlusCircle
                          size={20}
                          className="pointer right"
                          onClick={addFraisVenteDivers}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CoutsFixes;
