import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

export const refTypeEnergie = ["Eau", "Electricité", "Gaz"];

export const TypeEnergie = (props) => {
  const options = refTypeEnergie.map((tva, index) => {
    return (
      <option key={index} value={index}>
        {tva}
      </option>
    );
  });
  return (
    <FormGroup className={props.typeenergieclassname}>
      <Label>Type</Label>
      <Input type="select" className="small-input" {...props}>
        <option></option>
        {options}
      </Input>
    </FormGroup>
  );
};
