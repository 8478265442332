import { refTauxTVAValue } from "./Components/TauxTVA";

export const computeCoutTotal = (p) => {
  let _travaux = 0;
  console.log(p);

  p.ProjetTravauxes.forEach((travail) => {
    _travaux +=
      travail.prixHorsTaxe +
      travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA];
  });

  console.log(_travaux);

  let _fraisNotaire = p.fraisNotaire;

  let _fraisTotal = 0;

  let _commissionEngagement =
    ((p.prixAchat +
      p.fraisAgence +
      p.fraisNotaire +
      _travaux -
      (p.prixAchat + p.fraisAgence + p.fraisNotaire + _travaux) *
        (p.pourcentageApport / 100)) /
      100) *
    p.pourcentageCommissionEngagement;

  let _interetsEmprunt =
    ((p.prixAchat +
      p.fraisAgence +
      p.fraisNotaire +
      _travaux -
      (p.prixAchat + p.fraisAgence + p.fraisNotaire + _travaux) *
        (p.pourcentageApport / 100)) /
      100) *
    p.pourcentageInteretsEmprunt *
    (p.dureeInteretsEmprunt / 12);

  let _hypotheque =
    ((p.prixAchat +
      p.fraisAgence +
      p.fraisNotaire +
      _travaux -
      (p.prixAchat + p.fraisAgence + p.fraisNotaire + _travaux) *
        (p.pourcentageApport / 100)) /
      100) *
    p.pourcentageHypotheque;

  /* console.log(`_commissionEngagement : ${_commissionEngagement}`);
  console.log(`_interetsEmprunt : ${_interetsEmprunt}`);
  console.log(`_hypotheque : ${_hypotheque}`);
  console.log(`fraisDossier : ${p.fraisDossier}`); */

  _fraisTotal =
    _commissionEngagement + p.fraisDossier + _interetsEmprunt + _hypotheque;

  let _montantDepenses = 0;
  p.ProjetTravauxes.forEach((montant) => {
    _montantDepenses +=
      montant.prixHorsTaxe +
      montant.prixHorsTaxe * refTauxTVAValue[montant.tauxTVA];
  });

  /* console.log(`_fraisTotal : ${_fraisTotal}`);
  console.log(`_prixNetVendeur : ${_prixNetVendeur}`);
  console.log(`_fraisNotaire : ${_fraisNotaire}`);
  console.log(`_montantDepenses : ${_montantDepenses}`);
  console.log(`p.fraisAgence : ${p.fraisAgence}`); */

  return (
    _fraisTotal +
    _fraisNotaire + // Frais Notaire
    _montantDepenses +
    p.fraisAgence +
    p.prixAchat
  );
};

export const computeCoutTravaux = (p) => {
  let _travaux = 0;
  p.ProjetTravauxes.forEach((travail) => {
    _travaux +=
      travail.prixHorsTaxe +
      travail.prixHorsTaxe * refTauxTVAValue[travail.tauxTVA];
  });

  return _travaux;
};

export const computeCoutsFixes = (p) => {
  let _sommeTTCFV = 0;

  p.ProjetLots.forEach((lot) => {
    lot.ProjetLotFraisVentes.forEach((fv) => {
      _sommeTTCFV +=
        fv.prixHorsTaxe * refTauxTVAValue[fv.tauxTVA] + fv.prixHorsTaxe;
    });
  });

  return _sommeTTCFV;
};
