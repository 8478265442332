import React from "react";
import { FormGroup, Label } from "reactstrap";
import CurrencyInput from "./CurrencyInput";

const PrixTTC = (props) => {
  return (
    <FormGroup className={props.montanttvaclassname}>
      <Label>Prix TTC</Label>
      <CurrencyInput className="medium-input" {...props} />
    </FormGroup>
  );
};

export default PrixTTC;
