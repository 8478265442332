import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

import { Link, useNavigate } from "react-router-dom";
import Navbar from "./layout/Navbar";

import "./FraisNotaire.css";
import { FaQuestionCircle } from "react-icons/fa";

import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { toastConfig, getExternalData, prettyEuro, getData } from "../utils";
import CurrencyInput from "./Components/CurrencyInput";

import {
  calculDroitsEtTaxes,
  calculEmolumentsNotaire,
  calculMontantFraisNotaire,
} from "./Components/helpers/calculFraisNotaire";

function FraisNotairePage() {
  const [montantBien, setMontantBien] = useState("");
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [totalCoutsAcquisition, setTotalCoutsAcquisition] = useState(0);
  const [codePostal, setCodePostal] = useState("");
  const [voirResultats, setVoirResultats] = useState(false);
  const [ville, setVille] = useState("");
  const [droitsEtTaxes, setDroitsEtTaxes] = useState(0);
  const [emolumentsDuNotaire, setEmolumentsDuNotaire] = useState(0);
  const [departements, setDepartements] = useState([]);
  const [montantTotalAcquisition, setMontantTotalAcquisition] = useState(0);
  const [emolumentsEtFormalitesDeDebours, setEmolumentsEtFormalitesDeDebours] =
    useState(0);
  const [tranche1, setTranche1] = useState(0);
  const [tranche2, setTranche2] = useState(0);
  const [tranche3, setTranche3] = useState(0);
  const [tranche4, setTranche4] = useState(0);
  const [typeBien, setTypeBien] = useState(0);
  const [currentDepartement, setCurrentDepartement] = useState("");
  const [droitDepartementalEnregistrment, setDroitDepartementalEnregistrment] =
    useState(0);
  const [taxeCommunale, setTaxeCommunale] = useState(0);
  const [fraisAssiette, setFraisAssiette] = useState(0);
  const [contributionSecuriteImmobiliere, setContributionSecuriteImmobiliere] =
    useState(0);
  const [securiteImmobiliere, setSecuriteImmobiliere] = useState(0);
  useState(0);
  const [montantFraisNotaire, setMontantFraisNotaire] = useState(0);
  const navigate = useNavigate();

  const changeCodePostal = async (value) => {
    try {
      setVoirResultats(false);
      setCodePostal(value);
      if (value && value.length === 5) {
        const v = await getExternalData(
          `https://apicarto.ign.fr/api/codes-postaux/communes/${value}`
        );
        Array.isArray(v) ? setVille(v[0].nomCommune) : setVille("");
      } else {
        setVille("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleComputeFraisNotaire = () => {
    const _montantFraisNotaire = calculMontantFraisNotaire(
      montantBien,
      typeBien,
      codePostal
    );

    console.log(`montantBien : ${montantBien}`);
    const emoluments = calculEmolumentsNotaire(montantBien);
    const droitsEtTaxes = calculDroitsEtTaxes(
      montantBien,
      typeBien,
      codePostal
    );

    setMontantFraisNotaire(_montantFraisNotaire);

    console.log(emoluments);
    console.log(droitsEtTaxes);
    setTranche1(emoluments[0]);
    setTranche2(emoluments[1]);
    setTranche3(emoluments[2]);
    setTranche4(emoluments[3]);
    setEmolumentsDuNotaire(
      (emoluments[0] + emoluments[1] + emoluments[2] + emoluments[3]) * 1.2
    );
    setDroitsEtTaxes(montantBien * 0.0071498);
    setDroitDepartementalEnregistrment(droitsEtTaxes[0]);
    setEmolumentsEtFormalitesDeDebours(1360);
    setSecuriteImmobiliere(montantBien * 0.001);
    setTaxeCommunale(droitsEtTaxes[1]);
    setFraisAssiette(droitsEtTaxes[2]);
    setContributionSecuriteImmobiliere(droitsEtTaxes[3]);
    setVoirResultats(true);
    setTotalCoutsAcquisition(
      (emoluments[0] + emoluments[1] + emoluments[2] + emoluments[3]) * 1.2 +
        1360 +
        montantBien * 0.001 +
        montantBien * 0.0071498
    );
    setMontantTotalAcquisition(
      montantBien +
        (emoluments[0] + emoluments[1] + emoluments[2] + emoluments[3]) * 1.2 +
        1360 +
        montantBien * 0.001 +
        montantBien * 0.0071498
    );
  };

  useEffect(() => {
    console.log("useEffect FraisNotairePage");
    async function getUseEffectData() {
      try {
        const profiles = await getData("/v1/me/profiles");
        if (profiles.includes("ADMIN")) {
          setIsAdmin(true);
        }
        const dpts = await getExternalData(
          `https://geo.api.gouv.fr/departements`
        );

        setDepartements(dpts);
      } catch (error) {
        console.log(JSON.parse(error).error.status === 403);

        if (JSON.parse(error).error.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }

    getUseEffectData();
  }, [navigate]);
  return (
    <div>
      <Tooltip id="my-tooltip" />
      <Navbar isLoggedIn={true} isAdmin={isAdmin} />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <h2 className="pagetitle">Calculateur</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item">Calculateur Frais de Notaire</li>
            </ol>
          </nav>
        </div>
      </nav>
      <section className="vh-875">
        <Container fluid>
          <Row>
            <Col xs={12} md={8}>
              <Card className="step-body">
                <span className="step-title">
                  Calculez vos coûts d'un bien immobilier{" "}
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={`Calcul à titre indicatif et ne remplace pas l'évaluation des frais d'acquisition que votre notaire doit vous remettre`}
                  >
                    <FaQuestionCircle className="ml-10" />
                  </a>
                  <Card className="inside-body">
                    <Container fluid>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Montant de votre bien
                            </Label>
                            <CurrencyInput
                              onChange={(e) => setMontantBien(e.target.value)}
                              value={montantBien}
                              style={{ width: "100%" }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">Département</Label>
                            <Input
                              type="select"
                              value={currentDepartement}
                              onChange={(e) =>
                                setCurrentDepartement(e.target.value)
                              }
                            >
                              {departements.map((dpt, index) => {
                                return (
                                  <option value={index} key={index}>
                                    {`${dpt.code} ${dpt.nom}`}
                                  </option>
                                );
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-20">
                        <Col xs={12}>
                          <Button
                            style={{ width: "100%" }}
                            color="primary"
                            onClick={handleComputeFraisNotaire}
                          >
                            Calculer
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mt-20">
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Total des frais d'acquisition
                            </Label>
                            <CurrencyInput
                              disabled
                              style={{ width: "100%" }}
                              value={totalCoutsAcquisition}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup>
                            <Label className="input-label">
                              Montant total de l'acquisition
                            </Label>
                            <CurrencyInput
                              disabled
                              style={{ width: "100%" }}
                              value={
                                parseInt(montantBien, 10) +
                                totalCoutsAcquisition
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                </span>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="step-body">
                <span className="step-title">
                  Détails de vos coûts d'acquisition
                </span>
                <div className="frais-notaire-summary">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20px" }}>
                          <span className="bilan-project-step-nombre">1</span>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          Emoluments du notaire
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(emolumentsDuNotaire)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20px" }}>
                          <span className="bilan-project-step-nombre">2</span>
                        </td>
                        <td style={{ textAlign: "left" }}>Droits & taxes</td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(droitsEtTaxes)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20px" }}>
                          <span className="bilan-project-step-nombre">3</span>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          Emoluments de formalités et débours
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(emolumentsEtFormalitesDeDebours)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "20px" }}>
                          <span className="bilan-project-step-nombre">4</span>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          Sécurité immobilière
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(securiteImmobiliere)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left" }}>Total des frais</td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(totalCoutsAcquisition)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          Coût de l'opération
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {prettyEuro(
                            parseInt(montantBien, 10) + totalCoutsAcquisition
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </section>
    </div>
  );
}

export default FraisNotairePage;
