import React from "react";
import { FormGroup, Label } from "reactstrap";
import CurrencyInput from "./CurrencyInput";

const PrixFAI = ({ label = false, ...otherProps }) => {
  return (
    <FormGroup>
      {!label && <Label>Prix de vente FAI</Label>}
      {label && <Label>{label}</Label>}
      <CurrencyInput className="medium-input" {...otherProps} />
    </FormGroup>
  );
};

export default PrixFAI;
