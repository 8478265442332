import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, Navigate } from "react-router-dom";
import { postData, toastConfig } from "../utils";
import Navbar from "./layout/Navbar";

function LostPassword() {
  const [email, setEmail] = React.useState(false);
  const [resetCodeReady, setResetCodeReady] = React.useState(false);
  const [resetCode, setResetCode] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [toLogin, setToLogin] = React.useState(false);

  if (toLogin === true) {
    return <Navigate to="/" />;
  }

  const handleGetCode = async () => {
    try {
      await postData("/authentification/lostpassword", {
        email: email,
      });
      setResetCodeReady(true);
    } catch (error) {
      console.log(error);
      toast.error("Error", toastConfig);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await postData("/authentification/resetpassword", {
        email,
        motDePasse: password,
        resetCode,
      });
      toast.success(response.message, {
        onClose: (props) => setToLogin(true),
      });
    } catch (error) {
      console.log(error);
      toast.error("Error", toastConfig);
    }
  };

  return (
    <div>
      <Navbar isLoggedIn={false} isAdmin={false} />
      <section className="vh-875">
        <div className="container py-10 h-150">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "10px" }}
              >
                <div className="card-body p-3 text-center">
                  <h3 className="mb-5">Récuperer un mot de passe</h3>

                  <div className="form-outline mb-4 left">
                    <label className="form-label left" htmlFor="login">
                      Votre adresse email:
                    </label>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      className="form-control form-control-lg"
                    />
                  </div>

                  {resetCodeReady && (
                    <div className="form-outline mb-4 left">
                      <label className="form-label" htmlFor="password">
                        Votre mot de passe:
                      </label>
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        className="form-control form-control-lg"
                      />
                    </div>
                  )}

                  {resetCodeReady && (
                    <div className="form-outline mb-4 left">
                      <label className="form-label" htmlFor="password">
                        Code reçu par mail:
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setResetCode(e.target.value)}
                        className="form-control form-control-lg"
                      />
                    </div>
                  )}

                  {!resetCodeReady && (
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      type="submit"
                      onClick={handleGetCode}
                      disabled={email === ""}
                    >
                      CLIQUEZ ICI
                    </button>
                  )}
                  {resetCodeReady && (
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      type="submit"
                      onClick={handleResetPassword}
                      disabled={
                        email === "" || password === "" || resetCode === ""
                      }
                    >
                      REINITIALISER
                    </button>
                  )}
                </div>
                <p className="center">
                  <br />
                  <Link to="/">Se connecter !</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </div>
  );
}

export default LostPassword;
