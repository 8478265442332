import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const Libelle = (props) => {
  return (
    <FormGroup className={props.libelleclassname}>
      <Label>{props.libelle}</Label>
      <Input className="small-input" {...props} />
    </FormGroup>
  );
};

export default Libelle;
