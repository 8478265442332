import React from "react";
import { Navigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { postData, toastConfig } from "../utils";

function Register() {
  const [toLogin, setToLogin] = React.useState(false);
  const [nom, setNom] = React.useState("");
  const [prenom, setPrenom] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [motDePasse, setMotDePasse] = React.useState("");
  const [confirmation, setConfirmation] = React.useState("");

  if (toLogin === true) {
    return <Navigate to={`/`} />;
  }

  const handleRegister = async () => {
    console.log(`handleRegister`);
    let errors = false;
    try {
      if (nom === "") {
        toast.error(`le nom est obligatoire`, toastConfig);
        errors = true;
      }

      if (prenom === "") {
        toast.error(`le prénom est obligatoire`, toastConfig);
        errors = true;
      }

      if (email === "") {
        toast.error(`l'email est obligatoire`, toastConfig);
        errors = true;
      }

      if (motDePasse === "" || confirmation === "") {
        toast.error(
          `le mot de passe et sa confirmation sont obligatoires`,
          toastConfig
        );
        errors = true;
      }

      if (motDePasse !== confirmation) {
        toast.error(
          `le mot de passe et sa confirmation doivent correspondre`,
          toastConfig
        );
        errors = true;
      }

      if (errors === true) {
        return;
      }

      const response = await postData("/authentification/enregistrement", {
        nom,
        prenom,
        email,
        motDePasse,
      });

      console.log(response);

      setToLogin(true);
    } catch (error) {
      let e = JSON.parse(error);
      switch (e.error.status) {
        case 409:
          toast.error("Ce compte existe déjà", toastConfig);
          break;
        default:
          toast.error(e.message, toastConfig);
      }
    }
  };

  return (
    <section className="vh-875">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card shadow-2-strong"
              style={{ borderRadius: "10px" }}
            >
              <div className="card-body p-3 text-center">
                <h3 className="mb-5">Inscrivez-vous</h3>

                <div className="form-outline mb-4 left">
                  <label className="form-label left" htmlFor="nom">
                    Votre Nom:
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setNom(e.target.value)}
                    className="form-control form-control-lg"
                  />
                </div>
                <div className="form-outline mb-4 left">
                  <label className="form-label left" htmlFor="prenom">
                    Votre Prénom:
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setPrenom(e.target.value)}
                    className="form-control form-control-lg"
                  />
                </div>
                <div className="form-outline mb-4 left">
                  <label className="form-label left" htmlFor="email">
                    Votre adresse email:
                  </label>
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control form-control-lg"
                  />
                </div>

                <div className="form-outline mb-4 left">
                  <label className="form-label" htmlFor="password">
                    Votre mot de passe:
                  </label>
                  <input
                    type="password"
                    onChange={(e) => setMotDePasse(e.target.value)}
                    className="form-control form-control-lg"
                  />
                </div>

                <div className="form-outline mb-4 left">
                  <label className="form-label" htmlFor="confirmation">
                    Confirmation:
                  </label>
                  <input
                    type="password"
                    onChange={(e) => setConfirmation(e.target.value)}
                    className="form-control form-control-lg"
                  />
                </div>

                <button
                  className="btn btn-primary btn-lg btn-block"
                  type="submit"
                  onClick={handleRegister}
                >
                  CLIQUEZ ICI
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

export default Register;
