import React from "react";
import { FormGroup, Label } from "reactstrap";
import MetreCarre from "./MetreCarre";

const Surface = ({ showLabel = true, ...otherProps }) => {
  return (
    <FormGroup>
      {showLabel && <Label>Surface</Label>}
      <MetreCarre className="medium-input" {...otherProps} />
    </FormGroup>
  );
};

export default Surface;
