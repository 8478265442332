import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import LostPasswordPage from "./pages/LostPasswordPage";
import RegisterPage from "./pages/RegisterPage";
import DashboardPage from "./pages/DashboardPage";
import WelcomePage from "./pages/WelcomePage";
import CancelPage from "./pages/CancelPage";
import FraisNotairePage from "./pages/FraisNotairePage";
import FraisEncherePage from "./pages/FraisEncherePage";
import ProjectPage from "./pages/ProjectPage";
import UserPage from "./pages/UserPage";

import "./App.css";
import AdministrationPage from "./pages/AdministrationPage";
import UtilisateurPage from "./pages/UtilisateurPage";

function useAuth() {
  const decodedJwt = parseJwt(localStorage.getItem("token"));
  if (!decodedJwt) {
    return false;
  }
  if (decodedJwt.exp * 1000 < Date.now()) {
    return false;
  }
  return true;
}

function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}

function PrivateRoute({ children }) {
  const auth = useAuth();
  return auth ? children : <Navigate to="/" />;
}

function AdminRoute({ children }) {
  const auth = useAuth();
  return auth ? children : <Navigate to="/" />;
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initState();
  }

  initState = () => {
    return {
      showBasic: false,
    };
  };

  toggleShowBasic = () => {
    this.setState({
      showBasic: !this.state.showBasic,
    });
  };

  render() {
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/lostpassword" element={<LostPasswordPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/welcome"
            element={
              <PrivateRoute>
                <WelcomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/cancel"
            element={
              <PrivateRoute>
                <CancelPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/fraisnotaire"
            element={
              <PrivateRoute>
                <FraisNotairePage />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/fraisenchere"
            element={
              <PrivateRoute>
                <FraisEncherePage />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/compte"
            element={
              <PrivateRoute>
                <UserPage />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/administration"
            element={
              <AdminRoute>
                <AdministrationPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/utilisateurs/:uid"
            element={
              <AdminRoute>
                <UtilisateurPage />
              </AdminRoute>
            }
          ></Route>
          <Route
            path="/projets/:uid"
            element={
              <PrivateRoute>
                <ProjectPage />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
        {/*<Footer />*/}
      </div>
    );
  }
}

export default App;
