import React from "react";
import { FormGroup, Label } from "reactstrap";
import CurrencyInput from "./CurrencyInput";

const MontantTVA = (props) => {
  return (
    <FormGroup className={props.montanttvaclassname}>
      <Label>TVA</Label>
      <CurrencyInput className="small-input" {...props} />
    </FormGroup>
  );
};

export default MontantTVA;
