import React from "react";
import { FormGroup, Label } from "reactstrap";
import PercentInput from "./PercentInput";

const Ventilation = (props) => {
  return (
    <FormGroup
      style={{ display: "none" }}
      className={props.ventilationclassname}
    >
      <Label>Ventilation %</Label>
      <PercentInput className="tiny-input" {...props} />
    </FormGroup>
  );
};

export default Ventilation;
