import React from "react";
import { Input } from "reactstrap";

import "./CurrencyInput.css";

function CurrencyInput(props) {
  return (
    <div className="input-icon input-icon-right">
      <Input type="number" {...props} />
      <i>€</i>
    </div>
  );
}

export default CurrencyInput;
