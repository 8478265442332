import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, Navigate } from "react-router-dom";
import { postData, toastConfig } from "../utils";
import Navbar from "./layout/Navbar";
import packageJson from "../../package.json";

function Login() {
  const [email, setEmail] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [toDashboard, setToDashboard] = React.useState(false);

  if (toDashboard === true) {
    return <Navigate to="/dashboard" />;
  }

  const handleLogin = async () => {
    try {
      const response = await postData("/authentification/identification", {
        email: email,
        motDePasse: password,
      });

      localStorage.setItem("token", response.token);
      localStorage.setItem("email", response.email);
      localStorage.setItem("utilisateur", `${response.prenom} ${response.nom}`);
      localStorage.setItem("prenom", `${response.prenom}`);
      setToDashboard(true);
      console.log("apres");
    } catch (error) {
      toast.error(JSON.parse(error).error.data.message, toastConfig);
    }
  };

  return (
    <div>
      <Navbar isLoggedIn={false} isAdmin={false} />
      <section className="vh-875">
        <div className="container py-10 h-150">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "10px" }}
              >
                <div className="card-body p-3 text-center">
                  <h3 className="mb-5">Connectez-vous</h3>

                  <div className="form-outline mb-4 left">
                    <label className="form-label left" htmlFor="login">
                      Votre adresse email:
                    </label>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      className="form-control form-control-lg"
                    />
                  </div>

                  <div className="form-outline mb-4 left">
                    <label className="form-label" htmlFor="password">
                      Votre mot de passe:
                    </label>
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      className="form-control form-control-lg"
                    />
                  </div>

                  <div className="d-flex justify-content-around align-items-center mb-4 ">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => {}}
                        value=""
                        id="form1Example3"
                        checked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="form1Example3"
                      >
                        {" "}
                        Rester connecté{" "}
                      </label>
                    </div>
                    <Link to="/lostpassword">Mot de passe oublié ?</Link>
                  </div>
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    type="submit"
                    onClick={handleLogin}
                    disabled={email === "" || password === ""}
                  >
                    CLIQUEZ ICI
                  </button>
                </div>
                <p className="center">
                  Vous n'avez pas de compte?
                  <br />
                  <Link to="/register">Créez votre compte !</Link>
                </p>
                <p className="center">
                  {packageJson.name} - v{packageJson.version}
                </p>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </div>
  );
}

export default Login;
