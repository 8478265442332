import React, { useState, useEffect } from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import Navbar from "./layout/Navbar";

import {
  BsPerson,
  BsHouseDoor,
  BsCurrencyEuro,
  BsCreditCard2Front,
  BsPeople,
} from "react-icons/bs";

import Cards from "react-credit-cards";

import { deleteData, getData, putData, toastConfig } from "../utils";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert,
} from "reactstrap";

import "./UserPage.css";

function UserPage() {
  const navigate = useNavigate();
  const [subPage, setSubPage] = useState("details");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [ligne1, setLigne1] = useState("");
  const [ligne2, setLigne2] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [ville, setVille] = useState("");
  const [pays, setPays] = useState("");
  const [methodeDePaiment, setMethodeDePaiment] = useState({});
  const [cvc, setCvc] = useState("");
  const [numero, setNumero] = useState("");
  const [expiration, setExpiration] = useState("");
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [detenteurCarte, setDetenteurCarte] = useState(`${prenom} ${nom}`);
  const [modalMethodeDePaiment, setModalMethodeDePaiment] = useState(false);
  const [abonnement, setAbonnement] = useState({});
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isPremium, setIsPremium] = React.useState(false);

  const toggleSubPage = (sub) => {
    setSubPage(sub);
  };

  const handleMiseAJourProfile = async () => {
    try {
      await putData("/v1/me", {
        prenom,
        nom,
      });
      toast.info("Données personnelles mises a jour");
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleMiseAJourAdresse = async () => {
    try {
      await putData("/v1/me/adresse", {
        ligne1,
        ligne2,
        codePostal,
        ville,
        pays,
      });

      toast.info("Adresse mise a jour");
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const toggleModalMethodeDePaiment = () => {
    setModalMethodeDePaiment(!modalMethodeDePaiment);
  };

  const handleExpirationMonth = (value) => {
    setExpiration(`${value.toString().padStart(2, "0")}/`);
    setExpirationMonth(value);
  };

  const hanldeExpirationYear = (value) => {
    setExpiration(`${expirationMonth.padStart(2, "0")}/${value}`);
    setExpirationYear(value);
  };

  const cancelSubscription = async (id) => {
    try {
      await deleteData(`/v1/me/abonnement/${id}`);
      navigate(`/dashboard`);
    } catch (error) {}
  };

  useEffect(() => {
    async function getUseEffectData() {
      console.log("useEffect UserPage");

      try {
        const profiles = await getData("/v1/me/profiles");
        if (profiles.includes("ADMIN")) {
          setIsAdmin(true);
        }

        const me = await getData(`/v1/me`);
        setPrenom(me.prenom);
        setNom(me.nom);
        setEmail(me.email);
        setIsPremium(me.isPremium);

        setDetenteurCarte(`${me.prenom.toUpperCase()} ${me.nom.toUpperCase()}`);

        if (me.adresse) {
          setVille(me.adresse.ville);
          setLigne2(me.adresse.ligne2);
          setLigne1(me.adresse.ligne1);
          setCodePostal(me.adresse.codePostal);
          setPays(me.adresse.pays);
        }

        if (me.methodeDePaiment) {
          setMethodeDePaiment(me.methodeDePaiment);
        }

        if (me.abonnement) {
          setAbonnement(me.abonnement);
        }
      } catch (error) {
        console.log(JSON.parse(error).error.status === 403);

        if (JSON.parse(error).error.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }

    getUseEffectData();
  }, [navigate]);
  return (
    <div>
      <Navbar isLoggedIn={true} isAdmin={isAdmin} />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <h2 className="pagetitle">Mon compte</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {prenom} {nom}
              </li>
            </ol>
          </nav>
        </div>
      </nav>
      <section className="vh-875">
        <Modal isOpen={modalMethodeDePaiment}>
          <ModalHeader toggle={toggleModalMethodeDePaiment}>
            Methode de paiment
          </ModalHeader>
          <ModalBody>
            <center>
              <Container fluid>
                <Row>
                  <Col xs={12}>
                    <Cards
                      cvc={cvc}
                      expiry={expiration}
                      name={detenteurCarte}
                      number={numero}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mt-10">
                    <Input
                      className="mt-20"
                      type="text"
                      placeholder="Détenteur"
                      onChange={(e) => setDetenteurCarte(e.target.value)}
                      value={detenteurCarte}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mt-10">
                    <Input
                      type="tel"
                      inputMode="numeric"
                      pattern="[0-9\s]{13,19}"
                      name="number"
                      placeholder="Numéro de carte"
                      onChange={(e) => setNumero(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className="mt-10">
                    <select
                      className="form-control"
                      onChange={(e) => handleExpirationMonth(e.target.value)}
                    >
                      <option></option>
                      <option value={1}>Janvier</option>
                      <option value={2}>Février</option>
                      <option value={3}>Mars</option>
                      <option value={4}>Avril</option>
                      <option value={5}>Mai</option>
                      <option value={6}>Juin</option>
                      <option value={7}>Juillet</option>
                      <option value={8}>Août</option>
                      <option value={9}>Septembre</option>
                      <option value={10}>Octobre</option>
                      <option value={11}>Novembre</option>
                      <option value={12}>Décembre</option>
                    </select>
                  </Col>
                  <Col xs={6} className="mt-10">
                    <select
                      className="form-control"
                      onChange={(e) => hanldeExpirationYear(e.target.value)}
                    >
                      <option></option>
                      <option value={2022}>2022</option>
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                      <option value={2025}>2025</option>
                      <option value={2026}>2026</option>
                      <option value={2027}>2027</option>
                      <option value={2028}>2028</option>
                      <option value={2029}>2029</option>
                      <option value={2030}>2030</option>
                    </select>
                  </Col>
                </Row>
              </Container>
            </center>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModalMethodeDePaiment}>
              Annuler
            </Button>
            <Button color="primary" onClick={toggleModalMethodeDePaiment}>
              Définir
            </Button>
          </ModalFooter>
        </Modal>
        <Container fluid className="mt-20">
          <Row>
            <Col xs={12}>
              <Card className="step-body">
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink active={subPage === "details"} href="#">
                        <div
                          className="menu-item"
                          onClick={() => toggleSubPage("details")}
                        >
                          <BsPerson size={18} />{" "}
                          <span className="pt-10">Détails du compte</span>
                        </div>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={subPage === "adresse"} href="#">
                        <div
                          className="menu-item"
                          onClick={() => toggleSubPage("adresse")}
                        >
                          <BsHouseDoor size={18} />{" "}
                          <span className="pt-10">Adresse</span>
                        </div>
                      </NavLink>
                    </NavItem>
                    {!isPremium && !isAdmin && (
                      <NavItem>
                        <NavLink active={subPage === "subscription"} href="#">
                          <div
                            className="menu-item"
                            onClick={() => toggleSubPage("subscription")}
                          >
                            <BsCurrencyEuro size={18} />{" "}
                            <span className="pt-10">Abonnement</span>
                          </div>
                        </NavLink>
                      </NavItem>
                    )}
                    {abonnement && abonnement.id && (
                      <NavItem>
                        <NavLink active={subPage === "payment"} href="#">
                          <div
                            className="menu-item"
                            onClick={() => toggleSubPage("payment")}
                          >
                            <BsCreditCard2Front size={18} />{" "}
                            <span className="pt-10">Moyen de paiement</span>
                          </div>
                        </NavLink>
                      </NavItem>
                    )}
                    {false && (
                      <NavItem>
                        <NavLink active={subPage === "affiliate"} href="#">
                          <div
                            className="menu-item"
                            onClick={() => toggleSubPage("affiliate")}
                          >
                            <BsPeople size={18} />{" "}
                            <span className="pt-10">Affiliation</span>
                          </div>
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                  <TabContent activeTab={subPage}>
                    <TabPane tabId="details">
                      <Row>
                        <Col sm="12" className="mt-40 ml-10">
                          {isPremium && (
                            <Alert color="success">
                              Vous bénéficiez d'un abonnement illimité !
                            </Alert>
                          )}
                          <Form>
                            <FormGroup row>
                              <Label for="prenom" sm={2}>
                                Prenom
                              </Label>
                              <Col sm={10}>
                                <Input
                                  className="large-input"
                                  type="text"
                                  value={prenom}
                                  onChange={(e) => setPrenom(e.target.value)}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label for="nom" sm={2}>
                                Nom
                              </Label>
                              <Col sm={10}>
                                <Input
                                  className="large-input"
                                  type="text"
                                  value={nom}
                                  onChange={(e) => setNom(e.target.value)}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label for="nom" sm={2}>
                                eMail
                              </Label>
                              <Col sm={10}>
                                <Input
                                  className="large-input"
                                  type="email"
                                  value={email}
                                  disabled
                                />
                              </Col>
                            </FormGroup>
                            <Button
                              color="primary"
                              onClick={handleMiseAJourProfile}
                            >
                              Mettre à jour
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="adresse">
                      <Row>
                        <Col sm="12" className="mt-40 ml-10">
                          <Form>
                            <FormGroup row>
                              <Label for="ligne1" sm={2}>
                                Adresse
                              </Label>
                              <Col sm={10}>
                                <Input
                                  className="large-input"
                                  type="text"
                                  value={ligne1}
                                  onChange={(e) => setLigne1(e.target.value)}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label for="ligne2" sm={2}></Label>
                              <Col sm={10}>
                                <Input
                                  className="large-input"
                                  type="text"
                                  value={ligne2}
                                  onChange={(e) => setLigne2(e.target.value)}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label for="cpville" sm={2}>
                                Code postal & Ville
                              </Label>
                              <Col sm={1}>
                                <Input
                                  className="tiny-input"
                                  type="text"
                                  value={codePostal}
                                  onChange={(e) =>
                                    setCodePostal(e.target.value)
                                  }
                                />
                              </Col>
                              <Col sm={5}>
                                <Input
                                  className="pl-10 town-input"
                                  type="text"
                                  value={ville}
                                  onChange={(e) => setVille(e.target.value)}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label for="pays" sm={2}>
                                Pays
                              </Label>
                              <Col sm={10}>
                                <Input
                                  className="large-input"
                                  type="text"
                                  value={pays}
                                  onChange={(e) => setPays(e.target.value)}
                                />
                              </Col>
                            </FormGroup>

                            <Button
                              color="primary"
                              onClick={handleMiseAJourAdresse}
                            >
                              Mettre à jour
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="payment">
                      {methodeDePaiment && (
                        <Row>
                          <Col sm="12" className="mt-40 ml-10">
                            <Form>
                              <FormGroup row>
                                <Label sm={2}>Numéro de carte</Label>
                                <Input
                                  disabled
                                  type="text"
                                  className="medium-input"
                                  value={`.... .... .... ${methodeDePaiment.derniers}`}
                                ></Input>
                              </FormGroup>
                              <FormGroup row>
                                <Label sm={2}>Détenteur</Label>
                                <Input
                                  disabled
                                  className="medium-input"
                                  type="text"
                                  value={`${prenom.toUpperCase()} ${nom.toUpperCase()}`}
                                ></Input>
                              </FormGroup>
                              <FormGroup row>
                                <Label sm={2}>Expiration</Label>
                                <Input
                                  disabled
                                  className="tiny-input"
                                  type="text"
                                  value={`${methodeDePaiment.expiration}`}
                                ></Input>
                              </FormGroup>
                            </Form>
                            <Button
                              color="primary"
                              onClick={toggleModalMethodeDePaiment}
                            >
                              Modifier
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </TabPane>
                    {!isPremium && (
                      <TabPane tabId="subscription">
                        {abonnement.id && (
                          <Container fluid>
                            <div className="mt-40">
                              <h2 className="pagetitle">Vous êtes abonné !</h2>
                              <p>
                                Vous bénéficiez d'un abonnement mensuel dont la
                                prochaine échéance tombera le{" "}
                                {moment
                                  .unix(abonnement.ends)
                                  .format("DD/MM/YYYY")}
                              </p>
                              <p>
                                <Button
                                  onClick={() =>
                                    cancelSubscription(abonnement.id)
                                  }
                                >
                                  {" "}
                                  J'annule mon abonnement
                                </Button>
                              </p>
                            </div>
                          </Container>
                        )}
                        {!abonnement.id && (
                          <Container fluid>
                            <div className="mt-40">
                              <h2 className="pagetitle">
                                Nous n'avons pas trouvé d'abonnement actif pour
                                votre compte :'(
                              </h2>
                              <p>
                                Merci de renouveler votre abonnement ou de
                                contacter notre service commercial.
                              </p>
                              <div>
                                <form
                                  action={`${process.env.REACT_APP_BASEURL}/v1/me/abonnement`}
                                  method="POST"
                                >
                                  {/* Add a hidden field with the lookup_key of your Price */}
                                  <input
                                    type="hidden"
                                    name="lookup_key"
                                    value={process.env.REACT_APP_PRODUIT}
                                  />
                                  <input
                                    type="hidden"
                                    name="token"
                                    value={localStorage.getItem("token")}
                                  />
                                  <Button
                                    color="primary"
                                    id="checkout-and-portal-button"
                                    type="submit"
                                  >
                                    Je m'abonne
                                  </Button>
                                </form>
                              </div>
                            </div>
                          </Container>
                        )}
                      </TabPane>
                    )}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </section>
    </div>
  );
}

export default UserPage;
