import React from "react";
import { FormGroup, Label } from "reactstrap";
import CurrencyInput from "./CurrencyInput";

const PrixVente = (props) => {
  return (
    <FormGroup>
      <Label>{props.label}</Label>
      <CurrencyInput className="medium-input" {...props} />
    </FormGroup>
  );
};

export default PrixVente;
