import React from "react";
import { FormGroup, Label } from "reactstrap";
import CurrencyInput from "./CurrencyInput";

const PrixHorsTaxe = (props) => {
  return (
    <FormGroup className={props.prixhorstaxeclassname}>
      <Label>Prix HT</Label>
      <CurrencyInput className="medium-input" {...props} />
    </FormGroup>
  );
};

export default PrixHorsTaxe;
