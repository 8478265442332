import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

import { Link, useNavigate, useSearchParams, Navigate } from "react-router-dom";
import Navbar from "./layout/Navbar";

import { Container, Row, Col, Button } from "reactstrap";
import { putData, toastConfig } from "../utils";

function CancelPage() {
  const [toDashboard, setToDashboard] = React.useState(false);

  if (toDashboard === true) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div>
      <Tooltip id="my-tooltip" />
      <Navbar isLoggedIn={true} isAdmin={false} />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <h2 className="pagetitle">Bienvenue</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item">Inscription annulée</li>
            </ol>
          </nav>
        </div>
      </nav>
      <section className="vh-875">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="rounded px-3 px-sm-4 py-3 py-sm-5">
                <h1 className="display-3">Hummm ... </h1>
                <p className="lead">Votre inscription a été interrompue</p>
                <hr className="my-2" />

                <p className="lead">
                  <Button color="primary" onClick={() => setToDashboard(true)}>
                    Réessayer plus tard !
                  </Button>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </section>
    </div>
  );
}

export default CancelPage;
