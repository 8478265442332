import React from "react";
import { Input } from "reactstrap";

import "./PercentInput.css";

function SmallPercentInput(props) {
  return (
    <div className="input-icon input-icon-right small-input">
      <Input maxLength={3} type="number" {...props} />
      <i>%</i>
    </div>
  );
}

export default SmallPercentInput;
